import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {
  @Input() text = '';
  @Input() danger = false;
  isShow = false;

  constructor() { }

  ngOnInit(): void { }

  public show(): void {
    this.isShow = true;
    this.setTimer();
  }

  setTimer(): void {
    setTimeout(() => {
      this.isShow = false;
    }, 3000);
  }
}
