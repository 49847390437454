import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-rectangle-button',
  templateUrl: './rectangle-button.component.html',
  styleUrls: ['./rectangle-button.component.scss']
})
export class RectangleButtonComponent implements OnInit {
  @Input() label: string;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onClick = new EventEmitter<any>();
  @Input() dark: string;
  @Input() loading = false;

  constructor() { }

  ngOnInit(): void { }

  onClickButton(event: any): void {
    this.onClick.emit(event);
  }
}
