import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  Category,
  Channel,
  CollectionOptions,
  Content,
  Facets,
  LimitedChannels,
  MyHistoryOptions,
  Paging,
  RecommendationOptions,
  RecommenderData,
  SearchOptions,
  SearchResponse,
  StaticContent,
  SubscribeData,
  SubscriptionOptions,
  SubscriptionPath
} from '../models/limitless-interaction.model';
import { map } from 'rxjs/operators';
import { TransferState } from '@angular/platform-browser';
import { GeneralSettingsService } from './general-settings.service';
import { shuffleArray } from '../utils/helpers';
import { environment } from '../../environments/environment';
import { RestService } from './rest.service';
import { AuthService } from './auth.service';
import { TranslationService } from './translation.service';
import { GlobalService } from './global.service';


@Injectable({
  providedIn: 'root'
})
export class LimitlessInteractionService {

  private recommenderUrl = `${environment.wpJson}recommender`;
  private searchUrl = `${environment.wpJson}search/search`;
  private subscriptionUrl = `${environment.wpJson}subscriptions/`;
  private channelUrl = `${environment.wpJson}`;
  private historyUrl = `${environment.wpJson}history`;
  private postFileUrl = `${environment.wpJson}avatar2`;

  recommenderSource = new BehaviorSubject<any[]>([]);

  filterRecommender(data: Content[]): Content[] {
    const allArray = this.recommenderSource.value;
    const array = data.filter(item => !allArray.includes(item.externalId));

    this.recommenderSource.next([...allArray, ...array.map(item => item.externalId)]);
    return array;
  }

  constructor(
    private http: HttpClient,
    private transferState: TransferState,
    private generalSettings: GeneralSettingsService,
    private restService: RestService,
    private authService: AuthService,
    private ts: TranslationService,
    private globalService: GlobalService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  getRecommendation(options: RecommendationOptions): Observable<any> {
    const reqUrl = this.appendQuery(this.recommenderUrl + '?', options);
    return  this.http.get(reqUrl, {params: { languageCode: this.ts.currentLang}}).pipe(
      map(item => this.formatRecommenderResponse(item)));
  }

  getSearch(options: SearchOptions): Observable<SearchResponse> {
    return this.http.post(this.searchUrl, options, {params: { languageCode: this.ts.currentLang}}).pipe(map((data: any) => (
      {
        contents: this.formatSearchResponse(data),
        searchtermRecommendations: this.formatCategories(data.searchtermRecommendations),
        paging: data.paging as Paging,
        facets: data.facets as Facets
      } as SearchResponse)
    ));
  }

  getUserChannelsContents(options: LimitedChannels): Observable<any> {
    const reqUrl = this.appendParamSubscriptionLC(this.subscriptionUrl + 'contents?', options);
    return this.http.get(reqUrl, { headers: this.authService.getHeaders(), params: { languageCode: this.ts.currentLang} });
  }

  getChannel(options: SubscriptionPath): Observable<any> {
    const reqUrl = this.appendPathSubscription(this.channelUrl, options);
    return this.http.get(reqUrl, {params: { languageCode: this.ts.currentLang}});
  }

  hasUserSubscription(options: SubscriptionPath): Observable<any> {
    const reqUrl = this.appendPathSubscription(this.subscriptionUrl, options);
    return this.http.get(reqUrl, { headers: this.authService.getHeaders()});
  }

  updateNotification(options: SubscriptionPath, notification: string): Observable<any> {
    const reqUrl = this.appendPathSubscription(this.subscriptionUrl, options) + `?notificationPreference=` + notification;
    return this.http.put(reqUrl, null, { headers: this.authService.getHeaders()});
  }

  deleteSubscription(options: SubscriptionPath): Observable<any> {
    const reqUrl = this.appendPathSubscription(this.subscriptionUrl, options);
    return this.http.delete(reqUrl, { headers: this.authService.getHeaders()});
  }

  getContentChannels(options: Channel): Observable<any> {
    const reqUrl = this.appendPathSubscription(this.channelUrl, options) + '/contents?returnAmount=9999';
    return this.http.get(reqUrl, {params: { languageCode: this.ts.currentLang}});
  }

  getSubscription(options: SubscriptionPath): Observable<any> {
    const reqUrl = this.appendPathSubscription(this.subscriptionUrl, options);
    return this.http.post(reqUrl, null, { headers: this.authService.getHeaders(), params: { languageCode: this.ts.currentLang}});
  }

  appendPathSubscription(url: string, options: SubscriptionPath): string {
    let str = url;
    let first = true;
    for (const [key, value] of Object.entries(options)) {
      const val = Array.isArray(value) ? value.join(',') : value;
      if (value?.length) {
        if (!first) {
          str += `/`;
        } else {
          first = false;
        }
        str += `${key}/${val}`;
      }
    }
    return str;
  }

  appendParamSubscriptionLC(url: string, options: LimitedChannels): string {
    let str = url;
    for (const [key, value] of Object.entries(options)) {
      const val = Array.isArray(value) ? value.join(',') : value;
      if (value?.length) {
        str += `${key}=${val}&`;
      }
    }
    return str.substring(0, str.length - 1);
  }

  appendParamSubscription(url: string, options: SubscriptionOptions): string {
    let str = url;
    for (const [key, value] of Object.entries(options)) {
      const val = Array.isArray(value) ? value.join(',') : value;
      if (value?.length) {
        str += `${key}=${val}&`;
      }
    }
    return str;
  }

  appendQuery(url: string, options: RecommendationOptions): string {
    let str = url;
    for (const [key, value] of Object.entries(options)) {
      const val = Array.isArray(value) ? value.join(',') : value;
      if (value?.length) {
        str += `${key}=${val}&`;
      }
    }
    return str;
  }

  formatRecommenderResponse({contents}: any = {contents: []}): Content[] {
    const arri = contents.map((item: any) => (
      {
        externalId: item.content?.externalId,
        imageUrl: item.content?.metadata?.imageUrl,
        thumbnail: item.content?.metadata?.thumbnail,
        portraitImageUrl: item.content?.metadata?.portraitImage,
        portraitImageThumbnail: item.content?.metadata?.portraitImageThumbnail,
        coverThumbnailSmall: item.content?.metadata?.coverThumbnailSmall,
        coverThumbnailMedium: item.content?.metadata?.coverThumbnailMedium,
        coverThumbnailLarge: item.content?.metadata?.coverThumbnailLarge,
        roundImage: item.content?.metadata?.roundImage,
        roundImageSmall: item.content?.metadata?.roundImageSmall,
        roundImageMedium: item.content?.metadata?.roundImageMedium,
        roundImageLarge: item.content?.metadata?.roundImageLarge,
        roundImageRetina: item.content?.metadata?.roundImageRetina,
        roundImageMediumRetina: item.content?.metadata?.roundImageMediumRetina,
        roundImageSmallRetina: item.content?.metadata?.roundImageSmallRetina,
        mediumImg: item.content?.metadata?.mediumImg,
        imageAlt: item.content?.metadata?.imageAlt,
        hasVideo: this.isUndefinedOrEmpty(item.content?.metadata?.vimeoId),
        title: item.content?.content?.[0]?.title,
        description: item.content?.content?.[0]?.description,
        tag: item.content?.content?.[0]?.tags?.[0],
        url: item.content?.url,
        publishDate: item.content?.metadata?.publishDate,
        categories: item.content && this.formatCategories(item.content?.categories),
        showDate: item.content?.metadata?.showDate,
        contactEMail: item.content?.metadata?.contactEMail,
        showEMail: item.content?.metadata?.showEMail,
        isExternalUrl: item.content?.metadata?.isExternalUrl,
      } as Content
    )).filter((item: Content) => item?.title && item?.url && item?.imageUrl);
    return arri;
  }
  formatSearchResponse({contents = []}: any): Content[] {
    return contents.map((item: any) => (
      {
        externalId: item?.externalId,
        imageUrl: item.metadata?.imageUrl,
        thumbnail: item.metadata?.thumbnail,
        mediumImg: item.metadata?.mediumImg,
        coverThumbnailSmall: item.metadata?.coverThumbnailSmall,
        coverThumbnailMedium: item.metadata?.coverThumbnailMedium,
        roundImage: item.metadata?.roundImage,
        roundImageSmall: item.metadata?.roundImageSmall,
        roundImageMedium: item.metadata?.roundImageMedium,
        roundImageLarge: item.metadata?.roundImageLarge,
        roundImageRetina: item.metadata?.roundImageRetina,
        roundImageRetinaMedium: item.metadata?.roundImageRetinaMedium,
        roundImageRetinaSmall: item.metadata?.roundImageRetinaSmall,
        imageAlt: item.metadata?.imageAlt,
        title: item.content?.[0]?.title,
        description: item.content?.[0]?.description,
        tag: item.content?.[0]?.tags?.[0],
        url: item?.url,
        type: item?.type,
        isExternalUrl: item.metadata?.isExternalUrl,
      } as Content
    ));
  }

  formatCategories(data?: Category[]): any {
    if (!data) {return ; }
    return data.map((item) => ({
      externalId: item.externalId,
      title: item.category[0].title
    }));
  }

  loadRecommender(recommender: RecommenderData,
                  parentId: string,
                  contextCategories: number[],
                  primaryCategoryId?: string): Promise<Content[]> {
    let fullContents: Content[] = [];
    let unFilteredContents: Content[] = [];
    return new Promise(async (resolve, reject) =>  {
      if (recommender.staticContents?.length) {
        const staticContent = this.formatStaticCategories(recommender.staticContents);
        fullContents = this.filterRecommender(staticContent);
        if (recommender.recommenderType === 'Manual Only') {
          return resolve(staticContent);
        }
      }
      const categoriesId: any | string[] = recommender.categories?.length
        && recommender.categories.map((item: number) => item + '');
      const backupCategoriesId: any | string[] = recommender.backupCategories?.length &&
        recommender.backupCategories.map((item: number) => item + '');
      let excludedContents = [parentId];
      if (recommender.excludedItems) {
        excludedContents = excludedContents.concat(...recommender.excludedItems.split(','));
      }
      const {size} = recommender;
      const options: RecommendationOptions = {
        categories: categoriesId || contextCategories,
        primaryCategory: !categoriesId ? primaryCategoryId : undefined,
        algorithmType: recommender.algorithmType,
        excludedContents,
        size: size ? (+size - fullContents.length).toString() : null,
      };

      const response = await this.getRecommendation(options).toPromise();
      const filteredData = this.filterRecommender(response);
      unFilteredContents =  this.sortBy([...fullContents, ...response], recommender.sortBy);
      fullContents = this.sortBy([...fullContents, ...filteredData], recommender.sortBy);

      if (fullContents.length === +size) {
        return resolve(fullContents);
      }

      if (backupCategoriesId) {
        const BackupOptions: RecommendationOptions = {
          categories: backupCategoriesId || contextCategories,
          algorithmType: recommender.algorithmType,
          excludedContents,
          size: size ? (size - fullContents.length).toString() : null
        };
        const backupResponse = await this.getRecommendation(BackupOptions).toPromise();
        const filteredBackupData = this.filterRecommender(backupResponse);
        unFilteredContents =  this.sortBy([...fullContents, ...backupResponse], recommender.sortBy);
        fullContents =  this.sortBy([...fullContents, ...filteredBackupData], recommender.sortBy);
        if (!fullContents.length) {
          return resolve(unFilteredContents);
        }

        return resolve(fullContents);
      }

      if (!fullContents.length) {
        const someRandom = await this.getSomeRandomContent(+size - fullContents.length);
        return resolve([...fullContents, ...someRandom]);
      }
      return resolve(fullContents);
    });
  }

  getCollection(options: CollectionOptions): Observable<SearchResponse> {
    return this.http.post(this.searchUrl, options, {params: { languageCode: this.ts.currentLang}}).pipe(map((data: any) => (
      {
        contents: this.formatSearchResponse(data),
        searchtermRecommendations: this.formatCategories(data.searchtermRecommendations),
        paging: data.paging as Paging
      } as SearchResponse)
    ));
  }

  formatStaticCategories(data: StaticContent[]): Content[] {
    return data.map(item => ({
      externalId: item.id,
      title: item.title,
      url: item.slug,
      tag: item.tags[0],
      publishDate: item.publishDate,
      imageUrl: item.imageUrl,
      imageAlt: item.imageAlt,
      mediumImg: item.mediumImg,
      thumbnail: item.thumbnail,
      portraitImageUrl: item.portraitImage,
      portraitImageThumbnail: item.portraitImageThumbnail,
      description: item.description,
      categories: this.formatCategories(item.categories),
      hasVideo: this.isUndefinedOrEmpty(item.vimeoId),
    } as Content));
  }

  formatSubscribeResponse({contents = []}: any): SubscribeData[] {
    return contents.map((item: any) => (
    {
      channelId: item.channelId,
      channelName: item.channelName,
      isSubscribe: item.isSubscribe,
      imageUrl: item.imageUrl,
      imageAlt: item.imageAlt,
      } as SubscribeData
    ));
  }

  sortBy(data: Content[], sortBy: 'BY_DATE'| 'RANDOM'| 'default'): Content[] {
    if (sortBy === 'BY_DATE') {
      return data.sort((a, b) => {
        const dateA = new Date(a.publishDate.replace(/\s/, 'T'));
        const dateB = new Date(b.publishDate.replace(/\s/, 'T'));
          // @ts-ignore
        return dateB.getTime() - dateA.getTime();
      });
    }
    if (sortBy === 'RANDOM') {
      return shuffleArray(data);
    }
    return data;
  }

  isUndefinedOrEmpty(value: any): boolean {
    return value !== null && value !== undefined && value.trim().length > 0;
  }

  async getSomeRandomContent(size: number): Promise<Content[]> {
    const popularCategories = [5, 85, 13, 39, 6, 72];

    const randomBackup = await this.http.get(environment.wpJson + `recommender?categories=${getRandom(popularCategories, 3).join(',')}&algorithmType=BASE&size=${size}&languageCode=${this.ts.currentLang}`).toPromise();
    const contents = this.formatRecommenderResponse(randomBackup);
    return this.sortBy(contents, 'RANDOM');


    function getRandom(arr: string | any[], n: number): any[] {
      // tslint:disable-next-line:prefer-const one-variable-per-declaration
      let result = new Array(n),
        len = arr.length,
        // tslint:disable-next-line:prefer-const
        taken = new Array(len);
      if (n > len) {
        throw new RangeError('getRandom: more elements taken than available');
      }
      while (n--) {
        const x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
      }
      return result;
    }
  }

  getDeleteHistory(): Observable<any>  {
    const reqUrl = this.historyUrl + '/contents' ;
    if ( this.globalService.getIsDebuggingEnabled() ) {
      console.group('getDeleteHistory');
      console.dir( reqUrl);
      console.groupEnd();
    }
    return this.http.delete(reqUrl, { headers: this.authService.getHeaders()});
  }

  getPauseHistory(): Observable<any>  {
    const reqUrl = this.historyUrl + '/preferences' ;
    if ( this.globalService.getIsDebuggingEnabled() ) {
      console.group('getPauseHistory');
      console.dir( reqUrl);
      console.groupEnd();
    }
    return this.http.delete(reqUrl, { headers: this.authService.getHeaders()});
  }

  getHistoryIsTrack(): Observable<any>  {
    const reqUrl = this.historyUrl + '/preferences' ;
    if ( this.globalService.getIsDebuggingEnabled() ) {
      console.group('getHistoryIsTrack');
      console.dir( reqUrl);
      console.groupEnd();
    }
    return this.http.get(reqUrl, { headers: this.authService.getHeaders()});
  }

  getPlayHistory(): Observable<any>  {
    const reqUrl = this.historyUrl + '/preferences' ;
    if ( this.globalService.getIsDebuggingEnabled() ) {
      console.group('getPlayHistory');
      console.dir( reqUrl);
      console.groupEnd();
    }
    return this.http.post(reqUrl, null, { headers: this.authService.getHeaders()});
  }

  getHistoryContents(): Observable<any>  {
    const reqUrl = this.historyUrl + '/contents';
    const header = this.authService.getHeaders();
    setTimeout(() => '', 2000);

    return this.http.get(reqUrl, { headers: header, withCredentials: true, params: { languageCode: this.ts.currentLang}});
  }

  saveMyHistory(options: MyHistoryOptions): Observable<any>  {
    const reqUrl = this.appendParamMyHistory(this.historyUrl + '/', options);
    return this.http.post(reqUrl, null, { headers: this.authService.getHeaders(), params: { languageCode: this.ts.currentLang}});
  }

  appendParamMyHistory(url: string, options: MyHistoryOptions): string {
    let str = url;
    for (const [key, value] of Object.entries(options)) {
      const val = Array.isArray(value) ? value.join(',') : value;
      if (value?.length) {
        str += `${key}/${val}`;
      }
    }
    return str;
  }
}
