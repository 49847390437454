<div class="main-wrapper" [ngClass]="position">
  <div class="gal-wrapper">
    <div class="carousel-wrapper">
      <ngx-slick-carousel
        class="carousel gallery-carousel d-block d-md-grid"
        #slickModal="slick-carousel"
        [config]="slideConfig"
        (breakpoint)="onBreakpoint($event)"
        (beforeChange)="beforeChange.emit($event)"
        (afterChange)="onAfterChange($event)"
        (init)="slickInit($event)"
      >
        <div ngxSlickItem class="slide item-slide-{{ i }}" *ngFor="let item of templates | slice:0: maxItems; index as i">
          <ng-container *ngTemplateOutlet="item.template"></ng-container>
        </div>
      </ngx-slick-carousel>
    </div>
    <div class="button-wrappe button-wrapper-gallery container px-2  d-block d-md-none {{  isArrowContainer ? 'container p-0': ''}}" [ngClass]="{ 'left-on-mobile': position === sliderPositionArrows.right } " *ngIf="!(first && last)">
      <app-arrow-previous [attr.disabled]="first === true ? '' : null" [attr.dark]="dark === '' ? '' : null" (click)="prev()">
      </app-arrow-previous>
      <app-arrow-next [attr.disabled]="last === true ? '' : null" [attr.dark]="dark === '' ? '' : null" (click)="next()"> </app-arrow-next>
    </div>
  </div>
</div>
