<div class="container">
  <div class="accordion">
    <div class="row">
      <div class="col-2 col-md-1">
        <p class="row-name d-none d-md-block" [innerHTML]="'Wins' | translate"></p>
        <p class="row-name  d-md-none">#</p>
      </div>
      <div class="col-md-2 col-4">
        <p  class="row-name" [innerHTML]="'Award' | translate"></p>
      </div>
      <div class="d-none  d-lg-block col-lg-1"></div>
      <div class="col-md-7 col-4  d-flex align-items-center">
        <p  class="row-name" [innerHTML]="'Location' | translate"></p>
      </div>
      <div class="col-2 col-md-1 d-flex justify-content-end align-items-center">
      </div>
    </div>
    <div class="_accordion-item" *ngFor="let item of contentList; let i = index">
      <div class="award-card accordion-header"  [id]="'accordion' + i">
        <div   data-bs-toggle="collapse" attr.data-bs-target="{{'#' + 'collapse-' + i}}" aria-expanded="false">
        <div class="row">
          <div class="col-2 col-md-1 d-flex align-items-center">
            <h4 class="count">{{item.awardCount}}x</h4>
          </div>
          <div class="col-md-2 col-4">
            <img [src]="item.awardLogo.sizes.medium" alt="client-logo">
          </div>
          <div class="d-none  d-lg-block col-lg-1"></div>
          <div class="col-md-7 col-4  d-flex align-items-center">
            <h4 class="location" [innerHTML]="item.location"></h4>
          </div>
          <div class="col-2 col-md-1 d-flex justify-content-end align-items-center">
            <div class="_button"  data-bs-toggle="collapse" attr.data-bs-target="{{'#' + 'collapse-' + i}}" aria-expanded="false">
              <div class="d-none  d-md-block">
                <span class=" view-toggle ">
                  <img class="arrow-up" src="assets/svg/arrows/arrow-up.svg" alt="close arrow" height="24" width="24"/>
                </span>
                <span class=" hide-toggle ">
                  <img class="arrow-up" src="assets/svg/arrows/arrow-down.svg" alt="open arrow" height="24" width="24"/>
                </span>
              </div>
              <div>
                <span  class=" arrow d-md-none "><svg class=" arrow" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M23.245 4l-11.245 14.374-11.219-14.374-.781.619 12 15.381 12-15.391-.755-.609z"/></svg></span>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      <div class="accordion-collapse collapse" id="{{'collapse-' + i}}">
        <div class="row justify-content-md-center">
          <div class="container d-flex _accordion-body justify-content-md-center">
            <div class="_accordion-background row d-flex justify-content-md-center">
              <div class="col-12 col-md-10">
                <p class="description" [innerHTML]="item.description"></p>
                <p class="uppercase title" [innerHTML]="'SSAchievements' | translate"></p>
                <div *ngFor="let cat of item.categories">
                  <p class="item"> <span class="bullet">&bull;</span> {{cat.category}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="+data.gridList.length > +data.size" class="d-flex button-wrapper justify-content-center align-items-center flex-column">
    <div class="of">{{contentList.length}} of {{data.gridList.length}}</div>
  <app-rectangle-button (click)="loadMore()" dark [label]="contentList.length >= this.data.gridList.length ? data.lessButtonLabel : data.moreButtonLabel"></app-rectangle-button>
  </div>
</div>
