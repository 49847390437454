import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Text } from '../../../models/text.model';

@Component({
  selector: 'app-title-text-quote',
  templateUrl: './title-text-quote.component.html',
  styleUrls: ['./title-text-quote.component.scss']
})
export class TitleTextQuoteComponent implements OnInit {
  @HostBinding('class')
  elementClass = 'text-item';
  @Input() data: Text;

  constructor() { }

  ngOnInit(): void { }
}
