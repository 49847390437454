import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { LimitlessInteractionService } from '../../services/limitless-interaction.service';
import { algorithmType, Content } from '../../models/limitless-interaction.model';
import { Router } from '@angular/router';
import { TranslationService } from '../../services/translation.service';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-live-search',
  templateUrl: './live-search.component.html',
  styleUrls: ['./live-search.component.scss']
})
export class LiveSearchComponent implements OnInit {
  @Input() value: Observable<any>;
  @Input() liveSearchAllResults = {
    currentIndex: -1,
    selectedId: '',
    list: [] as any[],
  };
  @Output() liveResultsEvent = new EventEmitter<any>();
  contents: Content[];
  categories: any[];
  loading = false;
  searchValue = '';

  constructor(
    private router: Router,
    private limitlessInteraction: LimitlessInteractionService,
    private globalService: GlobalService,
  ) {}

  ngOnInit(): void {
    this.value.pipe(
      tap((value) => {
        this.categories = [];
        this.contents = [];
        if (value.length) {
          this.loading = true;
        }
      }),
      debounceTime(30),
      distinctUntilChanged(),
      switchMap(value => {
        if (!value) {
          return of({
            contents: [],
            searchtermRecommendations: []
          });
        }
        return this.limitlessInteraction.getSearch({keyword: value, algorithmType: algorithmType.LIVE});
      })
    ).subscribe((data) => {
      if ( this.globalService.getIsDebuggingEnabled() ) {
        console.group('LiveSearchComponent');
        console.dir(data);
        console.groupEnd();
      }
      this.loading = false;
      if (data.contents) {
        this.contents = data.contents;
      } else {
        this.contents = [];
      }
      if (data.searchtermRecommendations) {
        this.categories = data.searchtermRecommendations?.slice(0, 3);
      } else {
        this.categories = [];
      }
      this.liveResultsEvent.emit([...this.categories, ...this.contents]);
    });
  }

  handleCatClick(value: string): Promise<boolean> {
    return this.router.navigate(['search'], {queryParams: {term: value}, queryParamsHandling: 'merge'});
  }

  blurThis(): void {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }
}
