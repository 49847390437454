import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Router } from '@angular/router';
import { MailService } from '../../services/mail.service';
import { Subscription } from 'rxjs';
import { GlobalService } from '../../services/global.service';
import { TranslationService } from '../../services/translation.service';


@Component({
  selector: 'app-get-in-touch-section',
  templateUrl: './get-in-touch-section.component.html',
  styleUrls: ['./get-in-touch-section.component.scss']
})
export class GetInTouchSectionComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() data: any;
  @Input()  email: string | boolean;
  focusElem: string;
  form: FormGroup;
  previewPageUrl: any;
  isValideMail = false;
  loading = false;
  noSend = false;
  token = '';
  a = '';
  thankYouPageUrl = '';
  recaptchaSubscription: Subscription;

  constructor(
    private client: HttpClient,
    private recaptchaV3Service: ReCaptchaV3Service,
    private router: Router,
    private mailService: MailService,
    private globalService: GlobalService,
    private ts: TranslationService,
  ) {
    // tslint:disable-next-line:no-non-null-assertion
   this.previewPageUrl  =  this.router.getCurrentNavigation()?.extras.state!.data?.previewPageUrl;
  }

  ngOnInit(): void {
    if ( this.globalService.getIsDebuggingEnabled() ) {
      console.group('GetInTouchSectionComponent');
      console.dir( this.data);
      console.groupEnd();
    }
    this.thankYouPageUrl = this.data.thankYouPage.url;
  }

  ngAfterViewInit(): void {
    const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'visible';
    }
  }

  ngOnDestroy(): void {
    if (this.recaptchaSubscription) {
      this.recaptchaSubscription.unsubscribe();
    }
    if (typeof window !== 'undefined') {
      const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
      if (element) {
         element.style.visibility = 'hidden';
      }
    }
  }

  validateEmail(email: string): boolean {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.isValideMail = regularExpression.test(String(email).toLowerCase());
    return this.isValideMail;
  }

  log(x: any): void {
  }

  setFocus(name: string): void {
    this.focusElem = name;
  }

  async onSubmit(contactForm: NgForm): Promise<void> {
    this.noSend = false;
    const token = await this.recaptchaV3Service.execute('submit').toPromise();
   /* this.recaptchaSubscription = await this.recaptchaV3Service.execute('registerCustomer').subscribe((recaptchaToken) => {
      this.token = recaptchaToken;
    });*/
    const sl = this.ts.getSelectedLanguageCookieValue();
    // const token = this.token;
    const body = {...contactForm.value, token, sl};
    this.loading = true;
    try {
      const data = await this.mailService.sendMail(body);
      this.loading = false;
      if ( data.status !== 200) {
        this.noSend = true;
        return;
      }
      await this.router.navigate([this.thankYouPageUrl]);
      contactForm.resetForm();
    } catch (e) {
      this.noSend = true;
      this.loading = false;
    }
  }
}
