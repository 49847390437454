<div class="jobs-section-wrapper" [style]="{'background-color': data.backgroundColor, '--text-color': data.textColor}">
  <div class="col-12 carousel-wrapper">
    <app-carousel #slider [slideConfig]="_slideConfig">
      <ng-template misCarouselItem *ngFor="let item of data.jobSlider; let i = index">
        <div class="slide-item">
          <img [defaultImage]="item.image.sizes.thumbnail" [lazyLoad]="item.image.url" >
          <div class="container  px-md-5 flex-grow-1 flex-lg-grow-0 header-wrapper">
            <h4 class="section-title " [innerHTML]="data.title"></h4>
            <app-link-to
              class="button"
              [title]="data.link.title"
              [link]="data.link.url"
            >
            </app-link-to>
          </div>
          <div class="container  px-md-5 text-container">
            <a [href]="item.jobLink" target="_blank"  rel="nofollow noopener">
              <h1  [innerHTML]="item.jobTitle"></h1>
            </a>
                <div class="location">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path  d="M12 2c3.196 0 6 2.618 6 5.602 0 3.093-2.493 7.132-6 12.661-3.507-5.529-6-9.568-6-12.661 0-2.984 2.804-5.602 6-5.602m0-2c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/></svg>

                  <h6 class="" [innerHTML]="item.location"></h6>
                  <app-share class="app-share" [linkToPage]="item.url"></app-share>
                </div>
          </div>
          <p class="small slide-counter d-block d-md-none">
            <strong>{{i+1}}</strong>/{{data.jobSlider?.length}}
          </p>
          <div class="container px-md-5 d-none d-md-block">
            <app-arrow-previous (click)="handleNavigate('PREV')"></app-arrow-previous>
            <app-arrow-next (click)="handleNavigate('NEXT')" ></app-arrow-next>
          </div>
        </div>
      </ng-template>
    </app-carousel>
  </div>
</div>

