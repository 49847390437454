<div *ngIf="data" class="simply-text" [style]="{'background-color': data.scBackgroundColor, '--text-color': data.scTextColor}">
  <div class="container">
    <div class="sc-row row d-flex align-items-center" [ngClass]="data.scImageDirection==='left'?'left':'right'">
      <div class="col ">
        <h2 class='title' [innerHTML]="data.scTitle" [style]="{'color': data.scTitleColor}" ></h2>
        <h4 *ngIf="data.scSubTitle" [innerHTML]="data.scSubTitle"></h4>
        <div class="content" [innerHTML]="data.scDescription"></div>
        <div class="d-flex flex-column ">
          <div class="sc-btn-container" *ngIf="data.scPost.url">
            <a class="btn btn-primary btn-find-more" [routerLink]="data.scPost.url" target="_self" >
              <span>{{data.scPost.title?data.scPost.title:'Find out more'}}</span>
            </a>
          </div>
           <div class="sc-btn-container" *ngIf="data.scDownloadWhitepaperLink">
             <a  class="btn btn-primary btn-download" [href]="data.scDownloadWhitepaperLink" download>
               <span>{{'Download Whitepaper'}}</span>
             </a>
           </div>
        </div>
      </div>
      <div class="col sc-image-col">
        <img *ngIf="data.scImage" class="sc-image"
             [defaultImage]="data.scImage.sizes.thumbnail"
             [lazyLoad]="data.scImage.sizes.medium_large"
             alt="{{ data.scImage.alt }}">
      </div>
    </div>
  </div>
</div>
