<div class="title-text-quote" [style]="{'background-color': data.backgroundColor, '--text-color': data.textColor}">
  <div class="container p-6  p-md-0">
    <div class="row px-2 p-md-0" *ngIf="data.title || data.supTitle || data.subtitle">
      <div class="col-12 col-md-8">
        <div class="suptitle paragraph text-uppercase " *ngIf="data.supTitle">{{ data.supTitle }} </div>
        <h2 class="title" *ngIf="data.title" >{{ data.title }}</h2>
        <h4 class="subtitle mobile2" *ngIf="data.subtitle" [innerHTML]="data.subtitle"></h4>
      </div>
    </div>
    <div class="row px-2 p-md-0"  *ngIf="data?.content || data.quote?.content">
      <div  class="col-12 col-md-7" *ngIf="data?.content">
        <div class="content" [innerHTML]="data.content" ></div>
      </div>
      <div class="quote col-12 offset-md-1 col-md-4 d-flex justify-content-center align-items-md-center flex-column h-md-100" *ngIf="data.quote?.content">
        <a class="" *ngIf="data.quote.link" [href]="data.quote.link">
          <h3 class="link-underline">{{ data.quote.content }}</h3>
        </a>
        <h3 *ngIf="!data.quote.link">{{ data.quote.content }}</h3>
        <p *ngIf="data.quote.source?.length" class="small w-100 text-uppercase name">&#8722; {{ data.quote.source}}</p>
      </div>
    </div>
  </div>
</div>
