import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'vimeoUrl'
})
export class VimeoUrlPipe implements PipeTransform {

  constructor(protected _sanitizer: DomSanitizer) {}

  transform(value: unknown, ...args: unknown[]): unknown {
    const url = 'https://player.vimeo.com/video/' + value;
    return this._sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
