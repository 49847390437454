import {ActiveHotspot360, NavigationMarker360} from '../../../models/hotspot360.model';

export function getActiveMarkerHtml(data: ActiveHotspot360, index: number): string {
  return `
      <div class="marker">
       <div class="closed-wrapper d-flex align-items-end">
         <img
           class="closed-icon"
           src="${data.hotspotCloseImage.url}"
         >
         <div class="closed-text">
           <h5>${data.link.title}</h5>
         </div>
       </div>
      <div class="active-body">
        <div class="active-body-image-wrapper">
          <img class="active-background-image" src="${data.hotspotOpenImage.url}">
          <img class="active-image" [src]="item.activeHotspot.backgroundImage.sizes.medium" >
          </div>
            <div class="content">
              <div class="title-wrapper">
                <h4>${data.link.title}</h4>
                <p class="tiny">${data.description}</p>
              </div>
              <a href="${data.link.url}">
                <div class="button">
                <span>Go to</span>
                <svg width="25" height="10" viewBox="0 0 21 10" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17 6H0V4h17l-2-3 1-1 4 4 1 1-5 5-1-2 2-2z" fill="#fff" fill-rule="nonzero"/>
                </svg>
              </div>
              </a>
            </div>
          </div>
    </div>`
}

export function getNavigationMarkerHtml(data: NavigationMarker360): string {
  return `<a href="${data.link.url}">
           <div class="navigation-hotspot ${data?.icon.url ? 'has-icon' : ''}">
+              ${data?.icon.url ? '<img src="' + data.icon.url + '" >' : ''}
+              ${data?.icon.url ? '' : '<div class="icon"> + </div>'}
               <div class="text-wrapper">
                 <span>${data.link.title}</span>
               </div>
        </a>`;
}
