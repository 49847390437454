<div class="channels-subscribe ipad-padding" *ngIf="isLogin && isShowError && !isHomepage && isShow">
  <div class="container pe-0 p-md-0" >
    <div class="row">
      <h4 [innerHTML]="'SubscriptionsInfo1' | translate"></h4>
    </div>
  </div>
</div>

<div class="channels-subscribe ipad-padding" *ngIf="isLogin && !isShowError && isShow">
  <div class="container  p-md-0" >
    <div class="" *ngFor="let item of data;let i = index; let first = first;let last = last;" [ngClass]="!isHomepage?'set-padding':''">
      <hr class="delimiter"/>
      <div class="row channels-subscribe-head" [ngClass]="item.isAccordionOpen && isHomepage?'open':isHomepage?'close':''" *ngIf="item.channelContents.length > 0 " (click)="openTab(item, $event)">
        <div class="col-md-8 col-12 d-flex justify-content-start">
          <a *ngIf="!isHomepage; else homepageImg" [href]="'../' + item.url" title="{{ item.content[0]?.title }}" target="_self">
            <img
                 srcset="{{item.metadata.roundImageRetinaSmall}} 2x"
                 src="{{item.metadata.roundImageSmall}}"
                 alt="{{ item.metadata.imageAlt }}"
                 title="{{ item.content[0]?.title }}"
                 loading="lazy">
          </a>
          <ng-template #homepageImg>
            <img
                 srcset="{{item.metadata.roundImageRetinaSmall}} 2x"
                 src="{{item.metadata.roundImageSmall}}"
                 alt="{{ item.metadata.imageAlt }}"
                 title="{{ item.content[0]?.title }}"
                 loading="lazy">
          </ng-template>
          <div class="channels-subscribe-text-box d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <a [href]="'../' + item.url" *ngIf="!isHomepage; else homepage">
                <h4>{{item.content[0]?.title}}</h4>
              </a>
              <ng-template #homepage>
                <p class="title-name">{{item.content[0]?.title}}</p>
              </ng-template>
              <p class="channel-name small channel-border" [innerHTML]="'Channel' | translate"></p>
            </div>
            <div *ngIf="isHomepage" class="mobile-arrow align-self-center d-block d-md-none">
              <img class="arrow-up" src="assets/svg/arrows/arrow-down.svg" [ngClass]="item.isAccordionOpen?'':'close'"  alt="open arrow" height="24" width="24"/>
            </div>
          </div>
        </div>
        <hr class="delimiter-{{i}} {{last?'last':''}} {{first?'first':''}}  delimiter delimiter-bottom  d-block d-md-none" [ngClass]="item.isAccordionOpen?'open':''"/>
        <div class="col-md-4 col-12 d-flex justify-content-between justify-content-md-end align-items-center view-channel-container" [ngClass]="item.isAccordionOpen?'open':''">
          <div class="d-flex h-100" *ngIf="item.isAccordionOpen">
            <app-link-to [title]="'View Channel'" [link]=" item.url" [color]="'black'" ></app-link-to>
          </div>
          <div *ngIf="isHomepage" class="d-none d-md-block">
            <img class="arrow-up" src="assets/svg/arrows/arrow-down.svg" [ngClass]="item.isAccordionOpen?'':'close'"  alt="open arrow" height="24" width="24"/>
          </div>
        </div>
      </div>
      <hr class="delimiter delimiter-{{i}} {{last?'last':''}} {{first?'first':''}} delimiter-bottom d-none d-md-block" [ngClass]="item.isAccordionOpen?'open':''"/>
      <div class="row channels-subscribe-body" *ngIf="item.channelContents.length > 0 " [ngClass]="item.isAccordionOpen?'show':'hidden'" id="body-item-{{i}}">
        <div class="col-12 f-content pe-0" >
          <app-carousel *ngIf="item.channelContents.length"  dark [slideConfig]="_slideConfig">
            <ng-template misCarouselItem *ngFor="let page of item.channelContents; let j = index">
              <a [routerLink]=" '../' + page.url" [title]="page.content[0].title" class="position-relative" id="{{ generatedId(item.url) }}">
                <app-video-play [isVideo]="checkIsVideo(page.metadata.vimeoId)"></app-video-play>
                <div class="slide channels-subscribe-slider"
                     [defaultImage]="page.metadata.coverThumbnailSmall? item.metadata.coverThumbnailSmall : page.metadata.thumbnail"
                     [lazyLoad]="page.metadata.coverThumbnailLarge? page.metadata.coverThumbnailLarge : page.metadata.mediumImg" >
                </div>
              </a>
              <p class="small slide-counter d-block d-md-none">
                <strong>{{j+1}}</strong>/{{item.channelContents.length}}
              </p>
              <div class="under-container h-100">
                <div class="d-flex">
                  <h6 class="medium-p tag-item d-inline-block social-title"   [innerHTML]="page.content[0].tags[0]"></h6>
                  <div class="share-wrapper d-inline-block">
                    <app-share [white]="false" [linkToPage]="page.url" ></app-share>
                  </div>
                </div>
                <div class="d-flex align-items-end h-100">
                  <div>
                    <a [routerLink]=" '../' + page.url" [title]="page.content[0].title" >
                      <h5 class="item-title mobile2-leed">{{ page.content[0].title}}</h5>
                    </a>
                  </div>
                </div>
              </div>
            </ng-template>
          </app-carousel>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="loading-wrapper" *ngIf="isLogin && !isShowError &&!isShow">
  <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
    <span class="visually-hidden" [innerHTML]="'Loading' | translate"></span>
  </div>
</div>
