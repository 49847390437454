import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver, ComponentRef,
  ElementRef, HostListener,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  ViewChild, ViewContainerRef
} from '@angular/core';
// @ts-ignore
import { Viewer as PhotoSphereViewer } from 'photo-sphere-viewer';
// @ts-ignore
import MarkersPlugin from 'photo-sphere-viewer/dist/plugins/markers';
// @ts-ignore
import VisibleRangePlugin from 'photo-sphere-viewer/dist/plugins/visible-range';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {Hotspot360Markers, Hotspot360Model} from "../../../models/hotspot360.model";
import {getActiveMarkerHtml, getNavigationMarkerHtml} from '../marker/marker-helper';
@Component({
  selector: 'app-hotspot360',
  templateUrl: './hotspot360.component.html',
  styleUrls: ['./hotspot360.component.scss']
})
export class Hotspot360Component implements AfterViewInit {
  @ViewChild('element') elementRef: ElementRef;
  viewer: any;
  @Input() data: Hotspot360Model;

  constructor( @Inject(PLATFORM_ID) private platformId: object, @Inject(DOCUMENT) private document: Document) { }

  ngAfterViewInit(): void {
    if (!isPlatformBrowser(this.platformId)) {return; }
    this.viewer = new PhotoSphereViewer({
      panorama: this.data.panoramaImage.url,
      container: this.elementRef.nativeElement,
      loadingImg: '/assets/img/photosphere-logo.gif',
      mousewheelCtrlKey: false,
      touchmoveTwoFingers: false,
      mousewheel: false,
      defaultLat: 0,
      defaultZoomLvl: this.isMobile ? 0 : 20,
      navbar: false,
      plugins: [
        [MarkersPlugin, {
          markers: this.formatMarkers(this.data.markers),
        }],
        [VisibleRangePlugin, {
          longitudeRange: [ 2 *  Math.PI],
          latitudeRange : ['1deg', '1deg'],
        }],
      ]
    });
    const markersPlugin  = this.viewer.getPlugin(MarkersPlugin);
    markersPlugin.on('select-marker', (e: any, marker: any) => {
      const selectedMarker = marker.$el.getElementsByClassName('marker')[0];
      selectedMarker?.classList.add('active');
    });
  }

  get isMobile(): boolean {
    return isPlatformBrowser(this.platformId) && window.innerWidth <= 768;
  }

  formatMarkers(markers: Hotspot360Markers[]): any {
    return markers.map((item, index: number) => {
      const active =  item.hotspotType === 'Active Marker';
      const navigation = item.hotspotType === 'Navigation Marker';
      return {
        id: Math.floor((Math.random() * 6) + 1),
        longitude: item[ active ? 'activeHotspot' : navigation ? 'navigation' : 'popup'].location.longitude,
        latitude: item[ active ? 'activeHotspot' : navigation ? 'navigation' : 'popup'].location.latitude,
        html: active ? getActiveMarkerHtml(item.activeHotspot, index) :
          navigation ? getNavigationMarkerHtml(item.navigation) : null,
        style: {
          zIndex: active ? 50 : 30
        }
      }; });
    }



  @HostListener('document:click', ['$event'])
  onClick(event: Event): void {
    // @ts-ignore
    if(!event.target.closest('.marker')) {
      // @ts-ignore
      const markers = event.currentTarget.querySelectorAll('.marker.active');
      if (!markers.length) {return; }
      Array.from(markers).forEach((item: any) => {
        item.classList.remove('active');
      });
    }
  }
  roomRotate(side: string): void {
    const val = side === 'r' ? 0.30 : -0.30;
    let roomLongitude = this.viewer.getPosition().longitude;
    roomLongitude += val;
    this.viewer.animate({ longitude: roomLongitude, latitude: 0, speed: 3000 });
  }

}
