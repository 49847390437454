import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-arrow-previous',
  templateUrl: './arrow-previous.component.html',
  styleUrls: ['./arrow-previous.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArrowPreviousComponent implements OnInit {
  @Input() dark: string;
  @Input() big: string;
  @Input() black: string;

  constructor() { }

  ngOnInit(): void { }
}
