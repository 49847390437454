import { ChangeDetectorRef, Component, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { CarouselComponent } from '../../shared/sliders/carousel/carousel.component';
import { PREVIEW_RECOMMENDER } from '../../models/mocks/preview-recommender.mock';
import { LimitlessInteractionService } from '../../services/limitless-interaction.service';
import { Content } from '../../models/limitless-interaction.model';
import { SeoUtils } from '../../utils/seo.utilis';

@Component({
  selector: 'app-preview-recommender',
  templateUrl: './preview-recommender.component.html',
  styleUrls: ['./preview-recommender.component.scss']
})
export class PreviewRecommenderComponent implements OnInit {
  @Input()
  @HostBinding('class')
  elementClass = 'with-background';
  @ViewChild('carouselComponent') carouselComponent: CarouselComponent;
  @Input() contextCategories: number[] = [];
  @Input() parentId: any;
  @Input() primaryCategoryId: string;
  data: any;
  currentSlide = 0;
  recData: Content[] = [];
  previewData = PREVIEW_RECOMMENDER;
  isForMobileVisible = true;

  // tslint:disable-next-line
  _slideConfig: any = {
    infinite: false,
    slidesToShow: 1.7,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
    ],
  };

  constructor(
    private recommenderService: LimitlessInteractionService,
    private cdr: ChangeDetectorRef
  ) { }

  async ngOnInit(): Promise<void> {
    this.recData = await this.recommenderService
      .loadRecommender(this.data.recommender, this.parentId, this.contextCategories, this.primaryCategoryId);
    if ((this.data.doNotShowDescriptionInMobile !== null || true) &&  this.data.doNotShowDescriptionInMobile) {
      this.isForMobileVisible = false;
    }
    this.cdr.detectChanges();
  }
  handleChange(e: any): void {
    this.currentSlide = e.currentSlide;
  }
  generatedId(link: string): string {
    return SeoUtils.generateID(link);
  }
}
