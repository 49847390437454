import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit, PLATFORM_ID,
  Renderer2,
  ViewChild
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-hr',
  templateUrl: './hr.component.html',
  styleUrls: ['./hr.component.scss']
})
export class HrComponent implements  OnInit, AfterViewInit {
  @Input() data: any;
  @ViewChild('psJobWidget', {read: ElementRef }) private psJobWidget: ElementRef;
  isMobileResolution: boolean;
  gId: string;
  gItemId: string;
  gTargetId: string;
  isAccordion = false;
  lastStatusMobile = false;

  constructor(
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.generateId();
  }

  ngOnInit(): void {
    this.detectScreenSize();
  }

  ngAfterViewInit(): void {
    this.getIframe();
  }

  @HostListener('window:resize', [])
  private onResize(): void  {
    this.detectScreenSize();
  }

  private detectScreenSize(): void {
    if (!isPlatformBrowser(this.platformId)){
      return;
    }
    if (window.innerWidth < 768) {
      this.isMobileResolution = true;
      this.lastStatusMobile = true;
    } else {
      this.isMobileResolution = false;
      if (this.lastStatusMobile) {
        setTimeout( () => {
          this.getIframe();
        }, 1000 );

      }
      this.lastStatusMobile = false;
    }
  }
  public content(): void  {
    this.getIframe();
  }
  public generateId(): void  {
    this.gId = 'accordion-gid-' + Math.random().toString(36).substr(2, 9);
    this.gItemId = 'accordion-gItemId-' + Math.random().toString(36).substr(2, 9);
    this.gTargetId = 'accordion-gTargetId-' + Math.random().toString(36).substr(2, 9);
  }

  private getIframe(): void {
    const elementExists = document.getElementsByClassName('CookieDeclaration');
    if (!elementExists || elementExists.length < 1) {
      const s = this.renderer2.createElement('script');
      s.type = 'text/javascript';
      s.src = this.data.jsSRC;
      s.text = '';
      s.id = '';
      this.renderer2.appendChild(this.psJobWidget.nativeElement, s);
    }
  }
}
