import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CarouselComponent } from '../../shared/sliders/carousel/carousel.component';
import { LimitlessInteractionService } from '../../services/limitless-interaction.service';
import { Content } from '../../models/limitless-interaction.model';
import { SeoUtils } from '../../utils/seo.utilis';

@Component({
  selector: 'app-about-us-section',
  templateUrl: './category-recommender.component.html',
  styleUrls: ['./category-recommender.component.scss']
})
export class CategoryRecommenderComponent implements OnInit {
  @ViewChild('carouselComponent') carouselComponent: CarouselComponent;
  @Input() data: any;
  @Input() contextCategories: number[] = [];
  @Input() parentId: any;
  @Input() primaryCategoryId: string;
  recData: Content[] = [];

  // tslint:disable-next-line:variable-name
  _slideConfig: any = {
    infinite: false,
    slidesToShow: 3,
    variableWidth: true,
    slidesToScroll: 3,
    dots: false,
    arrows: false,
    lazy: 'ondemand',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.1,
          variableWidth: false,
          slidesToScroll: 1,
        },
      },
    ],
  };

  constructor( private recommenderService: LimitlessInteractionService ) { }

  async ngOnInit(): Promise<void> {
    this.recData = await this.recommenderService
      .loadRecommender(this.data.recommender, this.parentId, this.contextCategories, this.primaryCategoryId);
  }

  generatedId(link: string): string {
    return SeoUtils.generateID(link);
  }
}
