<div class="about-us ipad-padding" [style]="{'background-color': data.backgroundColor, '--text-color': data.textColor}">
  <div class="container-fluid pe-0 p-md-0 position-relative">
    <div class="container ps-2 p-md-0 header-about-us">
      <div class="row main-row d-flex flex-column-reverse flex-md-row">
        <div class="col-md-9 col">
          <div class="tag-wrapper">
            <h6 class="medium-p tag-item d-inline-block e" [innerHTML]="data.tag"></h6>
          </div>
        </div>
        <div class="col-md-3 col p-md-0 d-flex justify-content-md-end pe-md-2">
          <app-link-to [title]="data.link.title" [link]="data.link.url" [color]="'black'" ></app-link-to>
        </div>
      </div>
      <div class="row title-row">
        <div class="col-md-8 col">
          <h2 class="mobile2" [innerHTML]="data.title"></h2>
        </div>
      </div>
    </div>

    <div class="container-fluid p-md-0 ps-2 pe-0" *ngIf="recData.length">
      <div class="d-flex justify-content-end">
        <div class="col-12 f-content">
          <app-carousel dark [slideConfig]="_slideConfig">
            <ng-template misCarouselItem *ngFor="let item of recData; let i = index">
              <div class="slide about-us-slider" [defaultImage]="item.portraitImageThumbnail ? item.portraitImageThumbnail :  item.thumbnail"
                   [lazyLoad]="item.portraitImageUrl? item.portraitImageUrl : item.mediumImg" >
                <a [routerLink]="'../' + item.url"  id="{{ generatedId(item.url) }}">
                  <app-video-play [isVideo]="item.hasVideo"></app-video-play>
                <div class="inner-container d-flex  align-content-between flex-wrap">
                  <div class="overlay"></div>
                  <div class="col-12 d-flex align-items-center">
                    <h6 class="medium-p tag-item d-inline-block white-color social-title" style="border-color:{{ data.textColor + '80' }}"  [innerHTML]="item.tag"></h6>
                    <div class="share-wrapper d-inline-block">
                      <app-share [white]="true" under [linkToPage]="item.url" ></app-share>
                    </div>
                  </div>
                  <div class="inner-content d-md-none">
                    <div>
                      <h3 class="white-color item-title" [innerHTML]="item.title"></h3>
                      <app-underline-button
                          class="align-self-start"
                          [link]= "item.url"
                          [text]="'Learn More'"
                          [color]="'#ffffff'"
                      >
                      </app-underline-button>
                    </div>
                  </div>
                </div>
                </a>
              </div>
              <p class="small slide-counter d-block d-md-none">
                <strong>{{i+1}}</strong>/{{recData.length}}
              </p>
            </ng-template>
          </app-carousel>
        </div>
      </div>
    </div>
  </div>
</div>
