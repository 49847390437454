import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { ImageTitleText } from '../../../models/image-title-text.model';

@Component({
  selector: 'app-image-title-text',
  templateUrl: './image-title-text.component.html',
  styleUrls: ['./image-title-text.component.scss']
})
export class ImageTitleTextComponent implements OnInit {
  @HostBinding('class')
  elementClass = 'text-item';
  @Input() data: ImageTitleText;

  constructor( private router: Router ) { }

  ngOnInit(): void { }

  pageLink(): string{
    return environment.angularUrl + this.router.url;
  }
}
