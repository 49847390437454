import {AfterViewInit, Component, HostBinding, HostListener, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import { ColumnSubtitleTex } from '../../../models/column-subtitle-text.model';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-column-subtitle-text',
  templateUrl: './column-subtitle-text.component.html',
  styleUrls: ['./column-subtitle-text.component.scss']
})
export class ColumnSubtitleTextComponent implements OnInit, AfterViewInit {
  @HostBinding('class')
  elementClass = 'text-item text-item-column';
  @Input() data: ColumnSubtitleTex;
  isMobileResolution: boolean;
  gId: string;
  gItemId: string;
  gTargetId: string;

  constructor( @Inject(PLATFORM_ID) private platformId: object ) {
    this.generateId();
  }

  ngOnInit(): void {
    this.detectScreenSize();
  }

  ngAfterViewInit(): void {
    this.detectScreenSize();
  }

  @HostListener('window:resize', [])
  private onResize(): void  {
    this.detectScreenSize();
  }

  private detectScreenSize(): void {
    if (!isPlatformBrowser(this.platformId)){
      return;
    }
    if (window.innerWidth < 768) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }

  public generateId(): void  {
    this.gId = 'accordion-gid-' + Math.random().toString(36).substr(2, 9);
    this.gItemId = 'accordion-gItemId-' + Math.random().toString(36).substr(2, 9);
    this.gTargetId = 'accordion-gTargetId-' + Math.random().toString(36).substr(2, 9);
  }
}
