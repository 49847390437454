import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselComponent } from './carousel/carousel.component';
import { CarouselItemDirective } from '../directives/carousel-item.directive';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ButtonsModule } from '../buttons/buttons.module';
import { TestimonialSliderComponent } from './testimonial-slider/testimonial-slider.component';
import { GallerySliderComponent } from './gallery-slider/gallery-slider.component';

@NgModule({
  declarations: [
    CarouselComponent,
    CarouselItemDirective,
    TestimonialSliderComponent,
    GallerySliderComponent,
  ],
  imports: [
    CommonModule,
    SlickCarouselModule,
    ButtonsModule,

  ],
  exports: [
    CarouselComponent,
    SlickCarouselModule,
    TestimonialSliderComponent,
    GallerySliderComponent,
    /* DIRECTIVES */
    CarouselItemDirective,
  ],
})
export class SlidersModule {}
