import { Component, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { GeneralSettingsService } from './services/general-settings.service';
import { CookieService } from 'ngx-cookie';
import { DOCUMENT, isPlatformServer, Location } from '@angular/common';
import { map } from 'rxjs/operators';
import { TrackingData } from './models/general-settings.model';
import { HomepageService } from './services/homepage.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from './services/translation.service';


declare var Cookiebot: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  sessionColor: string;
  constructor(private generalSettings: GeneralSettingsService,
              private cookieService: CookieService,
              private renderer2: Renderer2,
              private translate: TranslateService,
              private homePageService: HomepageService,
              private router: Router,
              private ts: TranslationService,
              private route: ActivatedRoute,
              private location: Location,
              @Inject(PLATFORM_ID) private platformId: object,
              @Inject(DOCUMENT) private document: Document,
              ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.ts.setQueryParams(this.ts.currentLang);
      }
    });
  }
  ngOnInit(): void {
    // this.translate.setDefaultLang('en');
    const themeString = this.cookieService.get('session-color');
    if (themeString) {

      const theme = JSON.parse(themeString);
      this.sessionColor = theme.color;
      this.generalSettings.$theme.next(theme);
    } else {
      this.generalSettings.$data.pipe(map(item => item.data.preferences.themeColorsList))
        .subscribe(colorList => {
          const theme = colorList[Math.floor(Math.random() * colorList.length)].theme;
          this.sessionColor = theme.color;
          this.generalSettings.$theme.next(theme);
          this.cookieService.put('session-color', JSON.stringify(theme));
        });
    }
    this.generalSettings.$data.subscribe(({data: {trackingData}}) => {
      if (!trackingData) {return; }
      this.loadGoogleTracking(trackingData);
    });
  }
  loadGoogleTracking(trackingData: TrackingData): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    const script = this.renderer2.createElement('script');
    script.type = `text/plain`;
    script.dataset.cookieconsent = 'statistics';
    script.text = trackingData.headScript;

    this.renderer2.appendChild(this.document.head, script);
  }

}
