import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {

  @Input() data: any;
  public isCollapsed = false;

  constructor( private globalService: GlobalService ) { }

  ngOnInit(): void {
    if ( this.globalService.getIsDebuggingEnabled() ) {
      console.group('TimelineComponent');
      console.dir( this.data);
      console.groupEnd();
    }
  }

}
