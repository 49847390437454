<div [innerHTML]="getStyles()"> </div>
<div class="custom-component" >
  <div class="container">
    <div class="row" *ngFor="let field of data.fields; let i = index">
      <div class="p-0 {{field.class}} ">
        <a class="btn btn-custom" *ngIf="field.link.url" [href]="field.link.url" target="{{field.link.target?field.link.target:'_self'}}" [title]="field.link.title?field.link.title:'GetInTouch' | translate">
          <span class="button-content">{{ field.link.title?field.link.title:"'GetInTouch' | translate" }}</span>
        </a>
        <div *ngIf="field.customHTML" class="custom-text " [innerHTML]="field.customHTML"></div>
        <img *ngIf="field.image" class="img" [lazyLoad]="field.image.url" [defaultImage]="field.image.sizes.thumbnail"  [alt]="field.image.alt">
      </div>
    </div>

  </div>
</div>

