export type RecommendationOptions = {
  categories: string[],
  primaryCategory?: string | undefined,
  algorithmType: algorithmType,
  excludedContents?: string[],
  userId?: string,
  languageCode?: string
  size?: string | null;
};

export type SearchOptions = {
  keyword: string,
  algorithmType: algorithmType,
  categoryIds?: string[],
  paging?: Paging,
  languageCode?: string
};

export type SearchResponse = {
  contentType: string[],
  contents: Content[],
  paging: Paging,
  searchtermRecommendations?: SearchTermRecommendations[],
  facets?: Facets[]
};

export type Facets = {
  categoryId?: string | number,
  count?: string | number,
  title?: string | number,
  parentCategoryId?: string | number,
  parentCategoryTitle?: string | number
};

export type Paging = {
    currentPage?: string | number,
    lastPage?: string | number,
    perPage?: string | number,
    from?: string | number,
    total?: string | number
};

export enum algorithmType {
  BASE = 'BASE',
  V2 = 'V2',
  V3 = 'V3',
  minus1 = '-1',
  LIVE = 'LIVE'
}

export class Content {
  externalId: string;
  url: string;
  imageUrl: string;
  imageAlt: string;
  thumbnail: string;
  coverThumbnailSmall: string;
  coverThumbnailMedium: string;
  coverThumbnailLarge: string;
  mediumImg: string;
  title: string;
  description: string;
  tag: string;
  publishDate: string;
  portraitImageThumbnail: string;
  portraitImageUrl: string;
  roundImage?: string;
  roundImageSmall?: string;
  roundImageMedium?: string;
  roundImageLarge?: string;
  roundImageRetina?: string;
  roundImageRetinaMedium?: string;
  roundImageRetinaSmall?: string;
  categories: Category[];
  showDate: boolean | string;
  hasVideo: boolean;
  contactEMail?: string;
  showEMail?: boolean;
  type?: string;
  isExternalUrl?: boolean;
}

export class SearchTermRecommendations {
  externalId: string;
  category: {title: string}[];
  searchable: boolean;
}

export class Category {
  title: string;
  externalId: string;
  category: any;
}

export class CategoryName {
  id: string;
  title: string;
}

export class StaticContent {
  id: string;
  title: string;
  slug: string;
  imageUrl: string;
  imageAlt: string;
  publishDate: string;
  thumbnail: string;
  mediumImg: string;
  portraitImage?: string;
  portraitImageThumbnail?: string;
  description: string;
  tags: [string];
  categories: Category[];
  vimeoId: any;
}

export class RecommenderData {
  algorithmType: any;
  backupCategories: any[];
  recommenderType: any;
  categories: any[];
  categoriesName: any;
  excludedItems: any;
  size: any;
  sortBy: 'BY_DATE'| 'RANDOM'| 'default';
  staticContents: StaticContent[];
}

export class SubscribeData {
  channelId: string;
  channelName: string;
  imageUrl: string;
  imageAlt: string;
  isSubscribe: string;
}

export class SubscriptionOptions {
  channelId: string;
  userId?: string;
  languageCode?: string;
  size?: string | null;
}

export class SubscriptionPath {
  channels?: string;
}

export class Channel {
  channels: string;
}

export class MyHistoryOptions {
  contents: string;
}

export class MyHistoryPath {
  users: string;
}

export class LimitedChannels {
  channelReturnAmount: string;
  contentReturnAmount: string;
}

export type CollectionOptions = {
  algorithmType: number,
  categoryIds: string[],
  paging?: Paging
  languageCode?: string;
};
