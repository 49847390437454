<div class="image-title-text" >
  <div class="container p-md-0" [ngClass]="data.borderBottom? 'border-bottom-itt' : ''">
    <div class="row px-2 p-md-0" *ngIf="data.tag" >
      <div  class="d-flex text-wrapper align-items-baseline justify-content-start">
        <span class="medium-p tag-item d-inline-block h6" style="border-color:{{ data.textColor + '80' }}"  [innerHTML]="data.tag"></span>
      </div>
    </div>
    <div class="row px-2 p-md-0" *ngIf="data.supTitle" >
      <h2 [innerHTML]="data.supTitle" class=" sup-title col-12 mb-0"></h2>
    </div>
    <div class="row px-2 p-md-0" *ngIf="data.title" >
      <h5 [innerHTML]="data.title" class="title"></h5>
    </div>
    <div class="row px-2 p-md-0" *ngIf="data.image?.url" >
      <div class="col-12" [class.onlyImage]="!data.quote.content && !data.quote.source && !data.description && !data.tag  && !data.supTitle && !data.title">
        <div class="image-wrapper" *ngIf="data.image" >
          <img class="image" *ngIf="data.image"  [defaultImage]="data.image.sizes.thumbnail"  [lazyLoad]="data.image.url" [alt]="data.image?.alt" >
        </div>
      </div>
    </div>
    <div class="row px-2 p-md-0 desc-box" *ngIf="data.quote.content || data.quote.source || data.description">
      <div class="col-md-7 col-12 " *ngIf="data.description">
        <p class="light" [innerHTML]="data.description"></p>
      </div>
      <div class="d-flex align-items-center col-12 offset-md-1 col-md-4 quote" *ngIf="data.quote.content || data.quote.source" >
        <div class="quote-info">
          <p *ngIf="data.quote.content" class="h3" >{{ data.quote.content }}</p>
          <p *ngIf="data.quote.source" class="small w-100 text-uppercase">&#8722; {{ data.quote.source}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
