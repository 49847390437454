export const environmentBase = {
  production: true,
  pageUrl: 'https://bixcom.at/wordpress/wp-json/scoop/v1/page/',
  angularUrl: 'https://bixcom.at/',
  baseUrl: 'https://bixcom.at',
  wpJson: 'https://bixcom.at/wordpress/wp-json/scoop/v1/',
  hj: '2480302',
  recaptcha: '6Ld-wyAbAAAAAL_6yzmIXTvBF5pwh247WsAidFYP',

};

export const  environment = {
  ...environmentBase,
  recommenderUrl: environmentBase.wpJson + 'recommender',
  searchURL: environmentBase.wpJson + 'search/search',
  collectionCustomUrl: environmentBase.wpJson + 'search/custom',
};
