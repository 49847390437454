import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  private isDebuggingEnabled: string | null;
  constructor(
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: object
    ) {
    if (isPlatformBrowser(this.platformId)) {
      this.isDebuggingEnabled = localStorage.getItem('enable-debugging');
    } else {
      this.isDebuggingEnabled = 'false';
    }
  }

  /** Activate debugging:
   *  write in console of browser --- localStorage.setItem("enable-debugging", "true");   ---
   */
  getIsDebuggingEnabled(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      this.isDebuggingEnabled = localStorage.getItem('enable-debugging');
      return this.isDebuggingEnabled != null && this.isDebuggingEnabled === 'true';
    }
    return false;
  }
}
