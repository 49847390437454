import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import Player from '@vimeo/player';
import { VIMEO } from '../../models/mocks/vimeo.mock';
import { GeneralSettingsService } from '../../services/general-settings.service';

@Component({
  selector: 'app-vimeo-vide',
  templateUrl: './vimeo-vide.component.html',
  styleUrls: ['./vimeo-vide.component.scss']
})
export class VimeoVideComponent implements OnInit, AfterViewInit {
  private player: any;
  private themeColor: string;
  private isFullScreen = false;
  data: any;

  constructor(
    private generalSettingsService: GeneralSettingsService
  ) { }

  /*@HostListener('window:scroll', ['$event'])
  onScroll(event: any): void {
    this.pipScroll();
  }*/

  ngOnInit(): void {
    this.generalSettingsService.$theme.subscribe((theme: any ) => {
      this.themeColor = theme.color;
    });
  }

  ngAfterViewInit(): void {
  }

  private initPlayer(): any {
    this.player = new Player('.video-iframe');
    this.player.setColor(this.themeColor).then((color: string) => {}).catch((error: any) => {
    });
  }

  getVimeoVideo(id: any): string {
    if ( id ) {
      return id + '?controls=1&loop=1&muted=0';
    }
    return '';
  }

  @HostListener('fullscreenchange')
  @HostListener('webkitfullscreenchange')
  @HostListener('mozfullscreenchange')
  @HostListener('MSFullscreenChange')
  screenChange(): void {
    if ( this.isFullScreen ) {
      if (this.player !== undefined) {
        this.player.pause();
        this.player.setVolume(0);
      }
      this.isFullScreen = false;
    } else {
      if (this.player !== undefined) {
        this.player.play();
        this.player.setControls = true;
        this.player.setVolume(1);
      }
      this.isFullScreen = true;
    }
  }

  playVideo(item: number): void {
    this.player = new Player('video-iframe-' + item);
    this.player.requestFullscreen();
  }
}
