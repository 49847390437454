import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-award-add-on',
  templateUrl: './award-add-on.component.html',
  styleUrls: ['./award-add-on.component.scss']
})
export class AwardAddOnComponent implements OnInit {
  @Input() data: any;

  constructor() { }

  ngOnInit(): void { }
}
