import { Component, Input, OnInit } from '@angular/core';
import { ContactInfo } from '../../models/contact-info.model';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent implements OnInit {

  @Input() data: ContactInfo;
  constructor( private globalService: GlobalService ) { }

  ngOnInit(): void {
    if ( this.globalService.getIsDebuggingEnabled() ) {
      console.group('ContactInfo');
      console.dir( this.data);
      console.groupEnd();
    }
  }

}
