import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { LimitlessInteractionService } from '../../services/limitless-interaction.service';
import { MyHistory } from '../../models/my-history';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-my-history',
  templateUrl: './my-history.component.html',
  styleUrls: ['./my-history.component.scss']
})
export class MyHistoryComponent implements OnInit, AfterViewInit {
  isOpen = false;
  isShow = false;
  isError = false;
  isHistoryTrack = true;
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('historyErrorElem', {static: true}) historyErrorElem: ElementRef;
  data: MyHistory | null;
  constructor(
    private historyService: LimitlessInteractionService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.historyService.getHistoryContents().subscribe(data => {
      if ( this.globalService.getIsDebuggingEnabled() ) {
        console.group('ContactInfo');
        console.dir( data);
        console.groupEnd();
      }
      if (data?.status === 200) {
        if (data.contents.length < 1) {
          this.isError = true;
        }
        this.data = data;
      } else  {
        this.isError = true;
        console.error('Error');
      }
      this.checkIsHistoryTrack();
      this.isShow = true;
    });
  }

  @HostListener('document:click', ['$event'])
  clickOut(event: MouseEvent): void {
    if (this.toggleButton !== undefined) {
      if (!this.toggleButton.nativeElement.contains(event.target)){
        this.isOpen = false;
      }
    }
  }

  private checkIsHistoryTrack(): void {
    this.historyService.getHistoryIsTrack().subscribe(data => {
      if (data?.status === 200) {
        this.isHistoryTrack = data.contentHistoryPreference === '1';
      } else if (data?.status === undefined) {
        console.warn('No data');
        this.isHistoryTrack = false;
      } else {
        this.isShow = true;
        this.isHistoryTrack = false;
        console.error('Error');
      }
    });
  }

  getDate(): number {
    return Date.now();
  }

  public onSelect(value: string): void {
    if ( value === '0') {
      this.historyService.getPauseHistory().subscribe(data => {
        if (data?.status === 200) {
          this.isHistoryTrack = data.contentHistoryPreference === '1';
        } else if (data?.status === undefined) {
          console.warn('No data');
        } else {
          console.error('Error');
        }
      });
    } else if ( value === '1' ) {
      this.historyService.getPlayHistory().subscribe(data => {
        if (data?.status === 200) {
          this.isHistoryTrack = true;
        } else if (data?.status === undefined) {
          console.warn('No data');
          this.isHistoryTrack = false;
        } else {
          console.error('Error');
          this.isHistoryTrack = false;
        }
      });
    } else if ( value === '2' ) {
      this.historyService.getDeleteHistory().subscribe(data => {
        if (data?.status === 200) {
          this.data = data;
          this.isHistoryTrack = false;
        } else if (data?.status === undefined) {
          console.warn('No data');
        } else {
          console.error('Error');
        }
      });
    }
  }

  public openTracking(): void {
    this.isOpen = !this.isOpen;
  }

  ngAfterViewInit(): void {
    if (this.isError) {
      const elem = this.historyErrorElem.nativeElement;
      if (elem) {
        elem.l.setAttribute('style', 'display: block;');
      }
    }
  }
}
