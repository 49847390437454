<div class="hero-image-with-text " [style]="{'--text-color': data.textColor}" >
  <div class="container-fluid p-0" *ngIf="data.backgroundImage?.url">
    <div class="image-wrapper d-flex" style="background-position: center;" [lazyLoad]="data.backgroundImage.url" [defaultImage]="data.backgroundImage.sizes.thumbnail">
      <div class="layer">
        <ng-container *ngIf="data.type.includes('Hero'); then hero else simply">
        </ng-container>
        <ng-template #hero>
          <div class="container d-flex justify-content-center align-items-center flex-column h-100">
            <h1 class="logo-image"
                style="background-position: center;"
                [lazyLoad]="data.logo.sizes.large"
                [defaultImage]="data.logo.sizes.thumbnail">
              <span class="visible-hidden" [innerHTML]="data.seoLogoText"></span>
            </h1>
            <h4 *ngIf="data.description" class="description" [innerHTML]="data.description"></h4>
            <div class="row hiwt-cta-container" *ngIf="data.hiwtCTA">
              <div class="col">
                <a appLink *ngFor="let cta of data.hiwtCTA;let i = index; let first = first;let last = last;"
                   [href]="cta.hiwtLink.url"
                   class="hiwt-cta btn-fill btn btn-primary btn-find-more cta-{{i}} {{first?'first':''}} {{last?'last':''}}"
                   [attr.aria-label]="cta.hiwtLink.title">
                  <span [innerHTML]="cta.hiwtLink.title"></span>
                </a>
              </div>
            </div>
            <img class="help-image-down" src="assets/svg/arrows/arrow-down-white.svg" alt="down-button">
          </div>
        </ng-template>
        <ng-template #simply>
          <div class="container d-flex justify-content-center align-items-start flex-column h-100">
            <h2 class="logo-image"
                style="background-position: left center;"
                [lazyLoad]="data.logo.sizes.large"
                [defaultImage]="data.logo.sizes.thumbnail">
              <span class="visible-hidden" [innerHTML]="data.seoLogoText"></span>
            </h2>
            <p *ngIf="data.description" class="description text-start" [innerHTML]="data.description"></p>
            <div class="row hiwt-cta-container" *ngIf="data.hiwtCTA">
              <div class="col">
               <!-- <a *ngFor="let cta of data.hiwtCTA;let i = index; let first = first;let last = last;"
                   [href]="cta.hiwtLink.url"
                   class="hiwt-cta btn-fill btn btn-primary btn-find-more cta-{{i}} {{first?'first':''}} {{last?'last':''}}"
                   [attr.aria-label]="cta.hiwtLink.title">
                  <span [innerHTML]="cta.hiwtLink.title"></span>
                </a>-->
                <app-underline-button
                    *ngFor="let cta of data.hiwtCTA;let i = index; let first = first;let last = last;"
                    class="cta-{{i}} {{first?'first':''}} {{last?'last':''}}"
                    [link]="cta.hiwtLink.url"
                    [text]="cta.hiwtLink.title"
                    [color]="data.textColor"
                ></app-underline-button>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
