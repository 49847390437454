import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact-button',
  templateUrl: './contact-button.component.html',
  styleUrls: ['./contact-button.component.scss']
})
export class ContactButtonComponent implements OnInit {

  constructor( private router: Router ) { }

  ngOnInit(): void {
  }

  navigateToContact(): void {
    this.router.navigateByUrl('/contact', {
      state: {
        data: {
          previewPageUrl: this.router.url.replace('/', '')
        }
      }
    });
  }
}
