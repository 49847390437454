import { RecommendationsResponse } from '../recommendations-response.model';

export const RECOMMENDER_RESPONSE: RecommendationsResponse = {
    contents: [
      {
        content: {
          url: '/about-chameleons-freedom-and-the-love-for-art',
          externalId: '15027',
          metadata: {
            imageUrl: 'https://www.scoopandspoon.com/wp-content/uploads/2021/01/Chameleon.jpg',
            businessValue: '',
            contentType: 'Industry View',
            superTitle: '',
            subTitle: '',
            brandIcon: 'false',
            directLinks: '',
            recommendations: '',
          },
          categories: [
            {
              externalId: '96',
              category: [
                {
                  title: 'Story'
                }
              ]
            }
          ],
          content: [
            {
              title: 'About Chameleons, Freedom and the Love for Art',
              description: 'Chameleons are famous for their ability to change the colour at a breakneck speed. The primary function of the change of colour is not a disguise, but first and foremost – a communication with other members of their own species. For Michael Ksela, CEO of SCOOP & SPOON, the fact that technology and marketing can develop similar skills explains the success of the SCOOP 360°.',
              displayDate: '14. March 2021'
            }
          ]
        },
        numberOfCommonCategories: 1,
        similarityOfContentCategories: 100,
        type: 0,
      },
      {
        content: {
          url: '/about-chameleons-freedom-and-the-love-for-art',
          externalId: '15027',
          metadata: {
            imageUrl: 'https://www.scoopandspoon.com/wp-content/uploads/2021/01/Chameleon.jpg',
            businessValue: '',
            contentType: 'Stories',
            superTitle: '',
            subTitle: '',
            brandIcon: 'false',
            directLinks: '',
            recommendations: '',
          },
          categories: [
            {
              externalId: '96',
              category: [
                {
                  title: 'Story'
                }
              ]
            }
          ],
          content: [
            {
              title: 'About Chameleons, Freedom and the Love for Art',
              description: 'Chameleons are famous for their ability to change the colour at a breakneck speed. The primary function of the change of colour is not a disguise, but first and foremost – a communication with other members of their own species. For Michael Ksela, CEO of SCOOP & SPOON, the fact that technology and marketing can develop similar skills explains the success of the SCOOP 360°.',
              displayDate: '19. March 2020'
            }
          ]
        },
        numberOfCommonCategories: 1,
        similarityOfContentCategories: 100,
        type: 0,
      },
      {
        content: {
          url: '/about-chameleons-freedom-and-the-love-for-art',
          externalId: '15027',
          metadata: {
            imageUrl: 'https://www.scoopandspoon.com/wp-content/uploads/2021/01/Chameleon.jpg',
            businessValue: '',
            contentType: 'Industry View',
            superTitle: '',
            subTitle: '',
            brandIcon: 'false',
            directLinks: '',
            recommendations: '',
          },
          categories: [
            {
              externalId: '96',
              category: [
                {
                  title: 'Story'
                }
              ]
            }
          ],
          content: [
            {
              title: 'About Chameleons, Freedom and the Love for Art',
              description: 'Chameleons are famous for their ability to change the colour at a breakneck speed. The primary function of the change of colour is not a disguise, but first and foremost – a communication with other members of their own species. For Michael Ksela, CEO of SCOOP & SPOON, the fact that technology and marketing can develop similar skills explains the success of the SCOOP 360°.',
              displayDate: '14. May 2020'
            }
          ]
        },
        numberOfCommonCategories: 1,
        similarityOfContentCategories: 100,
        type: 0,
      },
      {
        content: {
          url: '/about-chameleons-freedom-and-the-love-for-art',
          externalId: '15027',
          metadata: {
            imageUrl: 'https://www.scoopandspoon.com/wp-content/uploads/2021/01/Chameleon.jpg',
            businessValue: '',
            contentType: 'Stories',
            superTitle: '',
            subTitle: '',
            brandIcon: 'false',
            directLinks: '',
            recommendations: '',
          },
          categories: [
            {
              externalId: '96',
              category: [
                {
                  title: 'Story'
                }
              ]
            }
          ],
          content: [
            {
              title: 'About Chameleons, Freedom and the Love for Art',
              description: 'Chameleons are famous for their ability to change the colour at a breakneck speed. The primary function of the change of colour is not a disguise, but first and foremost – a communication with other members of their own species. For Michael Ksela, CEO of SCOOP & SPOON, the fact that technology and marketing can develop similar skills explains the success of the SCOOP 360°.',
              displayDate: '19. May 2020'
            }
          ]
        },
        numberOfCommonCategories: 1,
        similarityOfContentCategories: 100,
        type: 0,
      },
      {
        content: {
          url: '/about-chameleons-freedom-and-the-love-for-art',
          externalId: '15027',
          metadata: {
            imageUrl: 'https://www.scoopandspoon.com/wp-content/uploads/2021/01/Chameleon.jpg',
            businessValue: '',
            contentType: 'Awards',
            superTitle: '',
            subTitle: '',
            brandIcon: 'false',
            directLinks: '',
            recommendations: '',
          },
          categories: [
            {
              externalId: '96',
              category: [
                {
                  title: 'Story'
                }
              ]
            }
          ],
          content: [
            {
              title: 'About Chameleons, Freedom and the Love for Art',
              description: 'Chameleons are famous for their ability to change the colour at a breakneck speed. The primary function of the change of colour is not a disguise, but first and foremost – a communication with other members of their own species. For Michael Ksela, CEO of SCOOP & SPOON, the fact that technology and marketing can develop similar skills explains the success of the SCOOP 360°.',
              displayDate: '4. June 2020'
            }
          ]
        },
        numberOfCommonCategories: 1,
        similarityOfContentCategories: 100,
        type: 0,
      },
    ],
    status: {
      code: 0,
      msg: ''
    }
  }
