<div class="modal fade" #forgotModal id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <span class="close-btn-modal"  data-bs-dismiss="modal" aria-label="Close"></span>
      <div class="modal-body _modal-content">
        <h4 [innerHTML]="'ForgotPassword' | translate"></h4>
        <form [formGroup]="registerForm" (ngSubmit)="handleForgot()">
          <div class="form-item">
            <label for="email-f" [innerHTML]="'Email' | translate">*</label>
            <input #emailAddress id="email-p" placeholder="{{'EmailPlaceholder' | translate}}" type="text" formControlName="email" autocomplete="on">
            <div *ngIf="registerForm.controls.email.touched">
              <div class="form-error" *ngIf="registerForm.controls.email.errors?.email" [innerHTML]="'EmailErrorValid' | translate"></div>
              <div class="form-error" *ngIf="registerForm.controls.email.errors?.required" [innerHTML]="'EmailErrorRequired' | translate"></div>
              <div class="form-error" *ngIf="registerForm.controls.email.errors?.auth" [innerHTML]="'EmailCouldntFindError' | translate"></div>
            </div>
            <p *ngIf="success" class="alert alert-success border-0 mt-3" [innerHTML]="'ResetPassword' | translate"></p>
          </div>
          <div class="button-wrapper d-flex justify-content-center">
            <button class="w-100" type="submit">
              <app-rectangle-button [loading]="submitLoading" dark [label]="'Submit' | translate"></app-rectangle-button>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
