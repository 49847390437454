import { Component, Input, OnInit } from '@angular/core';
import { HeroSliderModel } from '../../models/heroSlider.model';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hero-slider',
  templateUrl: './hero-slider.component.html',
  styleUrls: ['./hero-slider.component.scss']
})
export class HeroSliderComponent implements OnInit {
  @Input() data: HeroSliderModel;

  // tslint:disable-next-line
  _slideConfig: any = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    lazy: 'ondemand',
  };

  constructor( private router: Router ) { }

  ngOnInit(): void {
  }

  pageLink(): string{
    return environment.angularUrl + this.router.url;
  }
}
