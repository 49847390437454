<div class="main-wrapper" [ngClass]="position">
  <div class="testimonial-wrapper">
    <ngx-slick-carousel
        class="carousel testimonial-slider"
        #slickModal="slick-carousel"
        [config]="slideConfig"
        (breakpoint)="onBreakpoint($event)"
        (beforeChange)="beforeChange.emit($event)"
        (afterChange)="onAfterChange($event)"
        (init)="slickInit($event)"
    >
      <div ngxSlickItem class="slide" *ngFor="let item of templates">
        <ng-container *ngTemplateOutlet="item.template"></ng-container>
      </div>
    </ngx-slick-carousel>
    <div class="container-fluid testimonial-slider-arrows">
      <div class="container button-wrapper" [ngClass]="{ 'left-on-mobile': position === sliderPositionArrows.right } " *ngIf="!(first && last)">
        <div class="row">
          <div class="offset-md-10 offset-4 col-md-2 col-6 ps-2 p-md-0 arrow-container">
            <app-arrow-previous [attr.disabled]="first === true ? '' : null"  (click)="prev()">
            </app-arrow-previous>
            <app-arrow-next [attr.disabled]="last === true ? '' : null"  (click)="next()"> </app-arrow-next>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

