<ng-template #mobile>
  <div class="accordion accordion-mobile px-2" id="accordionPanelsStayOpen">
    <div class="accordion-item"  *ngFor="let elem of data.columns;let i = index">
      <h2 class="accordion-header" id="panelsStayOpen-heading-{{ i }}">
        <button class="accordion-button px-0" [ngClass]="i == 0? '' : 'collapsed'" type="button" data-bs-toggle="collapse" attr.data-bs-target="#panelsStayOpen-collapse-{{ i }}"  aria-expanded="true"  attr.aria-controls="panelsStayOpen-collapse-{{ i }}">
          {{elem.title}}
        </button>
      </h2>
      <div id="panelsStayOpen-collapse-{{ i }}" [ngClass]="i == 0? 'show' : ''"  class="accordion-collapse collapse"  attr.aria-labelledby="panelsStayOpen-heading-{{ i }}">
        <div class="accordion-body p-0">
          <div *ngFor="let item of elem.itemList">
           {{ item.item }}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #desktop>
  <osb-ngx-flexmasonry-grid class="row">
    <osb-ngx-flexmasonry-grid-item class="col col-lg-4 col-md-6 p-0 cl-item" *ngFor="let elem of data.columns;" >
      <div class="card-body p-0">
        <p class="card-title lead">{{elem.title}}</p>
        <div *ngFor="let item of elem.itemList">
          <p>{{ item.item }}</p>
        </div>
      </div>
    </osb-ngx-flexmasonry-grid-item>
  </osb-ngx-flexmasonry-grid>
</ng-template>


<div class="container" *ngIf="!isBrowser">
  <div class="row">
    <div class="col" *ngFor="let column of data.columns">
      <p class="card-title lead">{{column.title}}</p>
      <p *ngFor="let item of column.itemList">
        {{item.item}}
      </p>
    </div>
  </div>
</div>

<div class="column-list" *ngIf="isBrowser" [style]="{'background-color': data.backgroundColor, '--text-color': data.textColor}">
  <div class="container">
    <ng-container *ngTemplateOutlet="isMobileResolution? mobile : desktop"></ng-container>
  </div>
</div>
