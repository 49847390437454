<!--<div class="modal fade" #myDataModel id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">-->
<div class="modal fade" #myDataModel id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <span class="close-btn-modal"  data-bs-dismiss="modal" aria-label="Close"></span>
      <div class="modal-body _modal-content">
        <h4 [innerHTML]="'MyData' | translate"></h4>
        <div class="d-flex justify-content-center">
          <div class="profile-picture position-relative">
            <img src="{{ avatar }}" alt="avatar">
            <div class="add-avatar position-absolute" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal" data-bs-dismiss="modal" >
              <img src="assets/svg/avatar.svg" alt="add avatar" width="24" height="24">
            </div>
          </div>
        </div>
        <form [formGroup]="updateForm" (ngSubmit)="handleUpdate()">
          <div class="form-item">
            <label for="firstname" [innerHTML]="'FirstName' | translate"></label>
            <input #firstName id="firstname" placeholder="{{'FirstNamePlaceholder' | translate}}" type="text" formControlName="firstname">
            <div *ngIf="updateForm.controls.firstname.touched || submitted">
              <div class="form-error" *ngIf="updateForm.controls.firstname.errors?.required" [innerHTML]="'FirstNameErrorRequired' | translate"></div>
            </div>
          </div>
          <div class="form-item">
            <label for="lastname" [innerHTML]="'LastName' | translate"></label>
            <input id="lastname" placeholder="{{'LastNamePlaceholder' | translate}}" type="text" formControlName="lastname">
            <div *ngIf="updateForm.controls.lastname.touched || submitted">
              <div class="form-error" *ngIf="updateForm.controls.lastname.errors?.required" [innerHTML]="'LastNameErrorRequired' | translate"></div>
            </div>
          </div>
          <div class="form-item">
            <label for="preferredlanguage" [innerHTML]="'PreferredLanguage' | translate"></label>
            <select
                ngModel="{{prefLanguage}}"
                formControlName="preferredlanguage"
                id="preferredlanguage"
                (change)="changePreferredLanguage($event)"
            >
              <option *ngFor="let language of languages" [value]="language.code" [selected]="language.code===prefLanguage? true : null">{{language.title}}</option>
            </select>
          </div>
          <div class="form-item">
            <label for="email" [innerHTML]="'EmailAddress' | translate"></label>
            <input #emailAddress id="email" placeholder="{{'EmailPlaceholder' | translate}}" type="text" formControlName="email">
          </div>
          <div class="form-item" *ngIf="!editPassword">
            <label for="empty-password" [innerHTML]="'Password' | translate"></label>
            <div class="d-flex">
              <input class="w-100" disabled type="password" id="empty-password" placeholder="{{'PasswordPlaceholder' | translate}}" autocomplete="off">
              <p (click)="handleSwitch()" class="edit-password" [innerHTML]="'Edit' | translate"></p>
            </div>
          </div>
          <div *ngIf="editPassword">
            <div class="form-item">
              <label for="password" [innerHTML]="'Password' | translate">}</label>
              <div class="d-flex">
                <input class="w-100" type="password" id="password" placeholder="{{'PasswordPlaceholder' | translate}}"  formControlName="password" autocomplete="off">
                <p (click)="handleSwitch()" class="edit-password" [innerHTML]="'Cancel' | translate"></p>
              </div>
              <div *ngIf="updateForm.controls.password.touched || submitted">
                <div class="form-error" *ngIf="updateForm.controls.password?.errors?.required" [innerHTML]="'PasswordErrorEnter' | translate"></div>
              </div>
            </div>
            <div class="form-item">
              <label for="new-password" [innerHTML]="'NewPassword' | translate"></label>
              <input id="new-password" placeholder="{{'NewPasswordPlaceholder' | translate}}" type="password" formControlName="newPassword">
              <div *ngIf="updateForm.controls.newPassword.touched || submitted">
                <div class="form-error" *ngIf="updateForm.controls.newPassword?.errors?.required" [innerHTML]="'NewPasswordRequired' | translate"></div>
              </div>
              <div class="form-error" *ngIf="updateForm.controls.newPassword?.errors?.auth">{{updateForm.controls.newPassword?.errors?.auth}}</div>
            </div>
            <div class="form-item">
              <label for="new-password-confirmation" [innerHTML]="'NewPasswordConfirmation' | translate"></label>
              <input id="new-password-confirmation" placeholder="{{'NewPasswordConfirmationPlaceholder' | translate}}" type="password" formControlName="newPasswordConfirmation">
              <div *ngIf="updateForm.controls.newPasswordConfirmation.touched || submitted">
                <div class="form-error" *ngIf="updateForm.controls.newPasswordConfirmation?.errors?.required" [innerHTML]="'NewPasswordConfirmationErrorRequired' | translate"></div>
                <div class="form-error" *ngIf="!updateForm.controls.newPasswordConfirmation?.errors?.required && updateForm.errors?.mismatch" [innerHTML]="'NewPasswordConfirmationErrorMismatch' | translate"></div>
              </div>
            </div>
          </div>
          <p *ngIf="error" class="alert alert-danger border-0 mt-3">{{ errorMsg }}</p>
          <div class="button-wrapper d-flex justify-content-center flex-column">
            <button class="w-100" type="submit">
              <app-rectangle-button [loading]="submitLoading" dark [label]="'Save' | translate"></app-rectangle-button>
            </button>
            <button (click)="modal.hide()" class="w-100" >
              <app-rectangle-button  [label]="'Cancel' | translate"></app-rectangle-button>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<app-upload-image-popup #uploadAvatar (avatar)="changeAvatar($event)"></app-upload-image-popup>
