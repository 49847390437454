<div class="get-in-touch">
  <div class="container">
    <div class="row">
      <div class="d-none d-md-block col-12 offset-3 col-md-6 p-0">
        <h3 *ngIf="data.title; else noTitle">{{data.title}}</h3>
        <ng-template #noTitle>
          <h3 ></h3>
        </ng-template>
      </div>

      <div class="d-md-none">
        <div class="text-center">
          <h2 *ngIf="data.title; else noTitle2">{{data.title}}</h2>
          <ng-template #noTitle2>
            <h2 [innerHTML]="'GetInTouch' | translate"></h2>
          </ng-template>
        </div>
      </div>

    </div>
    <div class="row git-desc" *ngIf="data.description">
      <div class="col-12 offset-md-3 col-md-6 p-md-0 px-2" [innerHTML]="data.description">
      </div>
    </div>
    <div class="row">
      <div class=" col-12 offset-md-3 col-md-6 p-md-0 px-2">
        <form #contactForm="ngForm" (ngSubmit)="contactForm.form.valid &&  onSubmit(contactForm)" >
          <input [disabled]="loading" type="hidden" id="referrer" name="referrer" value="{{ previewPageUrl }}"  #referrer="ngModel" [(ngModel)]="previewPageUrl">
          <div class="form-group">
            <label for="name" [ngClass]="{selected: focusElem == 'name' }" [innerHTML]="'Name' | translate"></label>
            <input [disabled]="loading"
                   required
                   ngModel name="name"
                   id="name"
                   type="text"
                   class="form-control"
                   #name="ngModel"
                   placeholder="{{'NamePlaceholder' | translate}}"
                   (focus)="setFocus('name')"
                   (focusout)="setFocus('')">
            <span class="alert" *ngIf="(contactForm.submitted || contactForm.controls['name'].touched ) && contactForm.controls['name'].invalid" [innerHTML]="'NameErrorRequired' | translate"></span>
          </div>
          <div class="form-group">
            <label for="email" [ngClass]="{alertXX: email.touched && !email.valid, selected: focusElem == 'email' }" [innerHTML]="'Email' | translate">*</label>
            <input required
                   email
                   [disabled]="loading"
                   ngModel name="email"
                   id="email"
                   type="text"
                   class="form-control"
                   #email="ngModel"
                   [email]="validateEmail(email.value)"
                   placeholder="{{'EmailPlaceholder' | translate}}"
                   (focus)="setFocus('email')"
                   (focusout)="setFocus('')">
            <div class="alert" *ngIf="!isValideMail && email.touched" [innerHTML]="'EmailErrorValid' | translate"></div>
            <span class="alert" *ngIf="contactForm.submitted && !email.valid && !email.touched" [innerHTML]="'EmailErrorRequired' | translate"></span>
          </div>
          <div class="form-group">
            <label for="company" [ngClass]="{selected: focusElem == 'company' }" [innerHTML]="'Company' | translate"></label>
            <input [disabled]="loading"
                   ngModel
                   name="company"
                   id="company"
                   type="text"
                   class="form-control"
                   #company="ngModel"
                   placeholder="{{'CompanyPlaceholder' | translate}}"
                   (focus)="setFocus('company')"
                   (focusout)="setFocus('')" >
          </div>
          <div class="form-group">
            <label for="subjectRequested" [ngClass]="{selected: focusElem == 'subjectRequested' }" [innerHTML]="'Subject' | translate">*</label>
            <input [disabled]="loading"
                   required
                   ngModel name="subjectRequested"
                   id="subjectRequested"
                   type="text"
                   class="form-control"
                   #subjectRequested="ngModel"
                   placeholder="{{'SubjectPlaceholder' | translate}}"
                   (focus)="setFocus('subjectRequested')"
                   (focusout)="setFocus('')" >
            <span class="alert" *ngIf="(contactForm.submitted  || contactForm.controls['subjectRequested'].touched ) && contactForm.controls['subjectRequested'].invalid" [innerHTML]="'RequestIsAbout' | translate"></span>
          </div>
          <div class="form-group">
            <label for="message" [ngClass]="{selected: focusElem == 'message' }" [innerHTML]="'Message' | translate"></label>
            <textarea
                [disabled]="loading"
                ngModel
                name="message"
                id="message"
                rows="8"
                cols="50"
                #message="ngModel"
                placeholder="{{'MessagePlaceholder' | translate}}"
                (focus)="setFocus('message')"
                (focusout)="setFocus('')" >
            </textarea>
          </div>
          <div class="form-check d-flex justify-content-start">
            <input
                required
                ngModel
                class="form-check-input"
                type="checkbox"
                value=""
                id="pp"
                name="pp"
                #pp="ngModel" >
            <label class="form-check-label mt-0" for="pp"
                   [ngClass]="{alert: contactForm.submitted && pp.invalid, selected: focusElem == 'pp' }"
                   [innerHTML]="'Agree3' | translate"
            >
              <a class="pp paragraph" [routerLink]="'/privacy-policy'" target="_blank" [innerHTML]="'PrivacyPolicy3' | translate"></a>
            </label>
          </div>
          <div *ngIf="noSend" class="error-box">
            <div class="alert alert-danger" role="alert">
              <p>{{'TryAgain' | translate}} <a class="alert-link"  href="mailto:contact@scoopandspoon.com?subject=Problem%20with%20contact">contact@scoopandspoon.com</a></p>
            </div>
            <br/>
          </div>
          <div class="form-group d-flex justify-content-center">
            <button [disabled]="loading" class="form-button-submit" type="submit">
              <div *ngIf="loading" class="spinner-border" role="status">
                <span class="visually-hidden" [innerHTML]="'Loading' | translate"></span>
              </div>
              <span *ngIf="!loading">
                Submit
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
