import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-arrow-next',
  templateUrl: './arrow-next.component.html',
  styleUrls: ['./arrow-next.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArrowNextComponent implements OnInit {
  @Input() dark: string;
  @Input() big: string;
  @Input() black: string;

  constructor() { }

  ngOnInit(): void { }
}
