import { Component, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Input() data: any;
  isMobileResolution: boolean;
  start = 0;
  end = 0;
  count = 3;
  total = 0;

  constructor(  @Inject(PLATFORM_ID) private platformId: object ) { }

  @HostListener('window:resize', [])
  private onResize(): void  {
    this.detectScreenSize();
  }

  ngOnInit(): void {
    this.end = this.count;
    if (this.data.ccCardItem?.length) {
      this.total = this.data.ccCardItem?.length;
    }
    this.detectScreenSize();
  }

  private detectScreenSize(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    if (window.innerWidth < 768) {
      this.isMobileResolution = true;
      this.end = this.count;
    } else {
      this.isMobileResolution = false;
      this.end = this.total;
    }
  }

  handleShowMore(): void {
    this.end = this.total;
    if (this.end > this.total) {
      this.end = this.total;
    }
  }

}
