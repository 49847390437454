import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CarouselComponent } from '../../shared/sliders/carousel/carousel.component';
import { TestimonialSection } from '../../models/testimonial-section.model';

@Component({
  selector: 'app-quote-slider-section',
  templateUrl: './quote-slider-section.component.html',
  styleUrls: ['./quote-slider-section.component.scss']
})
export class QuoteSliderSectionComponent implements OnInit {
  @ViewChild('carouselComponent') carouselComponent: CarouselComponent;
  @Input() componentClass = 'px-2 p-md-0';
  @Input() slidesToShow: number;
  @Input() dark: string;
  @Input() isArrowContainer: boolean;
  @Input() data: TestimonialSection;

  // tslint:disable-next-line:variable-name
  _slideConfig: any = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  constructor() { }

  ngOnInit(): void { }
}
