import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-custom-component',
  templateUrl: './custom-component.component.html',
  styleUrls: ['./custom-component.component.scss']
})
export class CustomComponentComponent implements OnInit {

  @Input() data: any;
  myStyle: SafeHtml;

  constructor(
    private  _sanitizer: DomSanitizer,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    if ( this.globalService.getIsDebuggingEnabled() ) {
      console.group('Custom Component');
      console.dir( this.data);
      console.groupEnd();
    }
  }

  getStyles(): any {
    if (this.data.style) {
      return this._sanitizer.bypassSecurityTrustHtml('<style>' + this.data.style + '</style>');
    }
    return;
  }

}
