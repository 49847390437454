export class SeoUtils {
  public static generateID(link: string): string {
    link = this.removeFirstChar(link);
    return 'CTA-id-' + link;
  }
  private static removeFirstChar(str: string): string {
    if ( str.startsWith('/') || str.startsWith('-') ) {
      str = str.substring(1);
      this.removeFirstChar(str);
    }
    return str;
  }
}
