
<div class="live-search-wrapper" (touchstart)="blurThis()">
  <p *ngIf="loading" [innerHTML]="'Searching' | translate"></p>
  <div class="category-recommendation">
    <div class="category-item"  *ngFor="let category of categories" [ngClass]="(liveSearchAllResults.selectedId == category.externalId) ? 'active' : ''">
      <img src="/assets/atoms-icons-category.png" alt="">
      <p (click)="handleCatClick(category.title)">{{category.title}}</p>
    </div>
  </div>
  <div>
    <div class="content-item" *ngFor="let content of contents" [ngClass]="(liveSearchAllResults.selectedId == content.externalId) ? 'active' : ''">
      <ng-container *ngIf="content.coverThumbnailMedium">
        <ng-container *ngIf="(content.isExternalUrl) then external else local"></ng-container>
        <ng-template #local>
          <a [routerLink]="'../' + content.url">
            <img *ngIf="content?.type!.includes('page'); else showChannel"   [src]="content.coverThumbnailSmall ? content.coverThumbnailSmall : content.thumbnail" [alt]="content.imageAlt">
            <ng-template #showChannel >
              <img class="channel-image"
                   srcset="{{content.roundImageRetinaSmall}} 2x"
                   src="{{content.roundImageSmall}}"
                   [alt]="content.imageAlt" >
            </ng-template>
          </a>
        </ng-template>
        <ng-template #external>
          <a [href]="content.url" target="_blank">
            <img *ngIf="content?.type!.includes('page'); else showChannel"   [src]="content.coverThumbnailSmall ? content.coverThumbnailSmall : content.thumbnail" [alt]="content.imageAlt">
            <ng-template #showChannel >
              <img class="channel-image"
                   srcset="{{content.roundImageRetinaSmall}} 2x"
                   src="{{content.roundImageSmall}}"
                   [alt]="content.imageAlt" >
            </ng-template>
          </a>
        </ng-template>
        <div [ngClass]="content.tag.includes('Channel')?'d-flex align-items-center':'' ">
          <ng-container *ngIf="(content.isExternalUrl) then external2 else local2"></ng-container>
          <ng-template #local2>
            <a [routerLink]="'../' + content.url">
              <h5 class="search-result-title">{{content.title}}</h5>
            </a>
          </ng-template>
          <ng-template #external2>
            <a [href]="content.url" target="_blank">
              <h5 class="search-result-title">{{content.title}}</h5>
            </a>
          </ng-template>
          <p class="small" [ngClass]="content.tag.includes('Channel')?'channel-border':'' ">{{content.tag}}</p>
        </div>
      </ng-container>
    </div>
  </div>
</div>
