import {Component, Input, OnInit} from '@angular/core';
import {Awards} from '../../models/world-map.model';

@Component({
  selector: 'app-awards',
  templateUrl: './awards.component.html',
  styleUrls: ['./awards.component.scss']
})
export class AwardsComponent implements OnInit {
  @Input() data: Awards;
  contentList: any[] = [];

  constructor() { }

  ngOnInit(): void {
    // @ts-ignore
    this.contentList = this.data.gridList.slice(0, +this.data.size);
  }

  loadMore(): void {
    const currentLength = this.contentList.length;
    if (currentLength < this.data.gridList.length) {
      // @ts-ignore
      this.contentList = this.contentList.concat(this.data.gridList.slice(currentLength, +this.data.size + currentLength));
    } else {
      // @ts-ignore
      this.contentList = this.data.gridList.slice(0, +this.data.size);
    }
  }
}
