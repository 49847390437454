import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Text } from '../../../models/text.model';

@Component({
  selector: 'app-headline',
  templateUrl: './headline.component.html',
  styleUrls: ['./headline.component.scss']
})
export class HeadlineComponent implements OnInit {
  @HostBinding('class')
  elementClass = 'text-item';
  @Input() data: Text;

  constructor() { }

  ngOnInit(): void { }
}
