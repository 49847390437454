import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { HeroComponent } from './components/hero/hero.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieBannerComponent } from './components/cookie-banner/cookie-banner.component';
import { JobsSectionComponent } from './components/jobs-section/jobs-section.component';
import { CategoryRecommenderComponent } from './components/category-recommender/category-recommender.component';
import { NewsSectionComponent } from './components/news-section/news-section.component';
import { HighlightOneSectionComponent } from './components/highlight-one-section/highlight-section.component';
import { TestimonialSectionComponent } from './components/testimonial-section/testimonial-section.component';
import { SlidersModule } from './shared/sliders/sliders.module';
import { ButtonsModule } from './shared/buttons/buttons.module';
import { HighlightTwoSectionComponent } from './components/highlight-two-section/highlight-two-section.component';
import { LinkToComponent } from './components/link-to/link-to.component';
import { PreviewRecommenderComponent } from './components/preview-recommender/preview-recommender.component';
import { RecommenderSectionComponent } from './components/recommender-section/recommender-section.component';
import { SvgComponent } from './components/svg/svg.component';
import { GalleryRecommenderSectionComponent } from './components/gallery-recommender-section/gallery-recommender-section.component';
import { QuoteSliderSectionComponent } from './components/quote-slider-section/quote-slider-section.component';
import { OverviewComponent } from './shared/overview/overview.component';
import { GridComponent } from './components/grid/grid.component';
import { HeroImageComponent } from './components/hero-image/hero-image.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { GalleryQuoteComponent } from './components/gallery-quote/gallery-quote.component';
import { TitleTextQuoteComponent } from './components/text/title-text-quote/title-text-quote.component';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { TextComponent } from './components/text/text/text.component';
import { ImageQuoteTextComponent } from './components/text/image-quote-text/image-quote-text.component';
import { ImageOnlyComponent } from './components/text/image-only/image-only.component';
import { ColumnSubtitleTextComponent } from './components/text/column-subtitle-text/column-subtitle-text.component';
import { LogoGridSectionComponent } from './components/logo-grid-section/logo-grid-section.component';
import { LiveSearchComponent } from './components/live-search/live-search.component';
import { SearchResultsComponent } from './components/search-results/search-results.component';
import { CookieModule } from 'ngx-cookie';
import { GetInTouchSectionComponent } from './components/get-in-touch-section/get-in-touch-section.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { ContactButtonComponent } from './components/contact-button/contact-button.component';
import { ColumnListSectionComponent } from './components/column-list-section/column-list-section.component';
import { NgxFlexMasonryGridModule } from '@offensichtbar-codestock/ngx-flex-masonry-grid';
import { MapComponent } from './shared/sliders/map/map.component';
import { ToastComponent } from './components/toast/toast.component';
import { IframeComponent } from './components/iframe/iframe.component';
import { NewMarketingLandscapeComponent } from './components/new-marketing-landscape/new-marketing-landscape.component';
import { GridManualComponent } from './components/grid-manual/grid-manual.component';
import { HeroSliderComponent } from './components/hero-slider/hero-slider.component';
import { HeadlineComponent } from './components/text/headline/headline.component';
import { environment } from '../environments/environment';
import { HrComponent } from './components/hr/hr.component';
import { ImageTitleTextComponent } from './components/text/image-title-text/image-title-text.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AwardsComponent } from './components/awards/awards.component';
import { AwardAddOnComponent } from './components/award-add-on/award-add-on.component';
import { ModuleGridComponent } from './components/module-grid/module-grid.component';
import { Hotspot2dComponent } from './components/rooms/hotspot2D/hotspot2d.component';
import { MarkerComponent } from './components/rooms/marker/marker.component';
import { PolygonComponent } from './components/rooms/polygon/polygon.component';
import { VimeoVideComponent } from './components/vimeo-vide/vimeo-vide.component';
import { VimeoUrlPipe } from './pipes/vimeo-url.pipe';
import { ComponentsModule } from './modules/components/components.module';
import { Hotspot360Component } from './components/rooms/hotspot360/hotspot360.component';
import { LoginRegisterComponent } from './components/auth/login-register/login-register.component';
import { SignupModalComponent } from './components/auth/signup-modal/signup-modal.component';
import { LoginModalComponent } from './components/auth/login-modal/login-modal.component';
import { MyDataComponent } from './components/auth/my-data/my-data.component';
import { AuthService } from './services/auth.service';
import { ChannelsSubscribeComponent } from './components/subscriptions/channels-subscribe/channels-subscribe.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './components/auth/change-password/change-password.component';
import { ContactComponent } from './components/contact/contact.component';
import { ContactInfoComponent } from './components/contact-info/contact-info.component';
import { MyHistoryComponent } from './components/my-history/my-history.component';
import { CollectionComponent } from './components/collection/collection.component';
import { CollectionsModule } from '@scoop-lli/collections';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PicturesGalleryComponent } from './components/pictures-gallery/pictures-gallery.component';
import { LliModule } from '@scoop-lli/lli';
import { TagsComponent } from './components/tags/tags.component';
import { TagHighlightModule } from '@scoop-lli/tag-highlight';
import { NavigationComponent } from './components/navigation/navigation.component';
import { NavigationModule } from '@scoop-lli/navigation';
import { HeroImageWithTextComponent } from './components/hero-image-with-text/hero-image-with-text.component';
import { CustomComponentComponent } from './components/custom-component/custom-component.component';
import { CtaPromptComponent } from './components/cta-prompt/cta-prompt.component';
import { TranslateModule } from '@ngx-translate/core';
import { TranslationService, translationsFactory } from './services/translation.service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomepageComponent,
    HeroComponent,
    CookieBannerComponent,
    JobsSectionComponent,
    HighlightOneSectionComponent,
    NewsSectionComponent,
    CategoryRecommenderComponent,
    TestimonialSectionComponent,
    SvgComponent,
    HighlightTwoSectionComponent,
    LinkToComponent,
    PreviewRecommenderComponent,
    RecommenderSectionComponent,
    GalleryRecommenderSectionComponent,
    QuoteSliderSectionComponent,
    OverviewComponent,
    GridComponent,
    HeroImageComponent,
    GalleryComponent,
    GalleryQuoteComponent,
    TitleTextQuoteComponent,
    TextComponent,
    ImageQuoteTextComponent,
    ImageOnlyComponent,
    ColumnSubtitleTextComponent,
    LogoGridSectionComponent,
    LiveSearchComponent,
    SearchResultsComponent,
    GetInTouchSectionComponent,
    ContactButtonComponent,
    ColumnListSectionComponent,
    MapComponent,
    ToastComponent,
    IframeComponent,
    NewMarketingLandscapeComponent,
    GridManualComponent,
    HeroSliderComponent,
    HeadlineComponent,
    HrComponent,
    ImageTitleTextComponent,
    NotFoundComponent,
    AwardsComponent,
    AwardAddOnComponent,
    ModuleGridComponent,
    Hotspot2dComponent,
    MarkerComponent,
    VimeoVideComponent,
    VimeoUrlPipe,
    PolygonComponent,
    Hotspot360Component,
    LoginRegisterComponent,
    SignupModalComponent,
    LoginModalComponent,
    MyDataComponent,
    ChannelsSubscribeComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    ContactComponent,
    ContactInfoComponent,
    MyHistoryComponent,
    CollectionComponent,
    PicturesGalleryComponent,
    TagsComponent,
    NavigationComponent,
    HeroImageWithTextComponent,
    CustomComponentComponent,
    CtaPromptComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    TranslateModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SlidersModule,
    CookieModule.forRoot(),
    ButtonsModule,
    LoadingBarModule,
    BrowserTransferStateModule,
    ReactiveFormsModule,
    FormsModule,
    RecaptchaV3Module,
    NgxFlexMasonryGridModule,
    ComponentsModule,
    ButtonsModule,
    CollectionsModule,
    ImageCropperModule,
    LliModule.forRoot(environment),
    TagHighlightModule,
    NavigationModule,
    // LocalizeRouterModule.forRoot(routes, {
    //   initialNavigation: true,
    //   parser: {
    //     provide: LocalizeParser,
    //     useFactory: (translate: TranslateService, location: Location, settings: LocalizeRouterSettings) => {
    //         return new ManualParserLoader(translate, location, settings, ['de', 'en']);
    //       },
    //       deps: [TranslateService, Location, LocalizeRouterSettings]
    //     }
    //   }
    // ),
  ],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha,
    },
    TranslationService,
    {
      provide: APP_INITIALIZER,
      deps: [TranslationService],
      useFactory: translationsFactory,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      deps: [AuthService],
      useFactory: (authService: AuthService) => () => authService.loadUser(),
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})


export class AppModule { }

