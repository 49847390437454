import {AfterViewInit, Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {isPlatformBrowser} from "@angular/common";
import {CtaDownlaodService} from "../../services/cta-downlaod.service";

declare var bootstrap: any;

@Component({
  selector: 'app-cta-prompt',
  templateUrl: './cta-prompt.component.html',
  styleUrls: ['./cta-prompt.component.scss']
})
export class CtaPromptComponent implements OnInit, AfterViewInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private ctaDownload: CtaDownlaodService
  ) { }

  submitted = false;
  show = true;
  modal: any;
  targetId: string;
  error: boolean;
  loading: boolean;
  hasEmail: string|null;
  hasUser: string|null;
  currentUrl: string;
  @ViewChild('modalRef', {static: false}) signupModal: ElementRef;

  submitForm = new FormGroup({
    email: new FormControl('',    [Validators.required,  Validators.email]),
    tac: new FormControl('',  Validators.requiredTrue),
    tac2: new FormControl(''),
  });

  handleSubmit(): any {
    this.submitted = true;
    this.error = false;
    if (this.submitForm.valid) {
      this.loading = true;
      const id = this.targetId.split('_')[this.targetId.split('_').length - 1];
      localStorage.setItem('cta-form-email', this.submitForm.value.email);
      this.ctaDownload.getTarget(id, this.submitForm.value.email, '0', this.currentUrl).then(value => {
        // tslint:disable-next-line:triple-equals
        if (value.status == 201) {
          setTimeout( () => {
            this.close();
            this.ctaDownload.downloadURI(value.uri);
          }, 500 );
          this.error = false;
        } else {
          this.error = true;
        }
        this.modal.hide();
      }).catch(() => {
        this.error = true;
      }).finally(() => {
        this.loading = false;
      });
    }
  }
  ngAfterViewInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.currentUrl = window.location.href;
    this.modal = new bootstrap.Modal(this.signupModal.nativeElement);
    this.ctaDownload.target.subscribe((value) => {
      if (!value) {
        return;
      }
      this.targetId = value;
      this.modal.show();
      if (this.hasUser) {
        const id = this.targetId.split('_')[this.targetId.split('_').length - 1];
        const user = JSON.parse(this.hasUser);
        this.ctaDownload.getTarget(id, user.email, '1', this.currentUrl ).then(data => {
          // tslint:disable-next-line:triple-equals
          if (data.status == 201) {
            setTimeout( () => {
              this.modal.hide();
              this.ctaDownload.downloadURI(data.uri);
            }, 500 );
          } else {
            this.error = true;
          }
          this.modal.hide();
        }).catch(() => {
          this.error = true;
        }).finally(() => {
          this.loading = false;
        });
      }
      if (this.hasEmail && !this.hasUser) {
        const id = this.targetId.split('_')[this.targetId.split('_').length - 1];
        this.ctaDownload.getTarget(id, this.hasEmail, '0', this.currentUrl).then(data => {
          // tslint:disable-next-line:triple-equals
          if (data.status == 201) {
            this.modal.hide();
            setTimeout( () => {
              this.modal.hide();
              this.ctaDownload.downloadURI(data.uri);
            }, 500 );
          } else {
            this.error = true;
          }
          this.modal.hide();
        }).catch(() => {
          this.error = true;
        }).finally(() => {
          this.loading = false;
        });
      }
    });
    this.signupModal.nativeElement.addEventListener('shown.bs.modal', () => {
      this.submitForm.reset();
      this.submitForm.enable();
      this.submitted = false;
      this.error = false;
    });
    this.signupModal.nativeElement.addEventListener('hidden.bs.modal', () => {
      this.submitForm.reset();
      this.submitForm.enable();
      this.submitted = false;
      this.error = false;
    });
  }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.hasEmail = localStorage.getItem('cta-form-email');
    this.hasUser = localStorage.getItem('user');
    this.submitForm.valueChanges.subscribe(form => {
      if (this.submitted) {
        this.submitted = false;
      }
      if (this.error) {
        this.error = false;
      }
    });
  }

  public close(): any {
    this.submitForm.reset();
    this.modal.hide();
  }
}
