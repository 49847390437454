
<div  class="main-wrapper"   [style]="{'--background-color': data.backgroundColor, '--text-color': data.textColor}">
    <div class="left-tan" [style.background-image]="'url(' + brandingBorder.sizes.large + ')'" >
    </div>
    <div class="container _container">
      <div *ngIf="data.post" class="tag-wrapper mobile d-flex  d-lg-none">
        <p *ngIf="data.post"  class="small" style="border-color:{{ data.textColor + '80' }}"  [innerHTML]="data.post.preferences.tag"></p>
        <div class="share-wrapper">
          <app-share  *ngIf="data.post.slug" [linkToPage]="contentLink(data.post.slug)"></app-share>
        </div>
      </div>
      <div class="row d-flex justify-content-end justify-content-lg-start">
        <div class="col-lg-5 col-10 z-index">
          <div class="heading">
            <div class="title-tag">
              <div class="tag-wrapper d-none d-lg-flex">
                <p  *ngIf="data.post"  class="small"  style="border-color:{{ data.textColor + '80' }}"  [innerHTML]="data.post.preferences.tag"></p>
                <div class="share-wrapper">
                  <app-share *ngIf="data.post" [linkToPage]="contentLink(data.post.slug)"></app-share>
                </div>
              </div>
              <h2 *ngIf="data.post" [innerHTML]="data.post.preferences.title"></h2>
              <h2 *ngIf="data.title" [innerHTML]="data.title"></h2>
            </div>
            <div class="learn-more-button">
              <app-underline-button
                *ngIf="data.post"
                [text]="data.linkText"
                [link]="data.post.slug"
              >
              </app-underline-button>
              <app-underline-button
                *ngIf="data.link"
                [text]="data.link.title"
                [link]="data.link.url"
              >
              </app-underline-button>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="image-container">
            <div class="background-image" [style.background-image]="'url(' + brandingBackground.sizes.medium + ')'">
              <div class="image-wrapper">
                <img *ngIf="data.post" [lazyLoad]="data.post.preferences.thumbnailImage.sizes.medium_large" [defaultImage]="data.post.preferences.thumbnailImage.sizes.thumbnail">
                <img *ngIf="data.mainImage" [lazyLoad]="data.mainImage.sizes.medium_large" [defaultImage]="data.mainImage.sizes.thumbnail">
              </div>
            </div>
          </div>
        </div>
        <div class="row p-0 d-flex z-index justify-content-end">
          <div class="col-12 col-lg-6 p-0">
            <div *ngIf="data.post" class="description-wrapper">
              <p *ngIf="data.post" [innerHTML]="data.post.preferences.description"></p>
              <div class="d-lg-none mt-5">
                <app-underline-button
                  *ngIf="data.post"
                  [text]="data.linkText"
                  [link]="data.post.preferences.slug"
                >
                </app-underline-button>
              </div>
            </div>
            <div *ngIf="data.description" class="description-wrapper">
              <p *ngIf="data.description" [innerHTML]="data.description"></p>
              <div class="d-lg-none mt-5">
                <app-underline-button
                  *ngIf="data.link"
                  [text]="data.link.title"
                  [link]="data.link.url"
                >
                </app-underline-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right-tan"  [style.background-image]="'url(' + brandingBorder.sizes.large + ')'">
    </div>
  </div>



