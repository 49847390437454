<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     height="17" width="18" viewBox="0 0 17 18" xml:space="preserve">
  <g id="Symbols">
	<g id="Group-10" transform="translate(-112.000000, -4.000000)">
		<g id="basic-_x2F_-share_x5F_outline" transform="translate(111.216404, 3.000000)">
			<g>
				<g id="basic-_x2F_-share_x5F_outline-_x28_Background_x2F_Mask_x29_">
				</g>
        <defs>
					<filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse" x="1.7" y="1.7" width="15.8" height="16.7">
						<feColorMatrix  type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
					</filter>
				</defs>
        <mask maskUnits="userSpaceOnUse" x="1.7" y="1.7" width="15.8" height="16.7" id="mask-4_1_">
					<g class="st0">
						<rect id="path-3_1_" x="0" y="0" class="st1" width="20" height="20"/>
					</g>
				</mask>
        <path class="st2" d="M6.5,11.7l5.2,3c-0.1,0.2-0.1,0.5-0.1,0.7c0,1.6,1.3,2.9,2.9,2.9s2.9-1.3,2.9-2.9s-1.3-2.9-2.9-2.9
					c-0.8,0-1.4,0.3-2,0.8l-5.2-3c0.1-0.2,0.1-0.4,0.1-0.6l5.1-2.9c0.5,0.5,1.2,0.8,2,0.8c1.6,0,2.9-1.3,2.9-2.9s-1.3-2.9-2.9-2.9
					S11.7,3,11.7,4.6c0,0.2,0,0.5,0.1,0.7L7,8C6.5,7.2,5.6,6.7,4.6,6.7C3,6.7,1.7,8,1.7,9.6s1.3,2.9,2.9,2.9
					C5.3,12.5,6,12.2,6.5,11.7z M15.8,15.4c0,0.7-0.6,1.2-1.2,1.2c-0.7,0-1.2-0.6-1.2-1.2c0-0.7,0.6-1.2,1.2-1.2
					C15.3,14.2,15.8,14.7,15.8,15.4z M15.8,4.6c0,0.7-0.6,1.2-1.2,1.2c-0.7,0-1.2-0.6-1.2-1.2s0.6-1.3,1.2-1.3
					C15.3,3.3,15.8,3.9,15.8,4.6z M5.8,9.6c0,0.7-0.6,1.2-1.2,1.2s-1.3-0.6-1.3-1.2c0-0.7,0.6-1.2,1.3-1.2S5.8,8.9,5.8,9.6z"/>
			</g>
		</g>
	</g>
</g>
</svg>
