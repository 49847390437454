import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-simply-content-lr',
  templateUrl: './simply-content-lr.component.html',
  styleUrls: ['./simply-content-lr.component.scss']
})
export class SimplyContentLRComponent implements OnInit {

  @Input() data: any;

  constructor() { }

  ngOnInit(): void {

  }

}
