import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {ModuleGrid} from '../../models/module-grid';

@Component({
  selector: 'app-module-grid',
  templateUrl: './module-grid.component.html',
  styleUrls: ['./module-grid.component.scss']
})
export class ModuleGridComponent implements OnInit {
  @Input() data: ModuleGrid;
  accordionId = Math.floor(Math.random() * 9999);

  constructor( private sanitizer: DomSanitizer ) { }

  ngOnInit(): void {
  }

  formatNameToClass(value: string): string {
    return value.replace(/[^A-Z0-9]+/ig, '_');
  }

  transformYourHtml(htmlTextWithStyle: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
  }
}
