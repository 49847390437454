<div class="sns-contact" *ngIf="data.showEMail">
  <div class="container px-md-0">
    <div  [ngClass]="data.contactDataCenter? 'col-12 offset-md-2 col-md-8 px-2 px-md-0' : 'col-12 px-2 px-md-0'">
      <div class="contact-email"  [ngClass]="data.contactDataCenter? 'contact-center' : ''">
        <a  *ngIf="data.contactEMail; else elseContact" [href]="'mailto:' + data.contactEMail">{{data.contactEMail}}</a>
        <ng-template #elseContact>
          <a  href="mailto:info@scoopandspoon.com">info@scoopandspoon.com</a>
        </ng-template>
      </div>
    </div>
  </div>
</div>
