
<div class="simply-content-lr">
  <div class="container">
    <div class="row">
      <div class="col col-12 col-md-5 col-lr col-left" *ngIf="data.leftContent">
        <h2 *ngIf="data.leftContent.lcTitle" [innerHTML]="data.leftContent.lcTitle"></h2>
        <p class="lr-desc" *ngIf="data.leftContent.lcDescription" [innerHTML]="data.leftContent.lcDescription"></p>
        <a appLink *ngIf="data.leftContent.lcLink"
           class="btn btn-primary btn-find-more"
           [href]="data.leftContent.lcLink.url"
           [attr.aria-label]="data.leftContent.lcLink.title">
          <span>{{data.leftContent.lcLink.title?data.leftContent.lcLink.title:'Find out more'}}</span>
        </a>
      </div>
      <div class="col col-12 col-md-6 col-lr offset-md-1 col-right" *ngIf="data.rightContent">
        <h2 *ngIf="data.rightContent.rcTitle" [innerHTML]="data.rightContent.rcTitle"></h2>
        <p class="lr-desc" *ngIf="data.rightContent.rcDescription" [innerHTML]="data.rightContent.rcDescription"></p>
        <a appLink *ngIf="data.rightContent.rcLink"
           class="btn btn-primary btn-find-more"
           [href]="data.rightContent.rcLink.url"
           [attr.aria-label]="data.rightContent.rcLink.title">
          <span>{{data.rightContent.rcLink.title?data.rightContent.rcLink.title:'Find out more'}}</span>
        </a>
      </div>
    </div>
  </div>

</div>
