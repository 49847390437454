<div class="header-wrapper" [style.position]="isHeadTrans || isSticky ? 'fixed' : 'sticky'"  [ngClass]="{'white': white}" *ngIf="$data | async as data">
  <div class="main-header">
    <div class="header-background" [style.opacity]="backgroundOpacity"></div>
    <div class="header-logo-wrapper" [ngClass]="{'isFocused': isFocused}">
      <div class="prev-arrow d-none" (click)="handleCloseFocus()">
        <svg class="prev-arrow-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="30">
          <defs>
            <filter id="a" color-interpolation-filters="auto">
              <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0"/>
            </filter>
            <filter id="b" color-interpolation-filters="auto">
              <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.254902 0 0 0 0 0.254902 0 0 0 0 0.254902 0 0 0 1.000000 0"/>
            </filter>
            <path id="c" d="M0 0h30v30H0z"/>
          </defs>
          <g filter="url(#a)" transform="translate(-16 -24)" fill="none" fill-rule="evenodd">
            <g filter="url(#b)" transform="translate(16 24)">
              <mask id="d" fill="#fff">
                <use xlink:href="#c"/>
              </mask>
              <path fill="#2E3A59" fill-rule="nonzero" d="M19.42 4.4L8.82 15l10.6 10.6 1.77-1.76L12.35 15l8.84-8.84-1.77-1.77z" mask="url(#d)"/>
            </g>
          </g>
        </svg>
      </div>
      <a (click)="resetWhiteColorLogo()" [routerLink]="'../'">
        <app-svg [url]="data.leftLogo" ></app-svg>
      </a>
    </div>
    <div class="header-hamburger-menu" [ngClass]="{'isNaviFocused': isNaviFocused}">
      <svg class="hamburger-svg" xmlns="http://www.w3.org/2000/svg" width="29" height="18" viewBox="0 0 29 18" (click)="handleNaviClick(); whiteHeaderOnClick();">
        <g fill="none" fill-rule="evenodd">
          <g fill="#FFF">
            <g>
              <path d="M28.5 15v3H0v-3h28.5zm0-7.5v3H0v-3h28.5zm0-7.5v3H0V0h28.5z" transform="translate(-241.000000, -29.000000) translate(241.000000, 29.000000)"/>
            </g>
          </g>
        </g>
      </svg>
      <svg class="X"  (click)="handleNaviClick(); whiteHeaderOnClick();" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/></svg>
    </div>
    <div class="header-search-bar-wrapper" [ngClass]="{'isFocused': isFocused}">
        <input
          [ngClass]="{'isFocused': isFocused}"
          #input
          (keyup.enter)="handleEnter()"
          (input)="handleInput(input.value)"
          (focusin)="handleFocus(input.value)"
          (keydown.ArrowDown)="handleOnDown()"
          (keydown.arrowUp)="handleOnUp()"
          placeholder="{{'Search' | translate}}"
          type="text"
        >
    </div>
    <div  class="mag-wrapper"  [ngClass]="{'isFocused': isFocused}">
      <svg (click)="handleMagClick()" class="mag"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 23">
        <path
          fill="red"
          d="M23.7 21.4l-5.8-5.5a9.6 9.6 0
            002.3-6.1c0-5.4-4.5-9.7-10.1-9.7C4.5 0 0 4.3 0 9.7s4.5 9.7 10.1 9.7c2.4 0 4.7-.8 6.4-2.2l5.8 5.5c.4.4
            1 .4 1.4 0 .4-.4.4-1 0-1.3zm-13.6-3.9C5.6 17.5 2 14 2 9.7s3.6-7.8 8.1-7.8 8.1 3.5 8.1 7.8-3.6 7.8-8.1 7.8z"
        />
      </svg>
    <svg class="X d-none"  (click)="handleMagClick()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/></svg>
    </div>
    <div class="header-sub-logo-wrapper d-flex justify-content-center" [ngClass]="{'isFocused': isFocused}">
      <app-language-switch *ngIf="isLanguageToggle && user"></app-language-switch>
    <!--  <app-language-switch></app-language-switch>-->
      <app-login-register></app-login-register>
      <!--<app-svg [url]="data.rightLogo" class="align-self-center" ></app-svg>-->
    </div>
  </div>
  <div *ngIf="isFocused" class="dropdown-background-overlay"></div>
  <div class="menu-dropdown" *ngIf="isFocused">
    <div class="container">
      <div class="row">
        <app-live-search
        [value]="inputValue.asObservable()"
        (liveResultsEvent)="handleLiveResults($event)"
        [liveSearchAllResults]="liveSearchAllResults"
        ></app-live-search>
      </div>
      <div class="row">
        <div class="col">
          <ng-container *ngFor="let item of data.quickLinks">
            <a *ngIf="!item.logo" [routerLink]="'../' + item.link.url" [innerHTML]="item.link.title">
            </a>
            <a *ngIf="item.logo" class="quickLinksItem quickLinksItem-logo" [routerLink]="'../' + item.link.url">
              <img [src]="item.logo.sizes.thumbnail" [alt]="item.logo.alt">
            </a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="menu-dropdown" *ngIf="isNaviFocused">
    <div class="container-fluid">
      <div class="row">
      <app-navigation [navigation]="data.navigation">
      </app-navigation>
      </div>
    </div>
  </div>
</div>




