import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ShareIconComponent} from './share-icon/share-icon.component';
import { InstagramIconComponent } from './instagram-icon/instagram-icon.component';
import { FacebookIconComponent } from './facebook-icon/facebook-icon.component';
import { LinkedinIconComponent } from './linkedin-icon/linkedin-icon.component';
import { TwitterIconComponent } from './twitter-icon/twitter-icon.component';

@NgModule({
  declarations: [
    ShareIconComponent,
    InstagramIconComponent,
    FacebookIconComponent,
    LinkedinIconComponent,
    TwitterIconComponent
  ],
  imports: [
    CommonModule],
  exports: [
    ShareIconComponent,
    FacebookIconComponent,
    InstagramIconComponent,
    LinkedinIconComponent,
    TwitterIconComponent
  ],
})
export class IconsModule {}
