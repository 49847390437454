<lib-navigation [navigation]="navigation" [initialTemplate]="newNavigation"></lib-navigation>
<ng-template #newNavigation >
  <div class="dropdown-background-overlay"></div>
  <div class="dropdown-menu-wrapper position-relative">
    <div class="vertical-img"  [style.background-image]="'url(' + brandingBackground.sizes.medium + ')'"></div>
    <div class="row">
      <div class="col-sm-12 col-lg-7 left-block position-relative">
        <div class="title">
          <h1>{{navigation.quickLinksTitle}}</h1>
        </div>
        <div class="quick-links">
          <ng-container *ngFor="let qs of navigation.quickLinks">
            <a *ngIf="!qs.logo" [ngClass]="qs.link?'quickLinksItem':'delimiter'"  [routerLink]="qs.link.url">
              {{qs.link.title}}
            </a>
            <a *ngIf="qs.logo" class="quickLinksItem quickLinksItem-logo" [routerLink]="qs.link.url">
              <img [src]="qs.logo.sizes.thumbnail" [alt]="qs.logo.alt" loading="lazy">
            </a>
          </ng-container>

      </div>
        <div class="navigation-links-block">
          <div class="title"><h3>{{navigation.linksTitle}}</h3></div>
          <div class="navigation-links d-flex">
            <a class="linkItem" *ngFor="let link of navigation.links" [routerLink]="link.link.url"><span [innerHTML]="link.link.title"></span></a>
          </div>
        </div>
        <div class="horizontal-img" [style.background-image]="'url(' + brandingBackground.sizes.medium + ')'"></div>
      </div>

      <div class="col-sm-12 col-lg-5 right-block">
        <div class="info-block">
            <div class="title">
              <h1>{{infoBox.title}}</h1>
            </div>
            <div class="email">
              <a [href]="'mailto:' + infoBox.eMail">{{infoBox.eMail}}</a>
            </div>
            <div class="phone">
              <a [href]="'tel:' + infoBox.tel">{{infoBox.tel}}</a>
            </div>
        </div>
        <div class="row contact-form position-relative" [style.background-image]="'url(' + brandingBackground.sizes.medium + ')'">
          <div class="overlay"></div>
            <a [href]="infoBox.contact.url">
              <div class="cta">{{infoBox.contact.title}}</div>
            </a>
        </div>
      </div>
    </div>
  </div>
</ng-template>
