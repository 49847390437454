import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ImageQuoteTextModel } from '../../../models/image-quote-text.model';

@Component({
  selector: 'app-image-quote-text',
  templateUrl: './image-quote-text.component.html',
  styleUrls: ['./image-quote-text.component.scss']
})

export class ImageQuoteTextComponent implements OnInit {
  @HostBinding('class')
  elementClass = 'text-item';
  @Input() data: ImageQuoteTextModel;

  constructor() { }

  ngOnInit(): void { }
}
