import { Component, HostBinding, Input, OnInit } from '@angular/core';
import {HighlightSection} from '../../models/highlight-section.model';
import {GeneralSettingsService} from '../../services/general-settings.service';


@Component({
  selector: 'app-highlight-section',
  templateUrl: './highlight-section.component.html',
  styleUrls: ['./highlight-section.component.scss']
})
export class HighlightOneSectionComponent implements OnInit {
  @HostBinding('class')
  elementClass = 'text-item';
  @Input() data: HighlightSection;
  brandingBorder: any;
  brandingBackground: any;

  constructor( private generalSettingsService: GeneralSettingsService ) { }

  ngOnInit(): void {
    this.generalSettingsService.$theme.subscribe((theme: any ) => {
      this.brandingBorder = theme.brandingBorder;
      this.brandingBackground = theme.brandingBackgroundImage;
    });
  }

  contentLink(url: any): string {
    if (url) {
      return url;
    }
    return '';
  }
}
