<div class="modal cta-d fade show" #modalRef *ngIf="show"  id="exampleModalToggle" aria-labelledby="exampleModalToggleLabel" tabindex="-1" aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered px-3">
    <div class="modal-content _modal-content">
      <div class="modal-body _modal-body">
        <div *ngIf="hasEmail || hasUser">
          <div class="d-flex justify-content-center p-5">
            <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
              <span class="visually-hidden">{{'Loading' | translate}}</span>
            </div>
          </div>
        </div>
        <div *ngIf="!hasEmail && !hasUser" class="d-flex flex-column flex-md-row">
          <div class="section-background">
            <h3 [innerHTML]="'RiverDrive' | translate"></h3>
            <p [innerHTML]="'RiverFind' | translate"></p>
            <ul>
              <li class="d-flex align-items-center"><div class="bullet">✓</div><span [innerHTML]="'RiverLi1' | translate"></span></li>
              <li class="d-flex align-items-center"><div class="bullet">✓</div><span [innerHTML]="'RiverLi2' | translate"></span></li>
              <li class="d-flex align-items-center"><div class="bullet">✓</div><span [innerHTML]="'RiverLi3' | translate"></span></li>
              <li class="d-flex align-items-center"><div class="bullet">✓</div><span [innerHTML]="'RiverLi4' | translate"></span></li>
            </ul>
            <p [innerHTML]="'RiverInfo1' | translate">
            </p>
            <svg class="d-block d-md-none"(click)="close()" width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <defs>
                <path id="31eyi78rba" d="M0 0h40v40H0z"/>
              </defs>
              <g fill="none" fill-rule="evenodd">
                <mask id="5h4ed2ag9b" fill="#fff">
                  <use xlink:href="#31eyi78rba"/>
                </mask>
                <path d="M21.667 21.667v10h-3.334v-10h-10v-3.334h10v-10h3.334v10h10v3.334h-10z" fill="#fff" fill-rule="nonzero" mask="url(#5h4ed2ag9b)" transform="rotate(45 20 20)"/>
              </g>
            </svg>
          </div>
          <div class="section-data">
            <h3 class="title" [innerHTML]="'FindOutMore' | translate"></h3>
            <p class="description" [innerHTML]="'RiverInfo2' | translate"></p>
            <br />
            <p class="description" [innerHTML]="'RiverInfo3' | translate"></p>
<!--            <div class="d-flex flex-column flex-md-row button-wrap">-->
<!--              <button class="mb-3 m-md-0 me-0 me-md-3">Download and go</button>-->
<!--              <button>Create an Account</button>-->
<!--            </div>-->
            <form [formGroup]="submitForm">
              <div class="email-input">
                <input [disabled]="loading" type="text" formControlName="email" class="w-100 _form-control" placeholder="E-Mail Address*">
                <div *ngIf="submitForm.controls.email.touched || submitted"
                     class="mt-2 text-danger small ">
                  <div class="form-error" *ngIf="submitForm.controls.email.errors?.required" [innerHTML]="'EmailErrorRequired' | translate">
                  </div>
                  <div class="form-error" *ngIf="submitForm.controls.email.errors?.email" [innerHTML]="'EmailErrorValid' | translate">
                  </div>
                  <div class="form-error" *ngIf="error" [innerHTML]="'DownloadError' | translate">
                  </div>
                </div>
              </div>
              <div class="d-flex flex-column align-items-start checkbox-wrap">
                <div class="align-items-center ">
                  <input [disabled]="loading" id="tac" name="tac" class="me-2 align-middle" formControlName="tac" type="checkbox">
                  <label for="tac"  [innerHTML]="'Agree1' | translate"></label>
                  <span>&nbsp;</span><a [routerLink]="'/imprint'" target="_blank" class="text-decoration-underline" [innerHTML]="'Agree2' | translate"></a>
                </div>
                <div *ngIf="submitForm.controls.tac.touched || submitted">
                  <div class="form-error" *ngIf="submitForm.controls.tac.errors?.required" [innerHTML]="'Conditions' | translate"></div>
                </div>
<!--                <div class="d-flex">-->
<!--                  <input name="tac2" class="me-2" formControlName="tac2" type="checkbox">-->
<!--                  <label for="tac2">Yes, I would like to receive Marketing Emails</label>-->
<!--                </div>-->
              </div>
              <app-rectangle-button [loading]="loading" dark="true" (onClick)="handleSubmit()" label="{{'Download' | translate}}"></app-rectangle-button>
            </form>
            <svg class="d-none d-md-block" (click)="close()" width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <defs>
                <path id="31eyi78rba" d="M0 0h40v40H0z"/>
              </defs>
              <g fill="none" fill-rule="evenodd">
                <mask id="5h4ed2ag9b" fill="#fff">
                  <use xlink:href="#31eyi78rba"/>
                </mask>
                <path d="M21.667 21.667v10h-3.334v-10h-10v-3.334h10v-10h3.334v10h10v3.334h-10z" fill="#2A2A29" fill-rule="nonzero" mask="url(#5h4ed2ag9b)" transform="rotate(45 20 20)"/>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
