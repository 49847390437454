<div class="hr-section" *ngIf="!data.isAccordion">
  <div class="container px-6 px-md-0">
    <h4 class="mb-3" *ngIf="data.hrTitle">{{data.hrTitle}} </h4>
      <ng-container *ngTemplateOutlet="iframe"></ng-container>
  </div>
</div>

<ng-template #iframe>
  <div #psJobWidget id="psJobWidget"></div>
</ng-template>

<ng-template #mobile>
  <div class="accordion accordion-flush accordion-mobile px-2" id="{{ gId }}">
    <div class="accordion-item">
      <h2 class="accordion-header" id="{{ gItemId }}" >
        <button class="accordion-button px-0 collapsed" type="button" data-bs-toggle="collapse" attr.data-bs-target="#{{ gTargetId }}" aria-expanded="false" attr.aria-controls="{{ gTargetId }}"  [innerHTML]="data.hrTitle" (click)="content();">
        </button>
      </h2>
      <div id="{{ gTargetId }}" class="accordion-collapse collapse"  attr.aria-labelledby="{{ gItemId }}" attr.data-bs-parent="#{{ gId }}">
        <ng-container *ngTemplateOutlet="iframe"></ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #desktop>
  <div class="row">
    <div class="col-4 ps-0">
      <h3 [innerHTML]="data.hrTitle"></h3>
    </div>
    <div class="col-8 col-lg-7 pe-0">
      <ng-container *ngTemplateOutlet="iframe"></ng-container>
    </div>
  </div>
</ng-template>

<div *ngIf="data.isAccordion" class="column-subtitle-text" [style]="{'background-color': data.backgroundColor, '--text-color': data.textColor}">
  <div class="container py-md-5">
    <ng-container *ngTemplateOutlet="isMobileResolution? mobile : desktop"></ng-container>
  </div>
</div>

