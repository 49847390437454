<div class="contact-info">
  <div class="container px-6  p-md-0">
    <div class="row title-row">
      <h4>{{data.contactInfoTitle}}</h4>
    </div>
    <div class="row content-row">
      <div class="d-md-flex justify-content--start flex-wrap content-row-info">
        <div class="">
          <img class="image" [src]="data.contactInfoImage.sizes.thumbnail" [alt]="data.contactInfoImage?.alt" loading="lazy">
        </div>
        <div class="p-md-10 contact-data text-start">
          <address class="contact-data-a" >
            <h5 style="margin: 0;">{{data.contactInfoName}}</h5>
            <p class="contact-position">{{data.contactInfoPosition}}</p>
            <p *ngFor="let email of data.contactInfoEmails" >
              <a href="mailto:{{email.contactInfoEmail}}">
                {{email.contactInfoEmail}}
              </a>
            </p>
            <p *ngFor="let tel of data.contactInfoTelNumbers">
              <a href="tel:{{tel.contactInfoTelNumber}}" >
                {{tel.contactInfoTelNumber}}
              </a>
            </p>
          </address>
          <app-underline-button *ngIf="data.contactInfoLink?.contactInfoLinkLink"
              class="contact-link"
              [link]="data.contactInfoLink?.contactInfoLinkLink"
              [text]="data.contactInfoLink?.contactInfoLinkText"
              [color]="data.textColor"
          >
          </app-underline-button>
        </div>
      </div>
    </div>
  </div>
</div>
