export class User {
  id: string;
  email: string;
  // tslint:disable-next-line:variable-name
  first_name: string;
  // tslint:disable-next-line:variable-name
  last_name: string;
  // tslint:disable-next-line:variable-name
  profile_picture: string;
  token: string;
  featureToggles?: string;
  // tslint:disable-next-line:variable-name
  preferred_language?: string;
}

export class RegisterObj {
  email: string;
  firstname: string;
  lastname: string;
  password: string;
  preflang: string;
}

export class ForgotPasswordObj {
  email: string;
  preflang?: string;
}
