import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-linkedin-icon',
  templateUrl: './linkedin-icon.component.html',
  styleUrls: ['./linkedin-icon.component.scss']
})
export class LinkedinIconComponent implements OnInit {
  @Input() title: string;
  @Input() linkToPage: string;

  constructor() { }

  ngOnInit(): void { }

  getLink(): string {
    if (this.linkToPage) {
      let url = this.linkToPage;
      if (this.linkToPage.startsWith('https://') || this.linkToPage.startsWith('http://') ){
        if (this.linkToPage.includes('.marketing\/\/') ){
          const splitted = this.linkToPage.split('.marketing\/\/');
          this.linkToPage = splitted[1];
        }
        url = window.location.protocol + '//' + window.location.host + '/' + this.linkToPage;
      } else {
        if (this.linkToPage.startsWith('\/') ) {
          this.linkToPage = this.linkToPage.substring(1);
        }
        url = window.location.protocol + '//' + window.location.host + '/' + this.linkToPage;
        /*if (this.title.length > 0) {
          url = url + '&text=' + encodeURIComponent(this.title);
        }*/
      }
      // return 'https://www.linkedin.com/shareArticle?mini=true&amp;url=' + (encodeURIComponent(url));
      return 'https://www.linkedin.com/sharing/share-offsite/?url=' + (encodeURIComponent(url));
    }
    return '';
  }
}
