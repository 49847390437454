import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-instagram-icon',
  templateUrl: './instagram-icon.component.html',
  styleUrls: ['./instagram-icon.component.scss']
})
export class InstagramIconComponent implements OnInit {
  @Input() title: string;
  @Input() linkToPage: string;

  constructor() { }

  ngOnInit(): void { }

  getLink(): string {
    let url = window.location.protocol + '//' + window.location.host + '/' + this.linkToPage;
    if (this.linkToPage.startsWith('https://') || this.linkToPage.startsWith('https://') ){
      url = this.linkToPage;
    }
    if (this.title.length > 0) {
      url = url + '&text=' + encodeURIComponent(this.title);
    }
    return 'https://twitter.com/intent/tweet?' + (encodeURIComponent(url));
  }
}
