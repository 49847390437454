import { Component, Input, OnInit } from '@angular/core';
import { Content } from '../../models/limitless-interaction.model';
import { LimitlessInteractionService } from '../../services/limitless-interaction.service';
import { GeneralSettingsService } from '../../services/general-settings.service';
import { SeoUtils } from '../../utils/seo.utilis';

@Component({
  selector: 'app-gallery-recommender-section',
  templateUrl: './gallery-recommender-section.component.html',
  styleUrls: ['./gallery-recommender-section.component.scss']
})
export class GalleryRecommenderSectionComponent implements OnInit {
  @Input() data: any;
  @Input() contextCategories: number[] = [];
  @Input() parentId: any;
  @Input() primaryCategoryId: string;
  recData: Content[] = [];
  brandingBorder: any;

  // tslint:disable-next-line:variable-name
  _slideConfig: any = {
    infinite: false,
    slidesToShow: 2.5,
    variableWidth: true,
    slidesToScroll: 2,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1.05,
          variableWidth: false,
          slidesToScroll: 1,
        },
      },
    ],
  };

  constructor(
    private recommenderService: LimitlessInteractionService,
    private generalSettingsService: GeneralSettingsService
  ) { }

  async ngOnInit(): Promise<void> {
    this.generalSettingsService.$theme.subscribe(data => {
      if (!data) {return; }
      // @ts-ignore
      this.brandingBorder = data.brandingBorder;
    });
    this.recData = await this.recommenderService
      .loadRecommender(this.data.recommender, this.parentId, this.contextCategories, this.primaryCategoryId);
  }

  generatedId(link: string): string {
    return SeoUtils.generateID(link);
  }
}
