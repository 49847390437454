import { Component, Input, OnInit } from '@angular/core';
import { HomepageService } from '../../services/homepage.service';
import { GeneralSettingsService } from '../../services/general-settings.service';

@Component({
  selector: 'app-hero-image-with-text',
  templateUrl: './hero-image-with-text.component.html',
  styleUrls: ['./hero-image-with-text.component.scss']
})
export class HeroImageWithTextComponent implements OnInit {

  @Input() data: any;
  themeColor: '';

  constructor(
    private homePageService: HomepageService,
    private generalSettingsService: GeneralSettingsService
  ) { }

  ngOnInit(): void {
    /*this.homePageService.$data.subscribe(({data}) => {
      this.data = {...data.preferences, ...this.data};
    });*/
    this.generalSettingsService.$theme.subscribe((theme: any ) => {
      this.themeColor = theme.color;
    });
  }

}
