import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-underline-button',
  templateUrl: './underline-button.component.html',
  styleUrls: ['./underline-button.component.scss']
})
export class UnderlineButtonComponent implements OnInit {
  @Input() text: string | undefined  = '';
  @Input() link: string | undefined = '';
  @Input() color: string | undefined = '';
  ctaId: string;

  constructor() { }

  ngOnInit(): void {
    this.ctaId = 'CTA-' + this.link ;
  }
}
