<div class="hero-slider"  [style]="{'background-color': data.backgroundColor, '--text-color': data.textColor}">
  <div class="container px-6 p-md-0" >
    <div class="row">
      <div class="d-flex justify-content-start">
        <h6 class="social-title" style="border-color:{{ data.textColor + '80' }}"  [innerHTML]="data.tag"></h6>
        <app-share class="share-button" [linkToPage]="pageLink()"></app-share>
      </div>
      <h1 [innerHTML]="data.title"></h1>
    </div>
    <div class="row position-relative">
      <app-carousel  dark [slideConfig]="_slideConfig">
        <ng-template misCarouselItem *ngFor="let item of data.slider">
          <div class="hero-slider-box">
            <img class="hero-slider-image"  [lazyLoad]="item.image.url" [defaultImage]="item.image.sizes.thumbnail"  [alt]="item.image.alt">
            <div class="hero-content offset-md-1">
              <p class="lead hero-slider-title">
                {{ item.title }}
              </p>
              <p>
                {{ item.subtitle }}
              </p>
            </div>
          </div>
        </ng-template>
      </app-carousel>
    </div>
  </div>
</div>
