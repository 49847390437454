<div class="grid" [style]="{'background-color': data.backgroundColor, '--text-color': data.textColor}">
  <div class="container p-md-0 px-0">
    <div class="headline-wrapper" *ngIf="data.headline.title || data.headline.subtitle">
      <div class="row">
        <div class="col-12 mb-2">
          <h2 [innerHTML]="data.headline.title"></h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-8">
          <h4 [innerHTML]="data.headline.subtitle"></h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div *ngIf="isMultiCat && data.filterFeature; else noFilter" class="filter-container d-flex align-content-around flex-wrap" >
          <span class="buttonsCTA filter " [ngClass]="{selected: selectId === '0' }" (click)="selectFilter('0')">All</span>
          <span *ngFor="let cat of categoriesNames">
            <span class="buttonsCTA filter d-block" [ngClass]="{selected: selectId === cat.id + '' }" (click)="selectFilter(cat.id)">{{ cat.title }}</span>
          </span>
        </div>
        <ng-template #noFilter>
          <h4 class="grid-title mobile2" >{{ data.subtitle }}</h4>
        </ng-template>
      </div>
    </div>
    <div *ngIf="!loading">
      <div class="grid-container">
        <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
          <div class="col item-elem" [ngClass]="{'text-over-image': data.textOverImage}"  *ngFor="let item of contentsToSow; index as i; " >
            <div class="image-wrapper">
              <a [routerLink]="'../' + item.url" >
                <img class="grid-img"
                     [defaultImage]="item.coverThumbnailSmall ? item.coverThumbnailSmall : item.thumbnail"
                     [lazyLoad]="item.coverThumbnailLarge ? item.coverThumbnailLarge : item.mediumImg"  alt="{{ item.imageAlt }}">
                <div *ngIf="data.textOverImage" class="overlay-wrapper">
                  <h5 class="over-image-text text-center" [innerHTML]="item.title"></h5>
                </div>
              </a>
            </div>
            <div class="position-relative d-flex flex-column h-100">
              <div  class="d-flex text-wrapper align-items-baseline justify-content-start">
                <h6 class="medium-p tag-item d-inline-block social-title" style="border-color:{{ data.textColor + '80' }}"  [innerHTML]="item.tag"></h6>
                <div class="share-wrapper d-inline-block">
                  <app-share [white]="false" [linkToPage]="item.url" ></app-share>
                </div>
              </div>
              <a [routerLink]="'../' + item.url" [title]="item.title">
                <h5 class="title-under" [ngClass]="isClientStories?'max-title':''">{{ item.title }}</h5>
              </a>
              <div class="contact-email" *ngIf="item.showEMail">
                <a  *ngIf="item.contactEMail; else elseContact" [href]="'mailto:' + item.contactEMail">{{item.contactEMail}}</a>
                <ng-template #elseContact>
                  <a  href="mailto:info@scoopandspoon.com">info@scoopandspoon.com</a>
                </ng-template>
              </div>

              <p class="__date small mt-auto" *ngIf="item.showDate === 'true' || item.showDate === true">
                {{toData(item.publishDate)  | date: 'MMMM dd, YYYY' }}
              </p>
            </div>
          </div>
        </div>
      </div>


      <div class="more" *ngIf="(contentsAll.length !== contentsToSow.length) && contentsAll.length > data.contentsPerPage" >
        <div class="row">
          <div class="item-counter  col d-flex justify-content-center">
            <p>{{ contentsToSow.length }} of {{ contentsAll.length  }}</p>
          </div>
        </div>
        <div class="row" *ngIf="contentsAll.length > 9">
          <div class="col d-flex justify-content-center">
            <app-rectangle-button (onClick)="showMore($event)" [label]="data.showMoreLabel"></app-rectangle-button>
          </div>
        </div>
      </div>
    </div>
    <div class="loading-wrapper" *ngIf="loading">
        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
          <span class="visually-hidden" [innerHTML]="'Loading' | translate"></span>
        </div>
    </div>
  </div>
</div>
