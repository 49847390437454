<div class="modal fade" #signupModal id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <span class="close-btn-modal"  data-bs-dismiss="modal" aria-label="Close"></span>
      <div class="modal-body _modal-content">
        <h4 [innerHTML]="'Registration' | translate"></h4>
        <form [formGroup]="signupForm" (ngSubmit)="handleSignup()" autocomplete="off">
          <div class="form-item">
            <label for="firstName1" [innerHTML]="'FirstName' | translate">*</label>
            <input #firstName id="firstName1" placeholder="{{'FirstNamePlaceholder' | translate}}" type="text" formControlName="firstname">
            <div *ngIf="signupForm.controls.firstname.touched || submitted">
              <div class="form-error" *ngIf="signupForm.controls.firstname.errors?.required" [innerHTML]="'FirstNameErrorRequired' | translate"></div>
            </div>
          </div>
          <div class="form-item">
            <label for="lastName1" [innerHTML]="'LastName' | translate">*</label>
            <input id="lastName1" placeholder="{{'LastNamePlaceholder' | translate}}" type="text" formControlName="lastname">
            <div *ngIf="signupForm.controls.lastname.touched || submitted">
              <div class="form-error" *ngIf="signupForm.controls.lastname.errors?.required" [innerHTML]="'LastNameErrorRequired' | translate"></div>
            </div>
          </div>
          <div class="form-item">
            <label for="email1" [innerHTML]="'Email' | translate">*</label>
            <input #email1 id="email1" placeholder="{{'EmailPlaceholder' | translate}}" type="text" formControlName="email">
            <div *ngIf="signupForm.controls.email.touched || submitted">
              <div class="form-error" *ngIf="signupForm.controls.email.errors?.email" [innerHTML]="'EmailErrorValid' | translate"></div>
              <div class="form-error" *ngIf="signupForm.controls.email.errors?.required" [innerHTML]="'EmailErrorRequired' | translate"></div>
            </div>
            <div class="form-error" *ngIf="signupForm.controls.email?.errors?.auth">{{signupForm.controls.email?.errors?.auth}}</div>
          </div>
          <div class="form-item">
            <label for="preferredlanguageS" [innerHTML]="'PreferredLanguage' | translate"></label>
            <select
                id="preferredlanguageS"
                (change)="changePreferredLanguage($event)"
            >
              <option *ngFor="let language of languages" [value]="language.code" [selected]="language.code === selectLanguage">{{language.title}} </option>
            </select>
          </div>
          <div class="form-item">
            <label for="password1" [innerHTML]="'Password' | translate">*</label>
            <input #password1 id="password1" placeholder="{{'PasswordPlaceholder' | translate}}"  type="password" formControlName="password" autocomplete="off">
            <div *ngIf="signupForm.controls.password.touched || submitted">
              <div class="form-error" *ngIf="signupForm.controls.password?.errors?.required" [innerHTML]="'PasswordPlaceholder' | translate"></div>
              <div class="form-error" *ngIf="signupForm.controls.password?.errors?.minlength" [innerHTML]="'PasswordMinLengthError' | translate"></div>
            </div>
          </div>
          <div class="form-item">
            <label for="password2" [innerHTML]="'RepeatPassword' | translate">*</label>
            <input id="password2" placeholder="{{'ReEnterPasswordPlaceholder' | translate}}"  type="password" formControlName="password2" autocomplete="off">
            <div *ngIf="signupForm.controls.password2.touched || submitted">
              <div class="form-error" *ngIf="signupForm.controls.password2?.errors?.required" [innerHTML]="'ReEnterPasswordRequiredError' | translate"></div>
              <div class="form-error" *ngIf="signupForm.errors?.mismatch" [innerHTML]="'PasswordDontMatchError' | translate"></div>
            </div>
           </div>
          <div class="form-item">
            <div class="align-items-center">
              <input class="me-2" id="terms" type="checkbox" formControlName="agreed">
              <label class="my-0 paragraph small" for="terms" [innerHTML]="'Agree1' | translate"> </label><span>&nbsp;&nbsp;</span><a href="https://bixcom.at/imprint" target="_blank" class="paragraph small" [innerHTML]="'Agree2' | translate"></a>
            </div>
            <div *ngIf="signupForm.controls.agreed.touched || submitted">
              <div class="form-error" *ngIf="signupForm.controls.agreed.errors?.required" [innerHTML]="'Conditions' | translate"></div>
            </div>
          </div>
          <div class="form-item">
            <div class="align-items-center">
              <input class="me-2" id="pp" type="checkbox" formControlName="pp">
              <label class="my-0 paragraph small" for="pp">*{{'InfoRegister' | translate}}
              <span> </span>{{'PleaseClick' | translate}} <a href="https://bixcom.at/privacy-policy" target="_blank" class="paragraph small" [innerHTML]="'Here' | translate"></a> {{'PrivacyPolicy' | translate}}</label>
            </div>
            <div *ngIf="signupForm.controls.agreed.touched || submitted">
              <div class="form-error" *ngIf="signupForm.controls.pp.errors?.required" [innerHTML]="'PrivacyPolicy2' | translate"></div>
            </div>
          </div>
          <div class="button-wrapper d-flex justify-content-center">
            <button type="submit" class="w-100">
              <app-rectangle-button [loading]="submitLoading" dark [label]="'Register' | translate"></app-rectangle-button>
            </button>
          </div>
          <div class="register-info">
            <p class="text-center">{{'AlreadyAccount' | translate}} <a (click)="handleSwitch()">{{'SignIn' | translate}}</a></p>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div #successModal class="modal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content p-5 rounded-0">
      <h5 [innerHTML]="'CheckYourMail' | translate"></h5>
    </div>
  </div>
</div>
