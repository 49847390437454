<div  class="quote-slider" [style]="{'background-color': data.backgroundColor, '--text-color': data.textColor}">
  <div class="container  _container ">
    <app-carousel [ngClass]="componentClass"  dark  [slideConfig]="_slideConfig" [isArrowContainer]="true" >
      <ng-template misCarouselItem *ngFor="let item of data.slider">
        <div class="row flex-column-reverse flex-md-row">
        <div class="col-12  col-md-6">
          <div class="quote-wrapper">
            <h3>
              <q [innerHTML]="item.quote"></q>
            </h3>
          </div>
          <p class="lead name-surname">{{ item.name }}</p>
          <p>{{ item.jobTitle }}</p>
        </div>
        <div class="col-12 col-md-6 quote-slider-image-container">
          <img class="quote-slider-image" src="{{ item.image.url }}" alt="{{ item.name }}"/>
        </div>
        </div>
      </ng-template>
    </app-carousel>
  </div>
</div>
