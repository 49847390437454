import { Component, Input, OnInit } from '@angular/core';
import { Gallery } from '../../models/gallery.model';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  @Input() data: Gallery;

  constructor( private router: Router ) { }

  // tslint:disable-next-line:variable-name
  _slideConfig: any = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    adaptiveHeight: true
  };

  ngOnInit(): void {
  }

  pageLink(): string {
    return environment.angularUrl + this.router.url;
  }
}
