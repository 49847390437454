import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CarouselComponent } from 'src/app/shared/sliders/carousel/carousel.component';
import { PicturesGalleryModel } from './picture-gallery.model';

@Component({
  selector: 'app-pictures-gallery',
  templateUrl: './pictures-gallery.component.html',
  styleUrls: ['./pictures-gallery.component.scss']
})
export class PicturesGalleryComponent implements OnInit {
  @Input() data: PicturesGalleryModel;

  @ViewChild('slider') slider: CarouselComponent;

  // tslint:disable-next-line
  _slideConfig: any = {
    infinite: false,
    slidesToShow: 1.3,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  _slideConfigSmall: any = {
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    variableWidth: true,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.05,
          variableWidth: false,
          slidesToScroll: 1,
        },
      },
    ],
  };

  constructor() { }

  ngOnInit(): void { }

  handleNavigate(direction: string): void {
    if (direction === 'NEXT') {
      this.slider.next();
    }
    if (direction === 'PREV') {
      this.slider.prev();
    }
  }
}
