import { Component, Input, OnInit } from '@angular/core';
import { RECOMMENDER_RESPONSE } from '../../models/mocks/recommender-response.mock';
import { Content } from '../../models/limitless-interaction.model';
import { LimitlessInteractionService } from '../../services/limitless-interaction.service';
import { SeoUtils } from '../../utils/seo.utilis';

@Component({
  selector: 'app-recommender-section',
  templateUrl: './recommender-section.component.html',
  styleUrls: ['./recommender-section.component.scss']
})
export class RecommenderSectionComponent implements OnInit {
  @Input() data: any;
  @Input() parentId: any;
  @Input() contextCategories: number[] = [];
  @Input() primaryCategoryId: string;
  recommender = RECOMMENDER_RESPONSE;
  loading = true;
  isVideo = false;
  recData: Content[] = [];

  // tslint:disable-next-line:variable-name
  _slideConfig: any = {
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    variableWidth: true,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.05,
          variableWidth: false,
          slidesToScroll: 1,
        },
      },
    ],
  };

  constructor( private recommenderService: LimitlessInteractionService ) { }

  async ngOnInit(): Promise<void> {
    this.recData = await this.recommenderService
      .loadRecommender(this.data.recommender, this.parentId, this.contextCategories, this.primaryCategoryId);
    this.loading = false;
  }

  generatedId(link: string): string {
    return SeoUtils.generateID(link);
  }
}
