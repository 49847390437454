<div class="testimodial" [style]="{'background-color': data.backgroundColor, '--text-color': data.textColor}">
  <app-testimonial-slider dark >
    <ng-template misCarouselItem *ngFor="let item of  testimonialSection.slider">
      <div class="slide testimonial-slider container-fluid"
           style="background: url('{{ item.image.url}}') no-repeat center;">
        <div class="container-xl  h-100 p-0">
          <div class="row h-100">
            <div class="offset-md-7 offset-3 col-md-5 d-flex align-items-end">
              <div class="testimonial-content w-100">
                <h3 class=" col-9 col-md-12 quote">
                  <q>
                    <span>{{ item.quote }}</span>
                  </q>
                </h3>
                <p class="lead  name-surname">{{ item.name }}</p>
                <p class="">{{ item.jobTitle }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </app-testimonial-slider>
</div>
