<div class="grid-manual" [style]="{'background-color': data.backgroundColor, '--text-color': data.textColor}">
  <div class="container p-md-0 px-4">
    <div class="row">
      <div class="col">
          <h4 class="grid-title mobile2" >{{ data.subtitle }}</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4 grid-manual-item" *ngFor="let item of data.gridList">
        <a [href]="item.post.slug" [title]="item.post.preferences.title" target="_self" >
          <img class="grid-img" [defaultImage]="item.post.preferences.mainImage.sizes.thumbnail" [lazyLoad]="item.post.preferences.mainImage.sizes.medium_large"  alt="{{ item.post.preferences.mainImage.alt }}">
        </a>
        <div>
          <div>
            <h6 class="medium-p tag-item d-inline-block social-title" style="border-color:{{ data.textColor + '80' }}"  [innerHTML]="item.post.preferences.tag"></h6>
            <div class="share-wrapper d-inline-block">
              <app-share [white]="false" [linkToPage]="item.post.slug" ></app-share>
            </div>
          </div>
            <a [href]="item.post.slug" [title]="item.post.preferences.title" target="_self" >
              <h5>{{ item.post.preferences.title }}</h5>
            </a>
        </div>
      </div>
    </div>
  </div>
</div>
