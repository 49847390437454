import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-video-play',
  templateUrl: './video-play.component.html',
  styleUrls: ['./video-play.component.scss']
})
export class VideoPlayComponent implements OnInit {
  @Input() isVideo: boolean;

  constructor() { }

  ngOnInit(): void { }
}
