<div class="main-wrapper" [ngClass]="position">
  <div class="carousel-wrapper ipad-padding">
    <ngx-slick-carousel
      class="carousel"
      #slickModal="slick-carousel"
      [config]="slideConfig"
      (breakpoint)="onBreakpoint($event)"
      (beforeChange)="beforeChange.emit($event)"
      (afterChange)="onAfterChange($event)"
      (init)="slickInit($event)"
    >
      <div ngxSlickItem class="slide" *ngFor="let item of templates">
        <ng-container *ngTemplateOutlet="item.template"></ng-container>
      </div>
    </ngx-slick-carousel>
  </div>
  <div class="button-wrappe position-absolute position-md-static {{  isArrowContainer ? ' p-0': ''}}" [ngClass]="{ 'left-on-mobile': position === sliderPositionArrows.right } " *ngIf="!(first && last)">
    <app-arrow-previous [attr.disabled]="first === true ? '' : null" [attr.dark]="dark === '' ? '' : null" (click)="prev()">
    </app-arrow-previous>
    <app-arrow-next [attr.disabled]="last === true ? '' : null" [attr.dark]="dark === '' ? '' : null" (click)="next()"> </app-arrow-next>
  </div>
  <!--<p class="small slide-counter d-block d-md-none" *ngIf="currentPositionSlide">
    <strong>{{currentPositionSlide}}</strong>/{{allSlides}}
  </p>-->
</div>
