import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { CookieService } from 'ngx-cookie';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  data: any;
  currentLang: string;
  private selectedLanguage = '';
  private countryCode = '';

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private loaction: PlatformLocation,
    private cookieService: CookieService,
  ) { }

  init(): any {
    const params = (new URL(this.loaction.href)).searchParams;
    let lang = '';
    const langParam = params.get('lang');
    if (!langParam) {
      lang = 'en';
    }
    this.selectedLanguage = this.cookieService.get('selected_lang');
    if (!this.selectedLanguage) {
      this.countryCode = this.cookieService.get('country_code');
      if (!this.countryCode) {
        lang = 'en';
      } else {
        if (!this.countryCode) {
          lang = 'en';
        } else {
          lang = this.setLanguageToCountryCode(this.countryCode);
        }
      }
    } else {
      lang = this.selectedLanguage;
    }
    if (!langParam) {
      this.setQueryParams(lang);
    }
    this.currentLang = lang || 'en';
    return this.http.get(environment.wpJson + 'translations' + '?lang=' + lang || 'en').pipe(map((translations: any) => {
      this.data = translations;
      return translations;
    }));
  }
  private getQueryParameter(key: string): string|null {
    if (typeof window === 'undefined') {
      return null;
    }
    const parameters = new URLSearchParams(window.location.search);
    return parameters.get(key);
  }
  setQueryParams(lang: string): void {
    if (typeof window === 'undefined') {
      return;
    }
    const url = new URL(window.location.href);
    url.searchParams.set('lang', lang);
    // @ts-ignore
    window.history.replaceState(null, '', url);
  }

   setLanguageToCountryCode(code: string): string {
    let lang = 'en';
    switch (code) {
      case 'AT':
      case 'at':
      case 'DE':
      case 'de':
      case 'CH':
      case 'ch':
        lang = 'de';
        break;
      default:
        break;
    }
    this.cookieService.put('selected_lang', lang);
    return lang;
  }

  getSelectedLanguageCookieValue(): string | undefined {
    return this.cookieService.get('selected_lang');
  }
}

// tslint:disable-next-line:typedef
export function translationsFactory(provider: TranslationService) {
  return () => provider.init().toPromise();
}
