<div class="recommender position-relative" [style]="{'background-color': data.backgroundColor, '--text-color': data.textColor}">
  <div class="container-fluid p-md-0 pe-0">
    <div class="container-fluid p-lg-0 pe-0 ps-2">
      <div class="d-flex justify-content-end">
        <div class="col-12 f-content title-row">
          <h4 class="mobile2" [innerHTML]="data.title"></h4>
        </div>
      </div>
    </div>

    <div class="container-fluid p-lg-0 pe-0 ps-2">
      <div class="d-flex justify-content-end">
        <div class="col-12 f-content">
          <app-carousel *ngIf="recData.length"  dark [slideConfig]="_slideConfig">
            <ng-template misCarouselItem *ngFor="let item of recData; let i = index">
              <a [routerLink]=" '../' + item.url" [title]="item.title" class="position-relative" id="{{ generatedId(item.url) }}">
                <app-video-play [isVideo]="item.hasVideo"></app-video-play>
                <div class="slide recommender-slider"
                     [defaultImage]="item.portraitImageThumbnail? item.portraitImageThumbnail : item.thumbnail"
                     [lazyLoad]="item.portraitImageUrl? item.portraitImageUrl : item.mediumImg" >
                </div>
              </a>
              <p class="small slide-counter d-block d-md-none">
                <strong>{{i+1}}</strong>/{{recData.length}}
              </p>
              <div class="under-container h-100">
                <div class="d-flex">
                  <h6 class="medium-p tag-item d-inline-block social-title" style="border-color:{{ data.textColor + '80' }}"  [innerHTML]="item.tag"></h6>
                  <div class="share-wrapper d-inline-block">
                    <app-share [white]="false" [linkToPage]="item.url" ></app-share>
                  </div>
                </div>
                <div class="d-flex align-items-end h-100">
                  <div>
                    <a [routerLink]=" '../' + item.url" [title]="item.title" >
                      <h5 class="item-title mobile2-leed">{{ item.title}}</h5>
                    </a>
                  </div>
                </div>
              </div>
            </ng-template>
          </app-carousel>
          <div *ngIf="loading">
            <h2></h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
