import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { SubscriptionPath } from '../../../../models/limitless-interaction.model';
import { LimitlessInteractionService } from '../../../../services/limitless-interaction.service';

@Component({
  selector: 'app-subscribe-btn',
  templateUrl: './subscribe-btn.component.html',
  styleUrls: ['./subscribe-btn.component.scss']
})
export class SubscribeBtnComponent  {
  @Input() isChannel!: boolean;
  @Input() isSubscribe!: boolean;
  @Input() isOpen!: boolean;
  @Input() isUserLoggedIn$!: Observable<boolean>;
  @Input() isNotificationAll!: boolean;
  @Input() channelId!: number | string;
  @Output('parentSubscribe') parentSubscribe = new EventEmitter<any>();
  @Output('parentOnSelect') parentOnSelect = new EventEmitter<any>();
  @Output('parentOpenNotification') parentOpenNotification = new EventEmitter<any>();

  constructor() { }

  subscribe(): void {
    this.parentSubscribe.emit();
  }

  onSelect(value: string): void {
    this.parentOnSelect.emit(value);
  }

   openNotification(): void {
    this.parentOpenNotification.emit();
   }
}
