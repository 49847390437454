import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {HotSpotItem} from '../../../models/hotspot-2d.modal';
import {isPlatformBrowser} from '@angular/common';


@Component({
  selector: 'app-marker',
  templateUrl: './marker.component.html',
  styleUrls: ['./marker.component.scss']
})
export class MarkerComponent implements OnInit {
  @Input() item: HotSpotItem;
  @Input() i: number;
  active = false;
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private eRef: ElementRef
  ) { }

  ngOnInit(): void {
  }

  @HostListener('window:click', ['$event'])
  clickOut(event: any): any {
    this.active = this.eRef.nativeElement.contains(event.target);
  }

  get detectMob(): boolean {
    return isPlatformBrowser(this.platformId) && window.innerWidth <= 768;
  }
}
