import { Component, OnInit } from '@angular/core';
import { HomepageService } from '../../services/homepage.service';
import { ContactData } from '../../models/contact.model';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  data: ContactData;
  constructor( private homePageService: HomepageService, ) { }

  ngOnInit(): void {
    this.homePageService.$data.subscribe(({data}) => {
      this.data = {...data.preferences, ...this.data};
    });
  }
}
