<div class="image-quote-text" [style]="{'background-color': data.backgroundColor, '--text-color': data.textColor}" >
  <div class="container  p-md-0">
    <div class="row px-2 p-md-0" *ngIf="data.mainImage?.url" >
      <div class="col-12 col-md-9" [class.onlyImage]="!data.quote.content && !data.quote.source && !data.content">
        <div class="image-wrapper">
          <img class="image"  [defaultImage]="data.mainImage.sizes.thumbnail"  [lazyLoad]="data.mainImage.url" [alt]="data.mainImage?.alt" >
        </div>
      </div>
    </div>
    <div class="row px-2 p-md-0" *ngIf="data.quote.content || data.quote.source || data.content">
      <div class="col-12 col-md-4 quote" *ngIf="data.quote.content || data.quote.source" >
        <h3 *ngIf="data.quote.content" >{{ data.quote.content }}</h3>
        <p *ngIf="data.quote.source?.length" class="small w-100 text-uppercase">&#8722; {{ data.quote.source}}</p>
      </div>
      <div class="col-12 offset-md-1 col-md-4" *ngIf="data.content">
        <div [innerHTML]="data.content"></div>
      </div>
    </div>
  </div>
</div>
