<div class="river container cards py-5">
  <div class="row cards-intro">
    <div class="col-12 col-lg-8">
      <h2>{{data.title}}</h2>
      <p class="pt-4">{{data.ccDescription}}</p>
    </div>
  </div>
  <div class="row cards-item mt-5">
    <div class="card col-12 col-md-4 pb-5 border-0 align-items-stretch" *ngFor="let cards of data.ccCardItem | slice: start: end; let i = index">
      <div class="image d-flex align-items-center">
        <img *ngIf="data.ccCardItem[i].logo" class="card-img-top" [lazyLoad]="data.ccCardItem[i].logo.url" [defaultImage]="data.ccCardItem[i].logo.sizes.thumbnail"  [alt]="data.ccCardItem[i].logo.alt?data.ccCardItem[i].logo.alt:'Card image'">
      </div>
      <div class="card-body p-0 pt-3 d-flex flex-column">
        <h3 class="card-title pb-2">{{data.ccCardItem[i].title}}</h3>
        <h6 class="card-text" [innerHTML]="data.ccCardItem[i].description"></h6>
        <div class="mt-auto">
          <a [href]="data.ccCardItem[i].link.url" class="btn btn-primary  btn-find-more">{{data.ccCardItem[i].link.title}}</a>
        </div>
      </div>
    </div>
    <ng-container *ngIf="isMobileResolution">
      <div  class="row" *ngIf="end < total" >
        <div class="col d-flex justify-content-center">
          <button class="btn-card btn-only-fill btn btn-primary" (click)="handleShowMore()">
            <span [innerHTML]="'LoadAll' | translate"></span>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
