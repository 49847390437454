
  <div class="container _container">
    <div *ngIf="data.headline.title || data.headline.subtitle">
      <div class="row">
        <div class="col-12">
          <h2 class="headline-title" [innerHTML]="data.headline.title"></h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-8">
          <h4 class="headline-subtitle" [innerHTML]="data.headline.subtitle"></h4>
        </div>
      </div>
    </div>
    <h3 class="grid-super-title" [innerHTML]="data.supTitle"></h3>
    <h5 class="d-none grid-title d-sm-block" [style]="{'margin-top': data.supTitle  ? '26px' : '50px'}" [innerHTML]="data.title"></h5>
    <div class="accordion d-none d-lg-block row _accordion" [id]="'accordionExampleModule' + accordionId">
      <ng-container *ngFor="let item5 of data.tiles; let i = index">
        <ng-container *ngIf="i%4 === 0">
          <div>
            <div class="row">
              <div class="col-12 col-sm-6 col-lg-3 mb-4" *ngFor="let item of data.tiles | slice: i:i+4;" [style]="{'--tile-color': item.color}">
                <div class="accordion-header" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse-'  + formatNameToClass(item.tileTitle) + '-' + i" aria-expanded="false" [attr.aria-controls]="'collapse-'  + formatNameToClass(item.tileTitle) + '-' + i"
                     [id]="'heading-' + formatNameToClass(item.tileTitle) + '-' + i">
                  <div class="tile-wrapper">
                    <div class="tile-inner px-4">
                      <h4 class="text-center tile-tittle" [innerHTML]="item.tileTitle"></h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="tile-content-wrapper  " *ngFor="let item2 of data.tiles | slice: i:i+4;">
                <div [id]="'collapse-'  + formatNameToClass(item2.tileTitle) + '-' + i" class="accordion-collapse collapse" [attr.data-bs-parent]="'#accordionExampleModule' + accordionId" [attr.aria-labelledby]="'heading-' + formatNameToClass(item2.tileTitle) + '-' + i">
                  <div class="accordion-body _accordion-body mb-4">
                    <h4>{{item2.tileTitle}}</h4>
                    <div class="wyd-content" [innerHTML]="transformYourHtml(item2.content)"></div>
                    <div class="container px-0 mt-2">
                      <div class="row">
                        <div class="col-3" *ngFor="let subItem of item2.subModuleList">
                          <div class="sub-module-item-wrapper">
                            <div class="sub-module-item">
                              <h5><u><span [innerHTML]="subItem.subModuleTitle"></span></u></h5>
                              <p *ngIf="subItem.subModuleDescription"  [innerHTML]="subItem.subModuleDescription"></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-header" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse-'  + formatNameToClass(item2.tileTitle) + '-' + i" aria-expanded="false" [attr.aria-controls]="'collapse-'  + formatNameToClass(item2.tileTitle) + '-' + i"
                         [id]="'heading-' + item2.tileTitle + '-' + i">
                    <svg class="x-mark" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/></svg>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="accordion d-none d-sm-block d-lg-none row _accordion" [id]="'accordionExampleModuleSmall' + accordionId">
      <ng-container *ngFor="let item5 of data.tiles; let i = index">
        <ng-container *ngIf="i%2 === 0">
          <div>
            <div class="row">
              <div class="col-12 col-sm-6 col-lg-3 mb-4" *ngFor="let item of data.tiles | slice: i:i+2;" [style]="{'--tile-color': item.color}">
                <div class="accordion-header" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse-'  + formatNameToClass(item.tileTitle) + '-' + i" aria-expanded="false" [attr.aria-controls]="'collapse-'  + formatNameToClass(item.tileTitle) + '-' + i"
                     [id]="'heading-' + formatNameToClass(item.tileTitle) + '-' + i">
                  <div class="tile-wrapper">
                    <div class="tile-inner px-4">
                      <h4 class="text-center tile-tittle" [innerHTML]="item.tileTitle"></h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="tile-content-wrapper " *ngFor="let item2 of data.tiles | slice: i:i+2;">
                <div [id]="'collapse-'  + formatNameToClass(item2.tileTitle) + '-' + i" class="accordion-collapse collapse" [attr.data-bs-parent]="'#accordionExampleModuleSmall' + accordionId" [attr.aria-labelledby]="'heading-' + formatNameToClass(item2.tileTitle) + '-' + i">
                  <div class="accordion-body _accordion-body mb-4">
                    <h4>{{item2.tileTitle}}</h4>
                    <div class="wyd-content" [innerHTML]="transformYourHtml(item2.content)"></div>
                    <div class="container px-0 mt-2">
                      <div class="row">
                        <div class="col-6" *ngFor="let subItem of item2.subModuleList">
                          <div class="sub-module-item-wrapper">
                            <div class="sub-module-item">
                              <h5><u><span [innerHTML]="subItem.subModuleTitle"></span></u></h5>
                              <p *ngIf="subItem.subModuleDescription" [innerHTML]="subItem.subModuleDescription"></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-header" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse-'  + formatNameToClass(item2.tileTitle) + '-' + i" aria-expanded="false" [attr.aria-controls]="'collapse-'  + formatNameToClass(item2.tileTitle) + '-' + i"
                         [id]="'heading-' + formatNameToClass(item2.tileTitle) + '-' + i">
                      <svg class="x-mark" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/></svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="d-sm-none" [id]="'accordionExampleMobileWrapper' + accordionId">
      <div class="accordion-header mobile-accordion-head d-flex justify-content-between" [id]="'headingTitle' + accordionId" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseTitle' + accordionId" aria-expanded="false" [attr.aria-controls]="'collapseTitle' + accordionId">
        <h5 [innerHTML]="data.title"></h5>
        <span  class=" arrow "><svg class=" arrow" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M23.245 4l-11.245 14.374-11.219-14.374-.781.619 12 15.381 12-15.391-.755-.609z"/></svg></span>
      </div>
      <div [id]="'collapseTitle' + accordionId" class="accordion-collapse collapse mt-5" [attr.aria-labelledby]="'headingTitle' + accordionId" [attr.data-bs-parent]="'#accordionExampleMobileWrapper' + accordionId">
        <div class="accordion w-100 mx-0 d-sm-none  row" [id]="'accordionExampleModuleMobile' + accordionId">
          <div class="col-12 mb-3 _col" *ngFor="let item5 of data.tiles; let i = index" [style]="{'--tile-color': item5.color}">
            <div class="accordion-header" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse-'  + formatNameToClass(item5.tileTitle) + '-' + i" aria-expanded="false" [attr.aria-controls]="'collapse-'  + formatNameToClass(item5.tileTitle) + '-' + i"
                 [id]="'heading-' + formatNameToClass(item5.tileTitle) + '-' + i">
              <div class="tile-wrapper">
                <div class="tile-inner px-4">
                  <h4 class="text-center tile-tittle" [innerHTML]="item5.tileTitle"></h4>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="tile-content-wrapper ">
                <div [id]="'collapse-'  + formatNameToClass(item5.tileTitle) + '-' + i" class="accordion-collapse collapse" [attr.data-bs-parent]="'#accordionExampleModuleMobile' + accordionId" [attr.aria-labelledby]="'heading-' + formatNameToClass(item5.tileTitle) + '-' + i">
                  <div class="accordion-body _accordion-body mb-4">
                    <h4>{{item5.tileTitle}}</h4>
                    <div class="wyd-content" [innerHTML]="transformYourHtml(item5.content)"></div>
                    <div class="container px-0 mt-2">
                      <div class="row">
                        <div class="col-12" *ngFor="let subItem of item5.subModuleList">
                          <div class="sub-module-item-wrapper">
                            <div class="sub-module-item">
                              <h5><u><span [innerHTML]="subItem.subModuleTitle"></span></u></h5>
                              <p *ngIf="subItem.subModuleDescription"  [innerHTML]="subItem.subModuleDescription"></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-header" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse-'  + formatNameToClass(item5.tileTitle) + '-' + i" aria-expanded="false" [attr.aria-controls]="'collapse-'  + formatNameToClass(item5.tileTitle) + '-' + i"
                         [id]="'heading-' + item5.tileTitle + '-' + i">
                      <svg class="x-mark" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/></svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
