import {AfterViewInit, Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
// @ts-ignore
import { Viewer as PhotoSphereViewer } from 'photo-sphere-viewer';
// @ts-ignore
import MarkersPlugin from 'photo-sphere-viewer/dist/plugins/markers';
// @ts-ignore
import VisibleRangePlugin from 'photo-sphere-viewer/dist/plugins/visible-range';
import {PolygonMarker, PolygonModel} from '../../../models/polygon.model';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-polygon',
  templateUrl: './polygon.component.html',
  styleUrls: ['./polygon.component.scss']
})
export class PolygonComponent implements OnInit, AfterViewInit {
  @ViewChild('element') elementRef: ElementRef;
  viewer: any;
  data: PolygonModel;

  constructor( @Inject(PLATFORM_ID) private platformId: object ) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    if (!isPlatformBrowser(this.platformId)) {return; }

    this.viewer = new PhotoSphereViewer({
      panorama: this.isMobile ? this.data.panoramaImageMobile.url : this.data.panoramaImage.url,
      container: this.elementRef.nativeElement,
      loadingImg: '/assets/img/photosphere-logo.gif',
      mousewheelCtrlKey: false,
      touchmoveTwoFingers: false,
      mousewheel: false,
      defaultLat: 0,
      defaultZoomLvl: this.isMobile ? 0 : 20,
      navbar: false,
      plugins: [
        [MarkersPlugin, {
          markers: this.formatMarkers(this.data.markers),
        }],
        [VisibleRangePlugin, {
          longitudeRange: [ 2 *  Math.PI],
          latitudeRange : ['1deg', '1deg'],
        }],
      ]
    });
    const markersPlugin  = this.viewer.getPlugin(MarkersPlugin);

    markersPlugin.on('over-marker', (e: any, marker: any) => {
      marker.$el.setAttribute('style', 'cursor: pointer !important');
    });
    markersPlugin.on('select-marker', (e: any, marker: any) => {
      window.open(marker.config.url, '_self');
    });
  }

  get isMobile(): boolean {
    return isPlatformBrowser(this.platformId) && window.innerWidth <= 768;
  }

  formatMarkers(markers: PolygonMarker[]): any {
   return markers.map(item => ({
     id: item.id,
     url: item.url,
     polygonPx:  this.isMobile ?
       item.polygonPxSmallDevices.map(x => x.px).map(y => y.split(',').map(j => +j)) :
       item.polygonPx.map(x => x.px).map(y => y.split(',').map(j => +j)),
     svgStyle: {
       fill: 'rgba(0, 0, 0, 0)',
       stroke: 'rgba(0, 0, 0, 0)',
       strokeWidth: '2px'
     }
   }));
  }

  roomRotate(side: string): void {
    const val = side === 'r' ? 0.30 : -0.30;
    let roomLongitude = this.viewer.getPosition().longitude;
    roomLongitude += val;
    this.viewer.animate({ longitude: roomLongitude, latitude: 0, speed: 3000 });
  }
}
