import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { GeneralSettingsService } from '../../services/general-settings.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TranslationService } from '../../services/translation.service';
import { Language } from '../../models/Page.model';
import { GlobalService } from '../../services/global.service';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'app-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss']
})
export class LanguageSwitchComponent implements OnInit {

  showOptions = false;
  languages: Language[] = [];
  selectedLang: Language | undefined;
  defaultLang = { active: '1', code: 'en', icon: './assets/svg/gb.svg', title: '"English"'};

  constructor(
    private eRef: ElementRef,
    private generalSettingsService: GeneralSettingsService,
    private ts: TranslationService,
    private globalService: GlobalService,
    private cookieService: CookieService,
  ) {
    this.languages = this.ts.data.languages;
    if ( this.globalService.getIsDebuggingEnabled() ) {
      console.group('Language');
      console.dir( this.languages);
      console.groupEnd();
    }
    this.languages.forEach( x => {
      switch (x.code) {
        case 'en':
          x.icon = './assets/svg/gb.svg';
          break;
        case 'de':
          x.icon = './assets/svg/ger.svg';
          break;
        default:
          break;
      }
    });
    const selectetLangCookie = this.ts.getSelectedLanguageCookieValue();
    if (selectetLangCookie) {
      this.selectedLang = this.languages.find( (obj: Language) => {
        // @ts-ignore
        return obj.code === selectetLangCookie;
      });
    } else {
      this.selectedLang = this.languages.find( (obj: Language) => {
        // @ts-ignore
        return obj.active === '1';
      });
    }
  }

  $data = this.generalSettingsService.$data.pipe(
    map(item => item.data.languages),
  ) as Observable<Language>;

  ngOnInit(): void {}

  handleLang(lang: string): void {
    this.cookieService.put('selected_lang', lang);
    this.ts.setQueryParams(lang);
    window.location.reload();
  }

  @HostListener('document:click', ['$event'])
  clickout(event: MouseEvent): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showOptions = false;
    }
  }

}
