import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  url = environment.wpJson + 'mail';

  constructor(  private http: HttpClient) { }

  async sendMail(data: any): Promise<any> {
    return this.http.post(this.url, data).toPromise();
  }

}
