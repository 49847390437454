import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-link-to',
  templateUrl: './link-to.component.html',
  styleUrls: ['./link-to.component.scss']
})
export class LinkToComponent implements OnInit {
  @Input() title: any;
  @Input() link: any;
  @Input() color: string;
  ctaId: string;

  constructor() { }

  ngOnInit(): void {
    this.ctaId = 'CTA-' + this.link ;
  }

  showTitle(): string {
    let newTitle = '';
    if ( this.title.length < 1 ) {
      newTitle = 'All';
    } else {
      newTitle = this.title;
    }
    return newTitle;
  }
}
