export const PREVIEW_RECOMMENDER: any = {
  linkToPage: {
    link: 'www.avl.com',
    title: 'All Redefining Marketing',
  },
  slider: [
    {
      tag: 'Brand Story',
      title: 'Meet our Leadership Team',
      description: 'We are at the forefront of developing and implementing new digital marketing tools, which have already proven effective in hundreds of client projects around the world.',
      image: {
        url: '/assets/img/mocks/testImg1.png'
      },
      link: 'www.orf.at',
    },
    {
      tag: 'Our Leadership',
      title: '2 Meet our Leadership Team',
      description: 'We are at the forefront of developing and implementing new digital marketing tools, which have already proven effective in hundreds of client projects around the world.',
      image: {
        url: '/assets/img/mocks/testImg2.png'
      },
      link: 'www.rtl.de'
    },
    {
      tag: 'Awards',
      title: '3 Meet our Leadership Team',
      description: 'We are at the forefront of developing and implementing new digital marketing tools, which have already proven effective in hundreds of client projects around the world.',
      image: {
        url: '/assets/img/mocks/testImg3.png'
      },
      link: 'www.orf.at'
    },
    {
      tag: 'Brand Story',
      title: 'Meet our Leadership Team',
      description: 'We are at the forefront of developing and implementing new digital marketing tools, which have already proven effective in hundreds of client projects around the world.',
      image: {
        url: '/assets/img/mocks/testImg1.png'
      },
      link: 'www.orf.at'
    },
    {
      tag: ' Our Leadership',
      title: 'Meet our Leadership Team',
      description: 'We are at the forefront of developing and implementing new digital marketing tools, which have already proven effective in hundreds of client projects around the world.',
      image: {
        url: '/assets/img/mocks/testImg2.png'
      },
      link: 'www.orf.at'
    },
  ]
};
