<div class="position-relative">
  <div class="position-absolute arrow left"  (click)="roomRotate('l')">
    <img src="./assets/svg/arrows/arrow_left_black.svg" alt="">
  </div>
  <div class="position-absolute arrow right" (click)="roomRotate('r')">
    <img src="./assets/svg/arrows/arrow_right_black.svg" alt="">
  </div>

  <app-scroll-down *ngIf="data.scrollDown"></app-scroll-down>

  <div style="height: 100vh" #element>

  </div>

</div>
