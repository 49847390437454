<div class="gallery"  [style]="{'background-color': data.backgroundColor, '--text-color': data.textColor}" >
  <div class="container-fluid container-md p-md-0">
    <div class="container p-0">
      <div>
        <h6 class="tag-item d-inline-block social-title" style="border-color: {{ data.textColor + '80' }}"  [innerHTML]="data.tag"></h6>
        <div class="share-wrapper d-inline-block">
          <app-share [white]="false" [linkToPage]=" pageLink() " ></app-share>
        </div>
      </div>
      <h2 class="col col-md-7 title-wrapper" [innerHTML]="data.subtitle "></h2>
    </div>
    <div class="row p-0">
      <div class="main-wrapper d-none d-md-block">
        <div class=" gal-wrapper">
          <div class="carousel-wrapper">
            <div class="gallery-carousel">
              <div class="slide item-slide-{{ i }}" *ngFor="let item of data.images; index as i" >
                <img class="img-item" [lazyLoad]=" item.url" [defaultImage]="item.sizes.thumbnail" alt="{{ item.alt }}" >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-md-none mobile">
        <app-carousel class="__carousel" dark [slideConfig]="_slideConfig">
          <ng-template misCarouselItem *ngFor="let item of data.images; let i = index">
            <img [src]="item.sizes.medium_large" alt="">
            <p class="small slide-counter d-block d-md-none">
              <strong>{{i+1}}</strong>/{{data.images?.length}}
            </p>
            </ng-template>
          </app-carousel>
      </div>
    </div>
  </div>
</div>
