import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[misCarouselItem]'
})
export class CarouselItemDirective {
  @Input('misCarouselItem') nothing: any;

  constructor(public template: TemplateRef<any>) { }

}
