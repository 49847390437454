<div class="footer-wrapper" *ngIf="(data$ | async) as data">
  <div class="footer-content">
    <div class="get-in-touch-row">
      <div class="get-in-touch-wrapper">
        <h2>{{data.title}}</h2>
        <div class="footer-email-phone-wrapper">
          <p>
            <a class="medium-p" href="mailto:{{ data.email }}">{{ data.email }}</a>
          </p>
          <p>
            <a class="medium-p" href="tel:{{ data.phone }}">{{ data.phone }} </a>
          </p>
        </div>
      </div>
      <div class="social-media-wrapper">
        <span class="small-p">
           {{data.socialMediaGroup.title}}
        </span>
        <div class="social-icons-wrapper">
          <a *ngFor="let social of data.socialMediaGroup.list" [href]="social.link" target="_blank" rel="nofollow">
            <img [src]="social.image.url" [alt]="social.name" loading="lazy">
          </a>
        </div>
      </div>
    </div>
    <div class="contact-us-row">
      <div>
        <app-underline-button [color]="'#fff'" [link]="'../' + data.contactUsSection.link.url" [text]="data.contactUsSection.link.title"></app-underline-button>
      </div>
      <div class="location-wrapper">
        <div class="location-item" *ngFor="let item of data.contactUsSection.list">
          <a href="{{ item.mapsLink}}"
             class="mobile-locations d-flex align-items-stretch" *ngIf="isMobileResolution"
             rel="nofollow"
             target="_blank">
            <span class="pin">
              <img class="d-inline-block" src="assets/svg/location.svg" height="30" width="30" alt="location-pin">
            </span>
            <span class="location-title medium-p pin-text align-self-center ">{{item.city}}</span>
          </a>
          <span class="location-title medium-p" *ngIf="!isMobileResolution">{{item.city}}</span>
          <span class="small-p location-info">{{item.address}}</span>
          <span class="small-p location-info">{{item.zip}}</span>
          <span class="small-p location-info">{{item.location}}</span>
          <span class="small-p location-info">{{item.country}}</span>
        </div>
      </div>
      <div class="social-icons-wrapper-mobile d-md-none row">
        <div *ngFor="let social of data.socialMediaGroup.list" class="col-4">
          <a [href]="social.link" target="_blank" rel="nofollow noopener">
            <img [src]="social.image.url" [alt]="social.name">
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="info-wrapper">
    <div class="info-inner">
      <div class="info-items-container">
        <div class="info-item" *ngFor="let item of data.info; let i = index;">
          <div *ngIf="item.link.url.includes('home'); then thenBlock else elseBlock"></div>
          <ng-template #thenBlock>
            <a [routerLink]="'../' + item.link.url">
              <span class="small-p " [innerHTML]="item.link.title + currentYear()"></span>
            </a>
          </ng-template>
          <ng-template #elseBlock>
            <a [routerLink]="'../' + item.link.url">
              <span class="small-p " [innerHTML]="item.link.title "></span>
            </a>
          </ng-template>

          <div [class]="'_border-'+i"></div>
        </div>
      </div>
      <img [src]="data.footerLogo" alt="footer-logo">
    </div>
    <img class="footer-banner-image" [src]="data.infoBgImage"  alt="footer-info-banner">
  </div>
</div>




