<div class="px-2 px-md-0 d-flex align-items-center  button-cont position-relative" [ngClass]="isChannel?'justify-content-center':''" >
  <button *ngIf="isSubscribe" class= "s-btn primary-sns-subscribe-selected-btn subscribe" data-bs-toggle="modal" data-bs-target="#confirmationModal">{{ 'Subscribed' }}</button>
  <button *ngIf="!isSubscribe && !(isUserLoggedIn$ | async)" class= " s-btn primary-sns-subscribe-selected-btn" data-bs-toggle="modal"  data-bs-target="#loginModal" >{{ 'Subscribe' }}</button>
  <button *ngIf="!isSubscribe && (isUserLoggedIn$ | async)" class= "s-btn primary-sns-subscribe-selected-btn" (click)="subscribe()" >{{ 'Subscribe' }}</button>
  <div class="dropdown-btn position-relative">
    <span class="bell-btn" #toggleButton  (click)="openNotification()" [ngClass]="isSubscribe? 'active' : ''">
      <svg *ngIf="isNotificationAll" width="14px" height="18px" viewBox="0 0 14 18" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 17.0625C7.96252 17.0625 8.75 16.275 8.75 15.3125L5.25 15.3125C5.25 16.275 6.03748 17.0625 7 17.0625L7 17.0625L7 17.0625ZM12.25 11.8125L12.25 7.4375C12.25 4.75125 10.8237 2.5025 8.3125 1.9075L8.3125 1.3125C8.3125 0.586243 7.72626 0 7 0C6.27374 0 5.6875 0.586243 5.6875 1.3125L5.6875 1.9075C3.185 2.5025 1.75 4.74249 1.75 7.4375L1.75 11.8125L0 13.5625L0 14.4375L14 14.4375L14 13.5625L12.25 11.8125L12.25 11.8125L12.25 11.8125ZM10.5 12.6875L3.5 12.6875L3.5 7.4375C3.5 5.26749 4.82123 3.5 7 3.5C9.17877 3.5 10.5 5.26749 10.5 7.4375L10.5 12.6875L10.5 12.6875L10.5 12.6875Z" id="Shape" fill="#000000" fill-rule="evenodd" stroke="none" />
      </svg>
      <svg *ngIf="!isNotificationAll" width="14px" height="16px" viewBox="0 0 14 16" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <path d="M0 0L14 0L14 15.5556L0 15.5556L0 0Z" id="path_1" />
          <clipPath id="mask_1">
            <use xlink:href="#path_1" />
          </clipPath>
        </defs>
        <g id="1">
          <path d="M0 0L14 0L14 15.5556L0 15.5556L0 0Z" id="Background" fill="none" fill-rule="evenodd" stroke="none" />
          <g clip-path="url(#mask_1)">
            <path d="M6.22222 15.1667C7.07778 15.1667 7.77778 14.4667 7.77778 13.6111L4.66667 13.6111C4.66667 14.4667 5.36667 15.1667 6.22222 15.1667L6.22222 15.1667ZM6.22222 3.11111C8.15889 3.11111 9.33333 4.68222 9.33333 6.61111L9.33333 6.68889L10.8889 8.24444L10.8889 6.61111C10.8889 4.22333 9.62111 2.22444 7.38889 1.69556L7.38889 1.16667C7.38889 0.521111 6.86778 0 6.22222 0C5.57667 0 5.05556 0.521111 5.05556 1.16667L5.05556 1.69556C4.86889 1.74222 4.69 1.81222 4.51889 1.87444L5.79444 3.15C5.93444 3.13444 6.07444 3.11111 6.22222 3.11111L6.22222 3.11111L6.22222 3.11111ZM1.09667 0.661111L0 1.75778L2.18556 3.94333C1.78111 4.72111 1.55556 5.63111 1.55556 6.61111L1.55556 10.5L0 12.0556L0 12.8333L11.0756 12.8333L12.4289 14.1867L13.5256 13.09L1.09667 0.661111L1.09667 0.661111ZM9.33333 11.2778L3.11111 11.2778L3.11111 6.61111C3.11111 6.08222 3.20444 5.58444 3.37556 5.13333L9.33333 11.0911L9.33333 11.2778L9.33333 11.2778Z" id="Shape" fill="#000000" fill-rule="evenodd" stroke="none" />
          </g>
        </g>
      </svg>
    </span>
    <ul class="dropdown-btn-info position-absolute menu"  [ngStyle]="{'visibility': isOpen  ? 'visible' : 'hidden'}" >
      <li class="d-flex align-items-center"
          (click)="onSelect('1')"
      >
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
          <g id="notifications_black_24dp">
            <path d="M0 0L24 0L24 24L0 24L0 0Z" id="Path" fill="none" fill-rule="evenodd" stroke="none" />
            <path d="M8 19.5C9.1 19.5 10 18.6 10 17.5L6 17.5C6 18.6 6.9 19.5 8 19.5ZM14 13.5L14 8.5C14 5.43 12.37 2.86 9.5 2.18L9.5 1.5C9.5 0.67 8.83 0 8 0C7.17 0 6.5 0.67 6.5 1.5L6.5 2.18C3.64 2.86 2 5.42 2 8.5L2 13.5L0 15.5L0 16.5L16 16.5L16 15.5L14 13.5ZM12 14.5L4 14.5L4 8.5C4 6.02 5.51 4 8 4C10.49 4 12 6.02 12 8.5L12 14.5Z" transform="translate(4 2.5)" id="Shape" fill="#000000" stroke="none" />
          </g>
        </svg>
        <span class="buttonsCTA">All</span>
      </li>
      <li class="d-flex align-items-center"
          (click)="onSelect('0')"
      >
        <svg width="18px" height="20px" viewBox="0 0 18 20" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 19.5C9.1 19.5 10 18.6 10 17.5L6 17.5C6 18.6 6.9 19.5 8 19.5ZM8 4C10.49 4 12 6.02 12 8.5L12 8.6L14 10.6L14 8.5C14 5.43 12.37 2.86 9.5 2.18L9.5 1.5C9.5 0.67 8.83 0 8 0C7.17 0 6.5 0.67 6.5 1.5L6.5 2.18C6.26 2.24 6.03 2.33 5.81 2.41L7.45 4.05C7.63 4.03 7.81 4 8 4L8 4ZM1.41 0.85L0 2.26L2.81 5.07C2.29 6.07 2 7.24 2 8.5L2 13.5L0 15.5L0 16.5L14.24 16.5L15.98 18.24L17.39 16.83L1.41 0.85ZM12 14.5L4 14.5L4 8.5C4 7.82 4.12 7.18 4.34 6.6L12 14.26L12 14.5Z" id="Shape" fill="#000000" stroke="none" />
        </svg>
        <span class="buttonsCTA">None</span>
      </li>
    </ul>
  </div>
</div>
