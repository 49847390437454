import { Component, Input, OnInit } from '@angular/core';
import { Content } from '../../models/limitless-interaction.model';
import { LimitlessInteractionService } from '../../services/limitless-interaction.service';

@Component({
  selector: 'app-news-section',
  templateUrl: './news-section.component.html',
  styleUrls: ['./news-section.component.scss']
})
export class NewsSectionComponent implements OnInit {
  @Input() data: any;
  @Input() parentId: any;
  @Input() contextCategories: number[] = [];
  @Input() primaryCategoryId: string;
  recData: Content[] = [];

  // tslint:disable-next-line:variable-name
  _slideConfig: any = {
    infinite: false,
    slidesToShow: 3,
    variableWidth: true,
    slidesToScroll: 3,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.05,
          variableWidth: false,
          slidesToScroll: 1,
        },
      },
    ],
  };

  constructor( private recommenderService: LimitlessInteractionService ) { }

  async ngOnInit(): Promise<void> {
    this.recData = await this.recommenderService
      .loadRecommender(this.data.recommender, this.parentId, this.contextCategories, this.primaryCategoryId);
    }

  toData(date: string): Date {
    const newDate = new Date(date.replace(/-/g, '/'));
    return newDate;
  }
}
