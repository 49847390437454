<div *ngIf="data" class="simply-text-image-slider" [style]="{'background-color': data.sstiBackgroundColor, '--text-color': data.sstiTextColor}">
  <div class="container">
    <div *ngIf="data.sstiTitle || data.sstiSubTitle" class="d-flex justify-content-center flex-column align-items-center">
      <h2 *ngIf="data.sstiTitle" class="title" [ngClass]="data.sstiSubTitle?'':'pix'" [innerHTML]="data.sstiTitle"></h2>
      <p class="sub-title" *ngIf="data.sstiSubTitle" [innerHTML]="data.sstiSubTitle"></p>
    </div>
  </div>
  <div class="container-md container-fluid  p-0 p-md-auto">
    <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel" data-bs-interval="false" >
      <div class="carousel-inner d-flex " *ngIf="data.sstiSlider">
        <ng-container  *ngFor="let item of data.sstiSlider; let i = index; first as isFirst; last as isLast; odd as isOdd; even as isEven">
          <div #citemFull  class="carousel-item"  [ngClass]="isFirst?'active':''">
            <div #citem  class="row car-row car-row-{{i}}" [style]="'min-height:'+itemHeight+'px'">
              <div class="col col-md-6 col-left">
                <h5 class="i-sup-title" *ngIf="item.sstisSupTitle" [innerHTML]="item.sstisSupTitle"></h5>
                <h4 *ngIf="item.sstisTitle" [innerHTML]="item.sstisTitle"></h4>
                <div class="content"  *ngIf="item.sstisDescription" [innerHTML]="item.sstisDescription"></div>
              </div>
              <div class="col col-md-6 d-flex justify-content-center col-right">
                <img *ngIf="item.sstisImage.sizes.thumbnail" class="scs-image"
                     [defaultImage]="item.sstisImage.sizes.thumbnail"
                     [lazyLoad]="item.sstisImage.sizes.medium_large"
                     alt="{{ item.sstisImage.alt }}">
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">{{'Previous' | translate}}</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">{{'Next' | translate}}</span>
      </button>

    </div>
  </div>
</div>




