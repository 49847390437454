<div class="river timeline-section my-5">
  <div class="intro    container pb-3 pb-lg-5">
    <div class="row">
      <div class="col-12 text-center">
        <h1>{{'TimeLine1' | translate}}</h1>
        <h4>{{'TimeLine2' | translate}}</h4>
      </div>
    </div>
  </div>
  <div class="filters  container pb-3 pb-lg-5">
    <div class="row">
      <div class="col-12">
        <div class="d-grid d-md-block text-center">
          <button type="button" class="btn btn-secondary m-2">{{'TimeLine3' | translate}}</button>
          <button type="button" class="btn btn-secondary m-2">{{'TimeLine4' | translate}}</button>
          <button type="button" class="btn btn-secondary m-2">{{'TimeLine5' | translate}}</button>
          <button type="button" class="btn btn-secondary m-2">{{'TimeLine6' | translate}}</button>
          <button type="button" class="btn btn-secondary m-2">{{'TimeLine7' | translate}}</button>
          <button type="button" class="btn btn-secondary m-2">{{'TimeLine8' | translate}}</button>
          <button type="button" class="btn btn-secondary m-2">{{'TimeLine9' | translate}}</button>
          <button type="button" class="btn btn-secondary m-2">{{'TimeLine10' | translate}}</button>
          <button type="button" class="btn btn-secondary m-2">{{'TimeLine11' | translate}}</button>
          <button type="button" class="btn btn-secondary m-2">{{'TimeLine12' | translate}}</button>
          <button type="button" class="btn btn-secondary m-2">{{'TimeLine13' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="timeline           p-3 p-lg-5" [ngStyle]="{backgroundImage:'url(https://test.bixcom.at/wordpress/wp-content/uploads/2022/06/River-BG.jpeg)'}">
    <div class="container">
      <div class="row river-line">
        <div class="col-12 text-center">
          <svg width="567px" height="2412px" viewBox="0 0 567 2412" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>River</title>
            <defs>
              <linearGradient x1="24.7092043%" y1="-2.93638077%" x2="90.834485%" y2="82.7646506%" id="linearGradient-1">
                <stop stop-color="#0C18A3" offset="0%"></stop>
                <stop stop-color="#141FE3" offset="37.848%"></stop>
                <stop stop-color="#84E8FF" offset="100%"></stop>
              </linearGradient>
            </defs>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="River™-LP---Skribbles" transform="translate(-438.000000, -3771.000000)" stroke="url(#linearGradient-1)" stroke-width="6">
                <g id="Group-10" transform="translate(-490.000000, 3440.003926)">
                  <g id="Group-6" transform="translate(0.000000, 333.095157)">
                    <path d="M412.355764,245.205051 C637.758854,234.637838 713.145654,308.545994 638.516165,466.929518 C526.571932,704.504804 608.519776,852.66487 734.020343,868.000033 C836.928801,880.919979 1060.90697,703.545776 1127.55134,838.52699 C1194.19572,973.508203 862.889122,1202.03219 936.609541,1329.7197 C1010.32996,1457.40721 1467.27136,1146.81716 1563.17918,1312.93438 C1659.087,1479.0516 1236.29074,1641.53649 1329.12142,1775.39551 C1421.9521,1909.25454 1704.91628,1558.43025 1766.15433,1821.29607 C1827.39237,2084.16189 1351.26212,2001.89427 1447.90591,2215.31099 C1512.3351,2357.5888 1676.67237,2327.15019 1940.91773,2123.99516" id="River" transform="translate(1176.636746, 1273.713644) rotate(30.000000) translate(-1176.636746, -1273.713644) "></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
      <div class="hotspots">
        <div class="row">
          <div class="item d-flex align-items-center ">
            <div class="oc" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
              <button type="button" class="btn d-flex align-items-center" >
                <svg xmlns="http://www.w3.org/2000/svg" class="closed-btn mr-2" width="51px" height="50px" viewBox="0 0 51 50" version="1.1">
                  <title>Clickable Hotspot - Default</title>
                  <g id="g-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="g-2" transform="translate(-558.000000, -4088.000000)">
                      <g id="g-3" transform="translate(-490.000000, 3440.003926)">
                        <g id="g-4" transform="translate(0.000000, 333.095157)">
                          <g id="g-5" transform="translate(1048.759068, 314.904843)">
                            <circle id="Oval" stroke="#FFFFFF" stroke-width="3" fill-opacity="0.38" fill="#FFFFFF" cx="25" cy="25" r="23.5"/>
                            <path d="M27.0491803,13.1147541 L27.0487541,23.7697541 L37.704918,23.7704918 L37.704918,27.0491803 L27.0487541,27.0487541 L27.0491803,37.704918 L23.7704918,37.704918 L23.7697541,27.0487541 L13.1147541,27.0491803 L13.1147541,23.7704918 L23.7697541,23.7697541 L23.7704918,13.1147541 L27.0491803,13.1147541 Z" id="Rectangle-Copy-7" fill="#141FE3"/>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" class="opened-btn mr-2" width="51px" height="50px" viewBox="0 0 51 50" version="1.1">
                  <title>Clickable Hotspot - Open - X</title>
                  <g id="g-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="g-2" transform="translate(-529.000000, -4465.000000)">
                      <g id="g-3" transform="translate(-490.000000, 3440.003926)">
                        <g id="g-4" transform="translate(1019.759068, 1025.000000)">
                          <circle id="Oval" stroke="#141FE3" stroke-width="3" fill="#141FE3" cx="25" cy="25" r="23.5"/>
                          <path d="M27.0491803,13.1147541 L27.0487541,23.7697541 L37.704918,23.7704918 L37.704918,27.0491803 L27.0487541,27.0487541 L27.0491803,37.704918 L23.7704918,37.704918 L23.7697541,27.0487541 L13.1147541,27.0491803 L13.1147541,23.7704918 L23.7697541,23.7697541 L23.7704918,13.1147541 L27.0491803,13.1147541 Z" id="Rectangle-Copy-7" fill="#FFFFFF" transform="translate(25.409836, 25.409836) rotate(45.000000) translate(-25.409836, -25.409836) "/>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <img class="img-fluid w-50 pl-5" src="https://test.bixcom.at/wordpress/wp-content/uploads/2022/06/Placeholder-Logo-Allianz.png" alt="">
              </button>
            </div>
            <div class="card col-12 col-lg-8 mb-3 collapse" id="collapseExample">
              <div class="row">
                <div class="col-12 col-lg-8">
                  <div class="row g-0 align-items-center">
                    <div class="col-md-6">
                      <img src="https://test.bixcom.at/wordpress/wp-content/uploads/2021/11/test.png" class="img-fluid rounded-start" alt="Image">
                    </div>
                    <div class="col-md-6">
                      <div class="card-body">
                        <h5 class="card-title">{{'TimeLine14' | translate}</h5>
                        <img class="img-fluid py-2 w-75" src="https://test.bixcom.at/wordpress/wp-content/uploads/2022/06/Placeholder-Logo-Allianz.png" alt="">
                        <h6 class="card-text">{{'TimeLine15' | translate}}</h6>
                        <button class="btn btn-primary btn-find-more" href="#" role="button">{{'TimeLine16' | translate}}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
