<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
  <defs>
    <filter id="jh2w8fkq6a">
      <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"/>
    </filter>
    <filter id="v7kl0kpqsb">
      <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0"/>
    </filter>
    <path id="xayoi6o11c" d="M0 0H24V24H0z"/>
  </defs>
  <g>
    <g>
      <g>
        <g>
          <g>
            <g  transform="translate(-1259 -2167) translate(0 1709) translate(837 220) translate(102.509 53) translate(270.491 185)">
              <g  transform="translate(49)">
                <mask id="9nuacaeyld" fill="#fff">
                  <use xlink:href="#xayoi6o11c"/>
                </mask>
                <path  d="M15.535 3.515L7.05 12l8.485 8.485 1.415-1.414L9.879 12l7.07-7.071-1.414-1.414z"  transform="rotate(-180 12 12)"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
