import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { HomepageService } from '../../../services/homepage.service';
import { LimitlessInteractionService } from '../../../services/limitless-interaction.service';
import { SubscriptionPath } from '../../../models/limitless-interaction.model';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LoginModalService } from '../../../services/login-modal.service';

@Component({
  selector: 'app-subscribe-channel',
  templateUrl: './subscribe-channel.component.html',
  styleUrls: ['./subscribe-channel.component.scss'],
})
export class SubscribeChannelComponent implements OnInit {
  @Input() isChannel: boolean;
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('unsubscribeClose') unsubscribeClose: ElementRef;
  private user: any;
  public preferences: any;
  public isSubscribe = false;
  public isOpen = false;
  public isNotificationAll = true;
  public title: string;
  public data: any;
  public description: string;
  private channelId: number;
  public lazyLoadImg: string;
  public lazyLoadImg2: string;
  public defaultImg: string;
  public altImg: string;
  public url: string;
  public isLoadData = false;
  public mainImage: string;
  public isLoad = false;
  constructor(
    private homePageService: HomepageService,
    private subscribeService: LimitlessInteractionService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private loginModalService: LoginModalService,
    private cdr: ChangeDetectorRef
    ) { }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.data.routePrefix === '') {
      this.channelId =  this.homePageService.$data.value.data.preferences.channel;
      const options: SubscriptionPath = {
        channels: this.channelId + ''
      };
      this.subscribeService.getChannel(options).pipe(
        filter( (data) => {
          return !!data;
        })
      ).subscribe(data => {
        if (data?.status === 200) {
          this.data = data;
          this.title = data.contents[0].content[0].title;
          this.description = data.contents[0].content[0].description;
          this.lazyLoadImg = data.contents[0].metadata.roundImageSmall;
          this.lazyLoadImg2 = data.contents[0].metadata.roundImageRetinaSmall;
          this.defaultImg = data.contents[0].metadata.roundImageSmall;
          this.altImg = data.contents[0].metadata.contentType;
          this.url = '../' + data.contents[0].url;
          this.isLoad = true;
          this.cdr.detectChanges();
        } else {
          console.warn('No content data!');
        }
      });
    } else {
      this.channelId = this.activatedRoute.snapshot.data.preferences.data.id;
      this.title = this.homePageService.$data.value.data.preferences.title;
      this.description = this.homePageService.$data.value.data.preferences.description;
      this.lazyLoadImg = this.homePageService.$data.value.data.preferences.roundImage.sizes.round_big;
      this.lazyLoadImg2 = this.homePageService.$data.value.data.preferences.roundImage.sizes.round_retina;
      this.defaultImg = this.homePageService.$data.value.data.preferences.roundImage.sizes.round_small;
      this.altImg = this.homePageService.$data.value.data.preferences.roundImage.filename;
      this.mainImage = this.homePageService.$data.value.data.preferences.mainImage.url;
      this.cdr.detectChanges();
    }
    this.isUserLoggedIn$.subscribe( (user) => {
      if (user) {
        this.user = user;
        this.hasUserSubscribe();
      } else {
        this.isLoadData = true;
      }
    });
  }

  get isUserLoggedIn$(): Observable<boolean> {
     return this.authService.user.asObservable().pipe(
      map((user) => {
        return !!user;
      })
    );
  }

  hasUserSubscribe(): void {
    const options: SubscriptionPath = {
      channels: this.channelId + ''
    };
    this.subscribeService.hasUserSubscription(options).subscribe(data => {
      if (data.contents !== null && data.contents !== undefined)  {
        if (data.contents.length > 0 &&
          (data.contents[0].metadata.notificationPreference === '0' || data.contents[0].metadata.notificationPreference === '1')) {
          this.isSubscribe = true;
          if (data.contents[0].metadata.notificationPreference === '0') {
            this.isNotificationAll = false;
          } else if (data.contents[0].metadata.notificationPreference === '1') {
            this.isNotificationAll = true;
          }
          this.isLoadData = true;
        }
      }
    });
  }

    subscribe(): void {
      const options: SubscriptionPath = {
        channels: this.channelId + ''
      };
      if (this.isSubscribe) {
        this.subscribeService.deleteSubscription(options).subscribe(data => {
          if (data.status === 200) {
            this.isSubscribe = false;
            this.isNotificationAll = true;
          }
        });
      } else {
        this.subscribeService.getSubscription(options).subscribe(data => {
          if (data.status === 200) {
            this.isSubscribe = true;
            this.isNotificationAll = true;
          }
        });
      }
    }

   public onSelect(value: string): void {
      const options: SubscriptionPath = {
        channels: this.channelId + '',
      };
      this.subscribeService.updateNotification(options, value).subscribe(data => {
        if (data.status === 200) {
          this.isNotificationAll = value === '1';
        }
      });
      this.openNotification();
    }

    public openNotification(): void {
      this.isOpen = !this.isOpen;
    }

    openLogin(): void {
      this.loginModalService.openLoginModal();
      this.unsubscribeClose.nativeElement.click();
    }
}
