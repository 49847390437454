<div class="highlight-section"  [style]="{'background-color': data.backgroundColor, '--text-color': data.textColor}">
  <div
    class="highlight-section-left-tan"
    *ngIf="brandingBorder.sizes"
    [defaultImage]="brandingBorder.sizes.thumbnail"
    [lazyLoad]="brandingBorder.sizes.large"
  >
  </div>
  <div class="container">
    <div class="row">
      <div class="position-relative px-md-0  pe-0 head-container col-12">
        <div class="head-c d-md-flex col-12">
          <div class="col-12 col-md-6" *ngIf="data.post">
            <div  class="tags-wrapper d-flex justify-content-start  px-md-0">
              <div class="tag-item social-title" style="border-color:{{ data.textColor + '80' }}" >
                <p class="small">{{data.post.preferences.tag}}</p>
              </div>
              <div class="share-wrapper">
                <app-share *ngIf="data?.post?.slug" [linkToPage]="contentLink(data?.post?.slug)"></app-share>
              </div>
            </div>
          </div>
          <div [style]="{'background-color': data.backgroundColor}" class="highlight-text-container offset-md-0 offset-2  col-md-6 col-10 " [ngClass]="data.title? 'offset-md-6':''">
            <a *ngIf="data.post?.preferences?.title" [routerLink]="'../' + data?.post?.slug">
              <span class="container-h1"> <span class="h1"  [innerHTML]="data.post?.preferences?.title"></span></span>
            </a>
              <span class="container-h1" *ngIf="data.title" ><span class="h1" [innerHTML]="data?.title"></span></span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" >
      <div class="d-md-flex justify-content-center col-12 col-md-7 image-container" [defaultImage]="brandingBackground.sizes.large"  [lazyLoad]="brandingBackground.sizes.thumbnail">
        <div class="image-wrapper">
          <img *ngIf="data.post?.preferences?.mainImage?.sizes" [defaultImage]="data.post?.preferences?.thumbnailImage?.sizes?.thumbnail"  [lazyLoad]="data.post.preferences.thumbnailImage.sizes.medium_large" [alt]="data.post?.preferences?.mainImage?.alt">
          <img *ngIf="data.mainImage"  [defaultImage]="data.mainImage?.sizes?.thumbnail" [lazyLoad]="data.mainImage.sizes.medium_large"  [alt]="data.mainImage?.alt">
        </div>
      </div>
    </div>
    <div class="row">
      <div  class="highlight-p-wrapper px-6 px-md-0 col-12 offset-lg-8 col-lg-4">
        <p *ngIf="data.post" class="large-p desc-x" [innerHTML]="data.post?.preferences?.description"></p>
        <p *ngIf="data.description" class="large-p desc-x" [innerHTML]="data.description"></p>
        <app-underline-button
            *ngIf="data.post"
            [link]="data?.post?.slug"
            [text]="data?.linkText"
        >
        </app-underline-button>
        <app-underline-button
          *ngIf="data.link?.title"
          [link]="data.link?.url"
          [text]="data?.link?.title"
        >
        </app-underline-button>
      </div>
    </div>
  </div>
  <div
    class="highlight-section-right-tan"
    *ngIf="brandingBorder.sizes"
    [defaultImage]="brandingBorder.sizes.thumbnail"
    [lazyLoad]="brandingBorder.sizes.large"
  >
  </div>
</div>
