import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginModalService {

  openLogin$: BehaviorSubject<any> = new BehaviorSubject<any>( null);
  constructor() {
  }

  public openLoginModal(): void {
    this.openLogin$.next( true);
  }
}
