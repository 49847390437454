import {
  AfterViewChecked, AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-simply-text-image-slider',
  templateUrl: './simply-text-image-slider.component.html',
  styleUrls: ['./simply-text-image-slider.component.scss']
})
export class SimplyTextImageSliderComponent implements OnInit, AfterViewInit {

  @ViewChildren('citemFull', {read: ElementRef}) sliderItemsTmp: QueryList<HTMLElement>;
  @ViewChildren('citem', {read: ElementRef}) sliderItems: QueryList<HTMLElement>;
  @ViewChild('citem', { static: false }) divHello: ElementRef;
  @Input() data: any;
  itemHeight = 0;

  constructor(
              private cdRef: ChangeDetectorRef,
              private renderer: Renderer2,
              private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    if ( this.globalService.getIsDebuggingEnabled() ) {
      console.group('ContactInfo');
      console.dir( this.data);
      console.groupEnd();
    }
  }

  ngAfterViewInit(): void {
    this.changeMinHeight();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.changeMinHeight();
  }

  changeMinHeight(): void {
    this.itemHeight = 0;
    this.sliderItemsTmp.toArray().forEach( (c: any) => {
      c.nativeElement.classList.add('tmp');
    });
    this.cdRef.detectChanges();
    this.sliderItems.toArray().forEach((c: any, index: number, array: HTMLElement[]) => {
      this.renderer.removeStyle(c.nativeElement, 'min-height');
      this.cdRef.detectChanges();
      const minHeight = c.nativeElement.offsetHeight;
      if ( minHeight > this.itemHeight ) {
        this.itemHeight = minHeight;
      }
    });
    this.renderer.setStyle(this.divHello.nativeElement, 'min-height', this.itemHeight + 'px');
    this.sliderItemsTmp.toArray().forEach( (c: any) => {
      c.nativeElement.classList.remove('tmp');
    });
    this.cdRef.detectChanges();
  }
}
