import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from '@angular/common';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {
  @Input() white = false;
  @Input() linkToPage: string;
  @Input() under: string;
  isServer: boolean;

  constructor( @Inject(PLATFORM_ID) private platformId: string ) {
    this.isServer = isPlatformServer(this.platformId);
  }

  ngOnInit(): void { }
}
