import { Component, OnInit } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss']
})
export class IframeComponent implements OnInit {
  data: any;

  constructor( private sanitizer: DomSanitizer ) { }

  ngOnInit(): void {
  }

  getUrl(): any {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.data.iframeUrl);
  }
}
