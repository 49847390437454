import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { HighlightSection } from '../../models/highlight-section.model';
import { GeneralSettingsService } from '../../services/general-settings.service';

@Component({
  selector: 'app-highlight-two-section',
  templateUrl: './highlight-two-section.component.html',
  styleUrls: ['./highlight-two-section.component.scss']
})
export class HighlightTwoSectionComponent implements OnInit {
  @Input() data: HighlightSection;
  @ViewChild('dynamic', {read: ViewContainerRef })
  private viewRef: ViewContainerRef;
  brandingBorder: any;
  brandingBackground: any;
  isMobileResolution: boolean;

  constructor( private generalSettingsService: GeneralSettingsService ) { }

  ngOnInit(): void {
    this.generalSettingsService.$theme.subscribe((theme: any ) => {
      this.brandingBorder = theme.brandingBorder;
      this.brandingBackground = theme.brandingBackgroundImage;
    });
  }

  contentLink(url: any): string {
    if (url) {
      return url;
    }
    return '';
  }
}
