import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MarketingLandscape } from '../../models/marketing-landscape.model';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-new-marketing-landscape',
  templateUrl: './new-marketing-landscape.component.html',
  styleUrls: ['./new-marketing-landscape.component.scss']
})
export class NewMarketingLandscapeComponent implements OnInit {
  @Input() data: MarketingLandscape;
  @ViewChild('target') target: ElementRef;
  smallSquareThing = new Array(10);
  largeSquareThing = new Array(120);
  selected: {
    title: string
    image: any
    content: string
  };

  constructor( private globalService: GlobalService ) { }

  ngOnInit(): void {
    if ( this.globalService.getIsDebuggingEnabled() ) {
      console.group('NewMarketingLandscapeComponent');
      console.dir( this.data);
      console.groupEnd();
    }
  }

  handleClick(selected: any): void {
    this.selected = {
      title: selected.title,
      content: selected.content,
      image: {
        alt: selected.image.alt || '',
        url: selected.image.url,
        sizes: {
          medium: selected.image.sizes.medium,
        }
      }
    };
    this.target.nativeElement.scrollIntoView();
  }
}
