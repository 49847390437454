import { AfterViewInit, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { HeroImage } from '../../models/hero-iamge.model';
import { HomepageService } from '../../services/homepage.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import Player from '@vimeo/player';
import { GeneralSettingsService } from '../../services/general-settings.service';

@Component({
  selector: 'app-hero-image',
  templateUrl: './hero-image.component.html',
  styleUrls: ['./hero-image.component.scss']
})
export class HeroImageComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('class')
  elementClass = 'text-item';
  @Input() data: HeroImage;
  isDynamic = false;

  private player: Player;
  playerZIndex = '-99';
  themeColor: '';

  constructor(
    private homePageService: HomepageService,
    private router: Router,
    private generalSettingsService: GeneralSettingsService
  ) { }

  ngOnInit(): void {
    if (this.data.post) {
      this.isDynamic = true;
      return;
    }
    this.homePageService.$data.subscribe(({data}) => {
      this.data = {...data.preferences, ...this.data};
    });
    this.generalSettingsService.$theme.subscribe((theme: any ) => {
      this.themeColor = theme.color;
    });
  }

  ngAfterViewInit(): void {
    if ( this.data.vimeoId ) {
      this.player = new Player('hero-video');
      // this.player.play();
      if ( this.themeColor ) {
        this.player.setColor(this.themeColor).then((color: any) => {}).catch((error: any) => {
        });
      }
    }
  }

  ngOnDestroy(): void {
   //  this.homePageService.$data.unsubscribe();
  }

  pageLink(): string{
    return environment.angularUrl + this.router.url;
  }

  contentLink(url: any): string {
    if (url) {
      const x = url;
      return x;
    }
    return '';
  }

  getVimeoVideo(id: any): string {
    if ( id ) {
      return id + '?autoplay=0&controls=1&loop=0&muted=0';
    }
    return '';
  }
}
