<div class="modal fade" #changePassword id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <span class="close-btn-modal"  data-bs-dismiss="modal" aria-label="Close"></span>
      <div class="modal-body _modal-content">
        <h4 [innerHTML]="'ForgotPassword' | translate"></h4>
        <form [formGroup]="changePasswordForm" (ngSubmit)="handleChange()">
          <div class="form-item">
            <label for="newPassword" [innerHTML]="'NewPassword' | translate"></label>
            <input id="newPassword"
                   placeholder="{{'NewPasswordPlaceholder' | translate}}"
                   type="password"
                   formControlName="newPassword"
                   autocomplete="off"
                   #newPassword
                   required
            >
            <div *ngIf="changePasswordForm.controls.newPassword.touched || submitted">
              <div class="form-error" *ngIf="changePasswordForm.controls.newPassword.errors?.required" [innerHTML]="'NewPasswordRequired' | translate"></div>
              <div class="form-error" *ngIf="changePasswordForm.controls.newPassword?.errors?.minlength" [innerHTML]="'PasswordMinLengthError' | translate"></div>
            </div>
          </div>
          <div class="form-item">
            <label for="newPasswordConfirm" [innerHTML]="'ConfirmNewPassword' | translate"></label>
            <input id="newPasswordConfirm"
                   placeholder="{{'ConfirmNewPasswordPlaceholder' | translate}}"
                   type="password"
                   formControlName="newPasswordConfirm"
                   autocomplete="off"
                   required >
            <div *ngIf="changePasswordForm.controls.newPasswordConfirm.touched || submitted">
              <div class="form-error" *ngIf="changePasswordForm.controls.newPasswordConfirm.errors?.required" [innerHTML]="'ConfirmNewPasswordRequired' | translate"></div>
              <div class="form-error" *ngIf="changePasswordForm.controls.newPasswordConfirm?.errors?.minlength" [innerHTML]="'ConfirmNewPasswordMinLengthError' | translate"></div>
             <!-- <div class="form-error" *ngIf="changePasswordForm.errors?.mismatch">Passwords doesn't match.</div>-->
            </div>
            <div class="form-error" *ngIf="changePasswordForm.controls.password?.errors?.auth">{{changePasswordForm.controls.password?.errors?.auth}}</div>
            <p *ngIf="success" class="alert alert-success border-0 mt-3" [innerHTML]="'NewPasswordSet' | translate"></p>
            <p *ngIf="error" class="alert alert-danger border-0 mt-3">{{ errorMsg }}</p>
          </div>
          <div class="button-wrapper d-flex justify-content-center">
            <button class="w-100" type="submit">
              <app-rectangle-button [loading]="submitLoading" dark [label]="'Submit' | translate"></app-rectangle-button>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
