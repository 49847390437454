import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { User } from '../../../models/User.model';
import { CookieService } from 'ngx-cookie';


declare var bootstrap: any;

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements AfterViewInit, OnInit {

  @ViewChild('loginModal', {static: false}) loginModal: ElementRef;
  @ViewChild('emailAddress', {read: ElementRef}) emailAddress: ElementRef<HTMLInputElement>;
  loginForm: FormGroup;
  modal: any;
  submitted = false;
  hasValuePass = true;
  submitLoading = false;
  isActivated = false;
  @Output() switch = new EventEmitter();
  @Output() switchToRegister = new EventEmitter();
  constructor(@Inject(DOCUMENT) private document: Document,
              @Inject(PLATFORM_ID) private platformId: object,
              private authService: AuthService,
              private formBuilder: FormBuilder,
              private cookieService: CookieService,
              ) { }

   ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  ngAfterViewInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.modal = new bootstrap.Modal(this.loginModal.nativeElement);
    this.loginModal.nativeElement.addEventListener('hidden.bs.modal', () => {
      this.loginForm.reset();
      this.loginForm.enable();
      this.submitted = false;
    });
  }

  async handleLogin(): Promise<void> {
    this.submitted = true;
    if (this.loginForm.valid) {
      this.hasValuePass = true;
      this.submitLoading = true;
      this.loginForm.disable();
      try {
        const user = await this.authService.login(this.loginForm.value);
        this.close();
        this.loginForm.reset();
        if (typeof User) {
          this.setSelectedLanguage(user);
          window.location.reload();
        }
      } catch (e) {
        this.loginForm.enable();
        this.loginForm.controls.password.setErrors({auth: e.error.message});
      } finally {
        this.submitLoading = false;
      }
    } else {
      this.hasValuePass = false;
    }
  }

  handleSwitch(): void {
    this.switch.emit();
  }

  handleSwitchToForgot(): void {
    this.switchToRegister.emit();
  }

  public open(): any {
    this.loginForm.reset();
    this.submitted = false;
    this.hasValuePass = true;
    this.modal.show();
  }
  public close(): any {
    this.loginForm.reset();
    this.submitted = false;
    this.hasValuePass = true;
    this.modal.hide();
  }

  setSelectedLanguage(user: any): void {
    let pl = 'en';
    if (user != null && user.featureToggles?.includes('languageSwitch')) {
      pl = user.preferred_language;
      if (!pl) {
        pl = 'en';
      }
      this.cookieService.put('selected_lang', pl);
    }
  }
}
