import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../services/translation.service';
import { DomSanitizer, SafeHtml, SafeValue } from '@angular/platform-browser';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {

  constructor(
    private ts: TranslationService
  ) { }

  transform(value: string): any {
    if (value) {
      const trans = this.ts.data.translations[ value ];
      if (trans && trans.length > 0) {
        return trans;
      }
      return value;
    }
    return '';
  }

}
