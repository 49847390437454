<div class="headline"  [style]="{'background-color': data.backgroundColor, '--text-color': data.textColor}">
  <div class="container p-6  p-md-0">
    <div class="row px-2 p-md-0" >
      <div class="col-12 col-md-8">
        <div class="suptitle text-uppercase " *ngIf="data.supTitle">{{ data.supTitle }} </div>
        <h1 class="title" *ngIf="data.title" >{{ data.title }}</h1>
      </div>
    </div>
  </div>
</div>
