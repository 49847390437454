<div class="river card border-0" [style.background-image]="'url('+data.backgroundImage.url+')'">
<!--  <img [src]="data.backgroundImage.url" class="card-img-over" alt="{{data.backgroundImage.filename}}">-->
  <div class="container">
    <div class="row">
      <div  class="{{ data.class }}" >
        <img class="img-fluid" [src]="data.image.url" alt="" *ngIf="data.image">
        <div class="card-text" [innerHTML]="data.description" *ngIf="data.description"></div>
        <div class="d-flex align-items-center justify-content-center pt-4" *ngIf="data.links">
          <div class="" *ngFor="let item of data.links">
            <div class="links mx-3" >
              <a [href]="item.link.url" class="btn btn-secondary d-flex align-items-center">
                {{item.link.title}}
                <div class="d-none d-lg-block mx-1"></div>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25" height="24" viewBox="0 0 25 24">
                  <defs><path id="strifht3ka" d="M0 0h24v24H0z"/></defs><g transform="translate(.5)" fill="none" fill-rule="evenodd"><mask id="srpsgblbyb" fill="#fff"><use xlink:href="#strifht3ka"/></mask><path d="M7.021 15.55 7.014 12H5v7h7v-2.014l-3.55-.007L19 6.429 17.571 5 7.021 15.55z" fill="#FFF" fill-rule="nonzero" mask="url(#srpsgblbyb)" transform="rotate(-135 12 12)"/></g>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
