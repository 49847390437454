import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input, OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild
} from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { CarouselItemDirective } from '../../directives/carousel-item.directive';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  @ContentChildren(CarouselItemDirective) itemTemplates: QueryList<CarouselItemDirective>;
  @Input() slidesToShow: number;
  @Input() dark: string;
  @Input() isArrowContainer: boolean;
  @Input() position: SliderPositionArrows;
  @Output() breakpoint: EventEmitter<any> = new EventEmitter<any>();
  @Output() beforeChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() afterChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() init: EventEmitter<any> = new EventEmitter<any>();
  @Output() indexSlide: EventEmitter<number> = new EventEmitter<number>();
  public instance: any;
  public currentSlide: any;

  sliderPositionArrows = SliderPositionArrows;

  first = false;
  last = false;

  // tslint:disable-next-line
  private _slideConfig: any = {
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  @Input() set slideConfig(val: any) {
    if (val) {
      this._slideConfig = val;
    }
  }

  get slideConfig(): any {
    return this._slideConfig;
  }

  constructor( private cdr: ChangeDetectorRef ) {}

  ngOnInit(): void {
    if (typeof this.slidesToShow !== 'undefined') {
      this.slideConfig.slidesToShow = this.slidesToShow;
      this.slideConfig.slidesToScroll = this.slidesToShow;
    }
  }

  onAfterChange(e: { event: any; slick: any; currentSlide: number; first: boolean; last: boolean }): void {
    this.first = e.first;
    this.last = e.last;

    const { slick, currentSlide } = e;
    const slidesToShow = slick.options.slidesToShow;
    if (slidesToShow + currentSlide >= slick.slideCount) {
      this.last = true;
    }
    this.indexSlide.emit(currentSlide);
    this.afterChange.emit(e);
  }

  ngAfterViewInit(): void {
    this.cdr.markForCheck();
    this.slickModal.breakpoint.subscribe((b) => {
      const point = b.breakpoint;
      const responsive = this.slideConfig.responsive.find((r: any) => r.breakpoint === point);
      if (responsive) {
        this.slidesToShow = responsive.settings.slidesToShow;
      }
    });
  }

  ngOnDestroy(): void {
    // this.slickModal.breakpoint.unsubscribe();
  }

  slickInit(event: any): void {
    this.instance = event.slick;
    this.instance.setDimensions();
    this.cdr.markForCheck();
    this.init.emit(event);
    this.instance.slickGoTo(0);
    setTimeout(() => {
      this.instance.setDimensions();
    }, 250);
  }

  public setDimensions(): void {
    this.instance.setDimensions();
  }

  public get templates(): CarouselItemDirective[] {
    return this.itemTemplates.toArray();
  }

  onBreakpoint(event: any): void {
    event.slick.setDimensions();
    this.instance.setDimensions();
    this.breakpoint.emit(event);
  }

  prev(): void {
    this.slickModal.slickPrev();
  }

  next(): void {
    this.slickModal.slickNext();
    this.cdr.markForCheck();
  }

}

export enum SliderPositionArrows {
  // first is desktop and second is mobile
  center = 'center',
  left = 'left',
  right = 'right',
}
