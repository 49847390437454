import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { HomepageService } from '../../../services/homepage.service';
import { Injectable } from '@angular/core';

@Injectable()
export class PreferencesResolver implements  Resolve<Promise<any>>{
  constructor(private homePageService: HomepageService) {
  }

  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    const routePrefix = route.data.routePrefix;
    let routeX: string;
    if (route.params.id !== undefined ) {
      routeX = (routePrefix || '') + route.params.id;
    } else {
      routeX = (routePrefix || '');
    }
    return this.homePageService.getHomePage(routeX);
  }
}
