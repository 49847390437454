import { NgModule } from '@angular/core';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { RouterModule, Routes } from '@angular/router';
import { PreferencesResolver } from './modules/channel/resolvers/preferences.resolver';


export const routes: Routes = [
  { path: 'channel', loadChildren: () => import('./modules/channel/channel.module').then(m => m.ChannelModule) },
  {
    path: '**',
    component: HomepageComponent,
    /*resolve: {
      preferences: PreferencesResolver
    },*/
    data: {
      routePrefix: ''
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { enableTracing: false, onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled' })
  ],
  exports: [RouterModule],
  providers: [PreferencesResolver],
})
export class AppRoutingModule { }
