<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
  <defs>
    <filter id="0f51dkb8va">
      <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"/>
    </filter>
    <filter id="mtrl0fiftb">
      <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 1.000000 0"/>
    </filter>
    <path id="nm0halgbac" d="M0 0H24V24H0z"/>
  </defs>
  <g >
    <g>
      <g>
        <g>
          <g>
            <g  transform="translate(-1210 -2167) translate(0 1709) translate(837 220) translate(102.509 53) translate(270.491 185)">
              <g >
                <mask id="3cwpy7m4hd" >
                  <use xlink:href="#nm0halgbac"/>
                </mask>
                <path  d="M15.536 3.515L7.05 12l8.486 8.485 1.414-1.414L9.879 12l7.07-7.071-1.413-1.414z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
