<div *ngIf="data" class="simply-title-subtitle-image" [style]="{'background-color': data.tiBackgroundColor, '--text-color': data.tiTextColor}">
  <div class="container">
    <div class="row"  *ngIf="data.tiTitle || data.tiSubTitle" >
      <div class="col">
        <div class="d-flex justify-content-start flex-column align-items-start">
          <h2 *ngIf="data.tiTitle" class="title sti-txt " [ngClass]="data.tiSubTitle?'':'pix'" [innerHTML]="data.tiTitle"></h2>
          <p class="sub-title sti-txt col-12 col-md-7" *ngIf="data.tiSubTitle" [innerHTML]="data.tiSubTitle"></p>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="data.tiImage">
      <div class="col d-flex justify-content-center flex-column align-items-center">
        <picture *ngIf="data.tiImage.sizes.thumbnail">
          <source *ngIf="data.tiMobileImage.sizes.medium_large" media="(max-width:768px)" srcset="{{data.tiMobileImage.sizes.medium_large}}">
          <img  class="sti-image"
               [defaultImage]="data.tiImage.sizes.thumbnail"
               [lazyLoad]="data.tiImage.url"
               alt="{{ data.tiImage.alt }}" title="{{ data.tiImage.alt }}">
        </picture>
      </div>
    </div>
  </div>
</div>
