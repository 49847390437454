import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArrowPreviousComponent } from './arrow-previous/arrow-previous.component';
import { ArrowNextComponent } from './arrow-next/arrow-next.component';
import { UnderlineButtonComponent } from './underline-button/underline-button.component';
import {AppRoutingModule} from '../../app-routing.module';
import { RectangleButtonComponent } from './rectangle-button/rectangle-button.component';
import { ScrollDownComponent } from './scroll-down/scroll-down.component';


@NgModule({
  declarations: [
    ArrowPreviousComponent,
    ArrowNextComponent,
    UnderlineButtonComponent,
    RectangleButtonComponent,
    ScrollDownComponent,
  ],
  imports: [
    CommonModule,
    AppRoutingModule
  ],
    exports: [
        ArrowPreviousComponent,
        ArrowNextComponent,
        UnderlineButtonComponent,
        RectangleButtonComponent,
        ScrollDownComponent,
    ],
})
export class ButtonsModule {}
