import {Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core';
import {CtaDownlaodService} from "../../services/cta-downlaod.service";

@Directive({
  selector: '[appLink]'
})
export class LinkDirective implements OnInit {
  downloadTarget: string;
  isTargetVar: boolean;
  constructor(private el: ElementRef, private ctaDownload: CtaDownlaodService) {
  }


  @HostListener('click', ['$event']) onMouseEnter(event: any): void {
    event.preventDefault();
    if (!this.isTargetVar) {
      window.location.href = this.el.nativeElement.href;
    }
    if (this.downloadTarget) {
      this.ctaDownload.passTarget(this.downloadTarget);
    }
  }

  ngOnInit(): any {
    if (this.isTarget(this.el.nativeElement.href)) {
      this.downloadTarget = this.el.nativeElement.getAttribute('href');
      this.el.nativeElement.setAttribute('href', '');
    }
  }

  isTarget(url: string): boolean {
    this.isTargetVar = url.includes('_target_');
    return url.includes('_target_');
  }

}
