<div class="wrapper" [defaultImage]="data.backgroundImage.sizes.thumbnail" [lazyLoad]="data.backgroundImage.url">
  <ng-container  *ngFor="let item of data.hotspots; let i = index">
    <ng-container *ngIf="!item.activeHotspot.isActive">
      <a [routerLink]="item.button.url">
        <div class="navigation-hotspot" [style]="{'top': item.position.top, 'left': item.position.left}">
          <div class="icon">
            +
          </div>
          <div class="text-wrapper">
            <span>{{item.button.title}}</span>
          </div>
        </div>
      </a>
    </ng-container>
    <ng-container *ngIf="item.activeHotspot.isActive">
      <app-marker
      [item]="item"
      [i]="i"
      >
      </app-marker>
    </ng-container>
  </ng-container>
  <app-scroll-down *ngIf="data.scrollDown"></app-scroll-down>
</div>

