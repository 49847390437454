import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LoginModalComponent } from '../login-modal/login-modal.component';
// @ts-ignore
import { SignupModalComponent } from '../signup-modal/signup-modal.component';
import { AuthService } from '../../../services/auth.service';
import { User } from '../../../models/User.model';
import { MyDataComponent } from '../my-data/my-data.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { LoginModalService } from '../../../services/login-modal.service';
import {TranslationService} from '../../../services/translation.service';


@Component({
  selector: 'app-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.scss']
})
export class LoginRegisterComponent implements OnInit, AfterViewInit {
  showLoginRegister = false;
  isLogIn = false;
  isOpen = false;
  lang = '?lang=en';
  showDropdown = false;
  user: User|null = null;
  @ViewChild(LoginModalComponent) loginModal: LoginModalComponent;
  @ViewChild(SignupModalComponent) signupModal: SignupModalComponent;
  @ViewChild(ForgotPasswordComponent) forgotPasswordModal: ForgotPasswordComponent;
  @ViewChild(MyDataComponent) myDataModal: MyDataComponent;
  @ViewChild(ChangePasswordComponent) changeModal: ChangePasswordComponent;
  @ViewChild('dropdownRef', { static: false }) dropdownRef: ElementRef;
  @ViewChild(LoginModalComponent) login: LoginModalComponent;
  @ViewChild(SignupModalComponent) register: SignupModalComponent;
  @ViewChild(ForgotPasswordComponent) forgotPassword: ForgotPasswordComponent;
  @ViewChild(MyDataComponent) myData: MyDataComponent;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private loginModalService: LoginModalService,
    private router: Router,
    private ts: TranslationService
  ) { }

  async ngOnInit(): Promise<void> {
    this.authService.getUser().subscribe((data: User) => {
      this.user = data;
    });
    this.route.queryParams.subscribe((props) => {
      if (props.activate) {
        this.openLoginModal();
      }
      if (props.reset) {
        this.changeModal.open();
        this.changeModal.userId = props.user;
        this.changeModal.resetPasswordKey = props.key;
      }
    });
    this.lang = '?lang=' + this.ts.currentLang;
  }

  ngAfterViewInit(): void {
    this.loginModalService.openLogin$.subscribe((open) => {
      if (open) {
        this.handleLoginModal();
      }
    });
  }

  reload(): void {
    const route = this.router.url.substring(1).split('?')[0];
    let gotoPage = route;
    if (route === 'my-history' || route === 'subscriptions') {
      gotoPage = 'home';
    }

    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['./' + gotoPage], { relativeTo: this.route });
  }

  openLoginModal(): void {
    this.loginModal.isActivated = true;
    this.loginModal.open();
  }

  menuToggle(): void {
    this.showLoginRegister = !this.showLoginRegister;
    this.showDropdown = !this.showDropdown;
  }

  switchToRegister(): void {
    this.loginModal.close();
    this.signupModal.open();
  }

  switchToForgot(): void {
    this.loginModal.close();
    this.forgotPasswordModal.open();
    setTimeout(() => {
      this.forgotPassword.emailAddress.nativeElement.focus();
    }, 1000);
  }

  switchToLogin(): void {
    this.signupModal.close();
    this.loginModal.open();
  }

  handleLoginModal(): void {
    this.showDropdown = false;
    this.loginModal.open();
    setTimeout(() => {
      this.login.emailAddress.nativeElement.focus();
    }, 1000);
  }

  handleSignUpModal(): void {
    this.showDropdown = false;
    this.signupModal.open();
    setTimeout(() => {
      this.register.firstName.nativeElement.focus();
    }, 1000);
  }

  handleMyData(): void {
    this.showDropdown = false;
    this.myDataModal.open();
    setTimeout(() => {
      this.myData.firstName.nativeElement.focus();
    }, 1000);
  }

   logout(): void {
    this.authService.logout();
    this.showDropdown = false;
    this.reload();
  }

  closeSubMenu(): void {
    this.showDropdown = false;
  }

}
