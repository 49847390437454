<div class="preview-recommender-wrapper position-relative" [style]="{'background-color': data.backgroundColor, '--text-color': data.textColor}">
  <div class="container-md px-6 px-md-0 d-flex mb-5  justify-content-md-end">
    <app-link-to *ngIf="data.link?.url" [link]="data.link?.url"  [title]="data.link?.title"></app-link-to>
  </div>
  <div class="container-fluid p-0" *ngIf="recData.length">
    <div class="_custom-wrapper">
      <div class="col-12  f-content">
        <div class="col-12 col-md-4 text-column">
          <div class="tag-wrapper">
            <p class="tag small social-title" style="border-color:{{ data.textColor + '80' }}">{{recData[currentSlide].tag}}</p>
            <div class="social-share-wrapper">
              <app-share [linkToPage]="recData[currentSlide].url"></app-share>
            </div>
          </div>
          <a [routerLink]="'../' + recData[currentSlide].url">
            <h2 class=" mobile3">{{recData[currentSlide].title}}</h2>
          </a>
          <p class=" description" [ngClass]="isForMobileVisible?'mobile-visible': 'mobile-disabled'" >{{recData[currentSlide].description}}</p>
         <!-- <div class="link-button">
            <app-underline-button
              [color]="data.textColor"
              [link]="recData[currentSlide].url"
              [text]="'Learn More'"
            >
            </app-underline-button>
          </div>-->
        </div>
        <div class="col-12 col-md-8">
          <app-carousel (afterChange)="handleChange($event)"  [slideConfig]="_slideConfig">
            <ng-template misCarouselItem *ngFor="let item of recData; let i = index">
              <a class="link-to-news" [routerLink]="'../' + item.url" [title]="item.title"  id="{{ generatedId(item.url) }}">
                <div class="slide-item-wrapper-wrapper">
                  <div class="slide-item-wrapper">
                    <div class="slide-item"   [defaultImage]="item.coverThumbnailSmall ? item.coverThumbnailSmall : item.thumbnail"
                         [lazyLoad]="item.coverThumbnailLarge ? item.coverThumbnailLarge : item.mediumImg" >
                      <app-video-play [isVideo]="item.hasVideo"></app-video-play>
                    </div>
                  </div>
                </div>
              </a>
              <p class="small slide-counter d-block d-md-none">
                <strong>{{i+1}}</strong>/{{recData.length}}
              </p>
            </ng-template>
          </app-carousel>
        </div>
      </div>
    </div>
  </div>
</div>
