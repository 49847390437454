import { AfterViewInit, Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AuthService } from '../../../services/auth.service';

declare var bootstrap: any;

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, AfterViewInit{
  @ViewChild('changePassword', {static: false}) changePassword: ElementRef;
  @ViewChild('newPassword') elemNewPassword: ElementRef;
  changePasswordForm: FormGroup;
  submitLoading = false;
  success = false;
  error = false;
  errorMsg: string;
  userId: string;
  resetPasswordKey: string;
  modal: any;
  submitted = false;
  constructor(private formBuilder: FormBuilder, @Inject(DOCUMENT) private document: Document,
              @Inject(PLATFORM_ID) private platformId: object, private authService: AuthService) {
  }

  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      newPasswordConfirm: ['', [Validators.required,  Validators.minLength(6)]],
    }, {validators: passwordMatchValidator});


    function passwordMatchValidator(g: FormGroup): any {
      if (!g.get('newPassword')?.value){
        return null;
      }
      // @ts-ignore
      return g.get('newPassword').value === g.get('newPasswordConfirm').value
        ? null : {mismatch: true};
    }
  }

  ngAfterViewInit(): void {
    this.elemNewPassword.nativeElement.focus();
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.modal = new bootstrap.Modal(this.changePassword.nativeElement);
    this.changePassword.nativeElement.addEventListener('hidden.bs.modal', () => {
      this.changePasswordForm.reset();
    });
    setTimeout(() => {
      this.elemNewPassword.nativeElement.focus();
    }, 1000);
  }

  async handleChange(): Promise<void> {
    this.error = false;
    this.success = false;
    this.submitted = true;
    if (!this.changePasswordForm.valid) {
      if ( !this.changePasswordForm.controls.newPassword.valid
        || !this.changePasswordForm.controls.newPasswordConfirm.valid ) {
        return;
      }

      this.error = true;
      if ( this.changePasswordForm.controls.newPassword.value !== this.changePasswordForm.controls.newPasswordConfirm.value ) {
        this.errorMsg = 'Passwords don\'t match.';
        return;
      }
      if ( this.changePasswordForm.controls.newPassword.value.length < 6 ) {
        this.errorMsg = 'Password must be at least 6 characters long.';
        return;
      }
      this.errorMsg = 'Unknown error!';
      return;
    }
    try {
      this.submitLoading = true;
      this.error = false;
      const res = await this.authService
        .resetPassword({userId: this.userId, resetPasswordKey: this.resetPasswordKey, ...this.changePasswordForm.value});
      this.success = true;
      this.changePasswordForm.reset();
      this.userId = '';
      this.resetPasswordKey = '';
    } catch (e) {
      this.errorMsg = e.error.message;
      this.submitLoading = false;
      this.error = true;
      this.changePasswordForm.controls.password.setErrors({auth: e.error.message});
    } finally {
      this.submitLoading = false;
      this.submitted = false;
    }
  }

  public open(): any {
    this.changePasswordForm.reset();
    this.success = false;
    this.submitted = false;
    this.modal.show();
  }


}
