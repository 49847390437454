import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ImageOnly } from '../../../models/image-only.model';

@Component({
  selector: 'app-image-only',
  templateUrl: './image-only.component.html',
  styleUrls: ['./image-only.component.scss']
})
export class ImageOnlyComponent implements OnInit {
  @HostBinding('class')
  elementClass = 'text-item';
  @Input() data: ImageOnly;

  constructor() { }

  ngOnInit(): void { }
}
