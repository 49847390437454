import { Component, OnInit } from '@angular/core';
import { Hotspot2dModal } from '../../../models/hotspot-2d.modal';

@Component({
  selector: 'app-hotspot2d',
  templateUrl: './hotspot2d.component.html',
  styleUrls: ['./hotspot2d.component.scss']
})
export class Hotspot2dComponent implements OnInit {
  data: Hotspot2dModal;
  active: number;

  constructor() { }

  ngOnInit(): void { }
}
