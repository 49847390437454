
<ng-container class="" *ngIf="data.video.length === 1; then oneBlock else multipleBlocks"></ng-container>

<ng-template #oneBlock>
  <div class="video-component container px-md-0" >
    <div>
      <div class="row" *ngIf="data.title">
        <h2>{{ data.title }}</h2>
      </div>
      <div class="row">
        <div class="col-12  video-iframe-content" [ngClass]="data.video[0].fullSizeVimeoVideo? 'col-12 full-size-video' : 'col-md-7'">
          <div class="px-2 py-0 p-md-0">
            <iframe id="video-iframe"
                    [src]="getVimeoVideo(data.video[0].vimeoVideoVimeoId) | vimeoUrl"
                    data-cookieconsent="marketing"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen>
            </iframe>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="data.video[0].vimeoVideoDescription">
        <div>
          <div class="col-12 col-md-7 px-2 p-md-0">
            <p>{{data.video[0].vimeoVideoDescription}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #multipleBlocks>
  <div class="multiple-video-component">
    <div class="container">
      <div class="row" *ngIf="data.title">
        <h2>{{ data.title }}</h2>
      </div>
      <div class="row">
        <div class="player-container col col-md-6 col-lg-4 col-12 d-flex flex-column" *ngFor="let item of data.video; let i = index; first as isFirst; last as isLast; odd as isOdd; even as isEven">
          <div class="iframe-container position-relative">
            <div *ngIf="data.video[i].vimeoVideoVimeoPoster" class="poster-image position-absolute" style="background-position: center;" [lazyLoad]="data.video[i].vimeoVideoVimeoPoster.url" [defaultImage]="data.video[0].vimeoVideoVimeoPoster.sizes.thumbnail"></div>
            <iframe id="video-iframe-{{i}}"
                    class="vimeo-iframe"
                    [src]="getVimeoVideo(data.video[i].vimeoVideoVimeoId) | vimeoUrl"
                    data-cookieconsent="marketing"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen>
            </iframe>
          </div>
          <div>
            <p class="cl-gray" *ngIf="data.video[i].vimeoVideoTitle" [innerHTML]="data.video[i].vimeoVideoTitle"></p>
          </div>
          <button class="play-btn mt-md-auto" (click)="playVideo(i)">
            <span>play</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

