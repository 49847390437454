<div class="news"  [style]="{'background-color': data.backgroundColor, '--text-color': data.textColor}">
  <div class="container-fluid p-md-0 pe-0">
    <div class="container px-2 p-md-0">
      <div class="row title-row d-flex flex-column-reverse flex-md-row">
        <div class="col-md-9 col">
          <h4 class="mobile2 header-title">{{ data.title }}</h4>
        </div>
        <div class="col-md-3 col p-md-0 d-flex justify-content-md-end pe-md-2">
          <app-link-to [title]="data.link.title" [link]="data.link.url" [color]="'black'" ></app-link-to>
        </div>
      </div>
    </div>
    <div class="container-fluid p-md-0 ps-2 pe-0">
      <div class="d-flex justify-content-end">
        <div class="col-12 f-content">
          <app-carousel *ngIf="recData.length"   dark [slideConfig]="_slideConfig">
            <ng-template misCarouselItem *ngFor="let item of recData">
              <div class="slide recommender-slider"  >
                <a class="link-to-news" [routerLink]="'../' + item.url" [title]="item.title" >
                  <div class="link-to-news-img"
                       [defaultImage]="item.portraitImageThumbnail? item.portraitImageThumbnail : item.thumbnail"
                       [lazyLoad]="item.portraitImageUrl? item.portraitImageUrl : item.mediumImg" >
                  </div>
                </a>
                  <div class="d-flex align-items-end h-100">
                    <div class="under-container">
                      <div>
                        <h6 class="medium-p tag-item d-inline-block social-title" style="border-color:{{ data.textColor + '80' }}" [innerHTML]="item.tag"></h6>
                        <div class="share-wrapper d-inline-block">
                          <app-share [white]="false" [linkToPage]="item.url"></app-share>
                        </div>
                      </div>
                      <div class="d-flex align-items-start flex-column news-cc">
                        <div class="news-title">
                          <a [href]="item.url" [title]="item.title" >
                            <h5 class="item-title mobile2-leed">{{ item.title}}</h5>
                          </a>
                        </div>
                        <div class="mt-auto">
                          <p class="small">{{ toData(item.publishDate)  | date: 'dd. MMMM YYYY'  }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

            </ng-template>
          </app-carousel>
        </div>
      </div>
    </div>
  </div>
</div>
