
<div #marker  class="marker"
     [ngClass]="{active: active}"
      [style]="{'top': detectMob ? (item.mobilePosition.top ? item.mobilePosition.top : item.position.top) : item.position.top, 'left': detectMob ? (item.mobilePosition.left ? item.mobilePosition.left : item.position.left) : item.position.left}">
  <div class="closed-wrapper d-flex align-items-end" [ngClass]="{active: active}">
    <img
      class="closed-icon"
      [src]="item.activeHotspot.hotspotCloseImage.url"
      [alt]="item.activeHotspot.hotspotCloseImage.alt"
    >
    <div class="closed-text">
      <h5>{{item.button.title}}</h5>
    </div>
  </div>
  <div class="active-body" #element [ngClass]="{active: active }">
    <div class="active-body-image-wrapper">
      <img class="active-background-image" [src]="item.activeHotspot.hotspotOpenImage.url" [alt]="item.activeHotspot.hotspotOpenImage.alt">
      <img class="active-image" [src]="item.activeHotspot.backgroundImage.sizes.medium" [alt]="item.activeHotspot.backgroundImage.alt">
    </div>
    <div class="content">
      <div class="title-wrapper">
        <h4>{{item.button.title}}</h4>
        <p class="tiny">{{item.activeHotspot.description}}</p>
      </div>
      <div class="button" [routerLink]="item.button.url">
        <span>Go to</span>
        <svg width="25" height="10" viewBox="0 0 21 10" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 6H0V4h17l-2-3 1-1 4 4 1 1-5 5-1-2 2-2z" fill="#fff" fill-rule="nonzero"/>
        </svg>
      </div>
    </div>
  </div>
</div>
