<div *ngIf="isServer" class="share-icon" [ngClass]="{'white': white}">
  <app-share-icon></app-share-icon>
</div>

<div *ngIf="!isServer" [appPopper] class="popper-wrapper"  [target]="tooltip">
  <div class="share-icon" [ngClass]="{'white': white}">
    <app-share-icon></app-share-icon>
  </div>
    <span  class="popper _custom-popper" #tooltip>
      <span class="popper__arrow"></span>
      <app-twitter-icon [title]="''" [linkToPage]="linkToPage"> </app-twitter-icon>
      <app-facebook-icon [title]="''" [linkToPage]="linkToPage" ></app-facebook-icon>
      <span class="last">
        <app-linkedin-icon [title]="''" [linkToPage]="linkToPage" ></app-linkedin-icon>
      </span>
  </span>
</div>
