import {Component, HostListener, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import { Gallery } from '../../models/gallery.model';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-logo-grid-section',
  templateUrl: './logo-grid-section.component.html',
  styleUrls: ['./logo-grid-section.component.scss']
})
export class LogoGridSectionComponent implements OnInit {
  @Input() data: Gallery;
  isMobileResolution: boolean;
  start = 0;
  end = 10;
  total = 0;

  constructor( @Inject(PLATFORM_ID) private platformId: object ) { }

  @HostListener('window:resize', [])
  private onResize(): void  {
    this.detectScreenSize();
  }

  ngOnInit(): void {
    this.detectScreenSize();
    if (this.data.images?.length) {
      this.total = this.data.images?.length;
    }
  }

  private detectScreenSize(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    if (window.innerWidth < 768) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }

  handleShowMore(): void {
    this.end += 10;
    if (this.end > this.total) {
      this.end = this.total;
    }
  }
}
