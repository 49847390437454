<div id="my-history" class="container p-md-0">
  <div class="row">
    <p [innerHTML]="'Chronology' | translate"></p>
    <div class="my-history-title d-flex align-items-center justify-content-between justify-content-md-end">
      <h1 class="col-md-11" [innerHTML]="'MyHistory' | translate"></h1>
      <div class="col-md-1 dropdown-btn position-md-relative">
        <span class="track-btn d-flex justify-content-around align-items-center" #toggleButton  (click)="openTracking()">
          <svg *ngIf="isHistoryTrack" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.6 12c0-5.293-4.307-9.6-9.6-9.6-5.293 0-9.6 4.307-9.6 9.6 0 5.293 4.307 9.6 9.6 9.6 5.293 0 9.6-4.307 9.6-9.6m2.4 0c0 6.628-5.372 12-12 12S0 18.628 0 12 5.372 0 12 0s12 5.372 12 12m-4.8 1.2a1.2 1.2 0 0 1-1.2 1.2h-4.8a2.4 2.4 0 0 1-2.4-2.4V6a1.2 1.2 0 0 1 2.4 0v4.8c0 .66.54 1.2 1.2 1.2H18a1.2 1.2 0 0 1 1.2 1.2" fill="#000" fill-rule="evenodd"/>
          </svg>
          <svg *ngIf="!isHistoryTrack" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <rect width="24" height="24" id="artboard_1" />
                <clipPath id="clip_1">
                  <use xlink:href="#artboard_1" clip-rule="evenodd" />
                </clipPath>
              </defs>
              <g id="AtomsIconsPause" clip-path="url(#clip_1)">
                <path d="M0 0L24 0L24 24L0 24L0 0Z" id="Rectangle" fill="none" fill-rule="evenodd" stroke="none" />
                <path d="M7 14L9 14L9 6L7 6L7 14ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM11 14L13 14L13 6L11 6L11 14Z" transform="translate(2 2)" id="Shape" fill="#000000" stroke="none" />
              </g>
            </svg>
        </span>
        <ul class="dropdown-btn-info position-absolute menu"  [ngStyle]="{'visibility': isOpen  ? 'visible' : 'hidden'}" >
          <li *ngIf="isHistoryTrack" class="d-flex align-items-center"
              (click)="onSelect('0')"
          >
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <rect width="24" height="24" id="artboard_1" />
                <clipPath id="clip_1">
                  <use xlink:href="#artboard_1" clip-rule="evenodd" />
                </clipPath>
              </defs>
              <g id="AtomsIconsPause" clip-path="url(#clip_1)">
                <path d="M0 0L24 0L24 24L0 24L0 0Z" id="Rectangle" fill="none" fill-rule="evenodd" stroke="none" />
                <path d="M7 14L9 14L9 6L7 6L7 14ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM11 14L13 14L13 6L11 6L11 14Z" transform="translate(2 2)" id="Shape" fill="#000000" stroke="none" />
              </g>
            </svg>
            <span class="buttonsCTA buttonsCTA-pmh" [innerHTML]="'PauseMyHistory' | translate"></span>
          </li>
          <li *ngIf="!isHistoryTrack" class="d-flex align-items-center"
              (click)="onSelect('1')"
          >
            <svg  width="24" height="24" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.6 12c0-5.293-4.307-9.6-9.6-9.6-5.293 0-9.6 4.307-9.6 9.6 0 5.293 4.307 9.6 9.6 9.6 5.293 0 9.6-4.307 9.6-9.6m2.4 0c0 6.628-5.372 12-12 12S0 18.628 0 12 5.372 0 12 0s12 5.372 12 12m-4.8 1.2a1.2 1.2 0 0 1-1.2 1.2h-4.8a2.4 2.4 0 0 1-2.4-2.4V6a1.2 1.2 0 0 1 2.4 0v4.8c0 .66.54 1.2 1.2 1.2H18a1.2 1.2 0 0 1 1.2 1.2" fill="#000" fill-rule="evenodd"/>
            </svg>
            <span class="buttonsCTA buttonsCTA-rmh" [innerHTML]="'RecordMyHistory' | translate"></span>
          </li>
          <li class="d-flex align-items-center"
              (click)="onSelect('2')"
              style="padding-left: 26px;"
          >
            <svg width="14px" height="18px" viewBox="0 0 14 18" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 6L11 16L3 16L3 6L11 6M9.5 0L4.5 0L3.5 1L0 1L0 3L14 3L14 1L10.5 1L9.5 0ZM13 4L1 4L1 16C1 17.1 1.9 18 3 18L11 18C12.1 18 13 17.1 13 16L13 4Z" id="Shape" fill="#000000" stroke="none" />
            </svg>
            <span class="buttonsCTA buttonsCTA-dmh" style="padding-left: 23px;">{{'DeleteMyHistory' | translate}}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="row"  *ngIf="isShow">
      <section class="" *ngFor="let item of data?.contents">
        <h4>{{(item.unix * 1000) | date:'MMMM dd, yyyy'}}</h4>
        <article class="item-elem d-block d-md-flex align-items-center"   *ngFor="let content of item.contents; " >
          <a href="{{'../' + content.url}}" class="col-12 col-md-4 pe-3 d-flex justify-content-center">
            <ng-container *ngTemplateOutlet="content.type.includes('channel')? channel : page"></ng-container>
            <ng-template #page>
              <img class="history-img" *ngIf="content.metadata.coverThumbnailLarge"
                   [defaultImage]="content.metadata.coverThumbnailLarge"
                   [lazyLoad]="content.metadata.coverThumbnailMedium"
                   alt="{{ content.metadata.imageAlt }}">
              <img class="history-img" *ngIf="!content.metadata.coverThumbnailLarge"
                   src="/assets/img/placeholder.jpg"
                   loading="lazy"
                   alt="Scoop and sponn">
            </ng-template>
            <ng-template #channel>
              <img class="history-img channel" *ngIf="content.metadata.roundImageLarge"
                   [defaultImage]="content.metadata.roundImageLarge"
                   [lazyLoad]="content.metadata.roundImageRetina"
                   alt="{{ content.metadata.imageAlt }}">
              <img class="history-img channel" *ngIf="!content.metadata.roundImageLarge"
                   src="/assets/img/placeholder.jpg"
                   loading="lazy"
                   alt="Scoop and sponn">
            </ng-template>
          </a>
          <div class="col-12 col-md-8 ps-md-3 m-0">
            <div  class="d-flex text-wrapper">
              <h6 class="medium-p tag-item d-inline-block social-title" style="border-right: solid 0.5px rgba(10, 10, 10, 0.5)">
                <span [ngClass]="content.content[0].tags.includes('Channel')?'channel-border':'' " [innerHTML]="content.content[0].tags"></span>
              </h6>
              <div class="share-wrapper d-inline-block">
                <app-share [white]="false" [linkToPage]="content.url" ></app-share>
              </div>
            </div>
            <a href="{{'../' + content.url}}" class="">
              <h5>{{ content.content[0].title}}</h5>
            </a>
            <p class="small time-stamp">Visited at {{( content.metadata.lastVisitedUnix * 1000) | date:'H:mm' }}</p>
            <a href="{{'../' + content.url}}" class="">
              <p class="short-description">{{ content.content[0].description }}</p>
            </a>
          </div>
        </article>
      </section>
      <section #historyErrorElem class="historyError" >
        <h2 >{{'HistoryInfo' | translate}}</h2>
      </section>
    </div>
    <div class="loading-wrapper" *ngIf="!isShow">
      <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
        <span class="visually-hidden" [innerHTML]="'Loading' | translate"></span>
      </div>
    </div>
  </div>
</div>


