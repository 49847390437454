import { Component, Input, OnInit } from '@angular/core';
import { InfoBox, Link, Links, NavigationTree } from '@scoop-lli/navigation/lib/navigation.model';
import { GeneralSettingsService } from '../../services/general-settings.service';
import { GlobalService } from '../../services/global.service';


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  @Input() navigation: NavigationTree;

  infoBox: InfoBox;
  links: Links[];
  linksTitle: string;
  quickLinks: Links[];
  quickLinksTitle: string;
  contact: Link;
  brandingBorder: any;
  brandingBackground: any;
  logo: any;

  constructor(
    private generalSettingsService: GeneralSettingsService,
    private globalService: GlobalService
  ) { }
  ngOnInit(): void {
    if ( this.globalService.getIsDebuggingEnabled() ) {
      console.group('ContactInfo');
      console.dir( this.navigation);
      console.groupEnd();
    }
    this.infoBox = this.navigation.infoBox;
    this.links =  this.navigation.links;
    this.linksTitle = this.navigation.linksTitle;
    this.quickLinks = this.navigation.quickLinks;
    this.quickLinksTitle = this.navigation.quickLinksTitle;
    this.contact = this.infoBox.contact;
    this.generalSettingsService.$theme.subscribe((theme: any ) => {
      this.brandingBorder = theme.brandingBorder;
      this.brandingBackground = theme.brandingBackgroundImage;
    });
  }
}
