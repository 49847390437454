<div class="link-to d-flex align-items-center h-100">
  <a  *ngIf="link"  [routerLink]="'../' + link"  [id]="ctaId"  class="buttonsCTA " [title]="title">
    <span [innerHtml]="showTitle()"> </span>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter id="filter_1">
          <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
        </filter>
        <path d="M0 0L24 0L24 24L0 24L0 0Z" id="path_1" />
        <clipPath id="mask_1">
          <use xlink:href="#path_1" />
        </clipPath>
      </defs>
      <g id="grid--grid_big" filter="">
        <path d="M0 0L24 0L24 24L0 24L0 0Z" id="grid--grid_big-(BackgroundMask)" fill="none" fill-rule="evenodd" stroke="none" />
        <g  id="grid-gg" clip-path="url(#mask_1)">
          <path  d="M14 14L14 8L8 8L8 14L14 14ZM6 14L6 8L0 8L0 14L6 14ZM14 6L14 0L8 0L8 6L14 6ZM6 6L6 0L0 0L0 6L6 6Z" transform="translate(5 5)" id="grid--grid_big" fill="#2E3A59" fill-rule="evenodd" stroke="none" />
        </g>
      </g>
    </svg>
  </a>
</div>
