import { Component, Input, OnInit } from '@angular/core';
import { GridManual } from '../../models/grid-manual.model';

@Component({
  selector: 'app-grid-manual',
  templateUrl: './grid-manual.component.html',
  styleUrls: ['./grid-manual.component.scss']
})
export class GridManualComponent implements OnInit {
  @Input() data: GridManual;

  constructor() { }

  ngOnInit(): void { }
}
