import {Component, HostBinding, Input, OnInit, ViewEncapsulation} from '@angular/core';
import { Text } from '../../../models/text.model';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
  preserveWhitespaces: true,
  encapsulation: ViewEncapsulation.None,
})
export class TextComponent implements OnInit {
  @HostBinding('class')
  elementClass = 'text-item';
  @Input() data: Text;

  constructor( private sanitizer: DomSanitizer ) { }

  ngOnInit(): void { }

  transformYourHtml(htmlTextWithStyle: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
  }
}
