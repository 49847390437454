import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscribeChannelComponent } from '../../components/subscriptions/subscribe-channel/subscribe-channel.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ShareComponent } from '../../shared/share/share.component';
import { IconsModule } from '../../shared/icons/icons.module';
import { PopperDirective } from '../../shared/directives/popper.directive';
import { ChannelListComponent } from '../../components/channel-list/channel-list.component';
import { ButtonsModule } from '../../shared/buttons/buttons.module';
import { RouterModule } from '@angular/router';
import { VideoPlayComponent } from '../../shared/buttons/video-play/video-play.component';
import { DndDirective } from '../../shared/directives/dnd.directive';
import { ImageCropperModule } from 'ngx-image-cropper';
import { UploadImagePopupComponent } from '../../components/upload-image-popup/upload-image-popup.component';
import { FileSizePipe } from '../../pipes/file-size.pipe';
import { SubscribeBtnComponent } from '../../components/subscriptions/subscribe-channel/subscribe-btn/subscribe-btn.component';
import { FiltersModule } from '@scoop-lli/filters';
import { BackgroundImageComponent } from '../../components/background-image/background-image.component';
import { SimplyTextComponent } from '../../components/simply-text/simply-text.component';
import { TimelineComponent } from '../../components/timeline/timeline.component';
import { SimplyTextImageSliderComponent } from '../../components/simply-text-image-slider/simply-text-image-slider.component';
import { SimplyTitleImageComponent } from '../../components/simply-title-image/simply-title-image.component';
import { SimplyContentLRComponent } from '../../components/simply-content-lr/simply-content-lr.component';
import { CardComponent } from '../../components/card/card.component';
import { CardBackgroundComponent } from '../../components/card-background/card-background.component';
import { LinkDirective } from '../../shared/directives/link.directive';
import { LanguageSwitchComponent } from '../../components/language-switch/language-switch.component';
import { TranslatePipe } from '../../pipes/translate.pipe';


@NgModule({
  declarations: [
    SubscribeChannelComponent,
    ShareComponent,
    PopperDirective,
    LinkDirective,
    ChannelListComponent,
    VideoPlayComponent,
    DndDirective,
    UploadImagePopupComponent,
    FileSizePipe,
    SubscribeBtnComponent,
    BackgroundImageComponent,
    SimplyTextComponent,
    SimplyTextImageSliderComponent,
    SimplyTitleImageComponent,
    TimelineComponent,
    SimplyContentLRComponent,
    CardComponent,
    CardBackgroundComponent,
    LanguageSwitchComponent,
    TranslatePipe,
  ],
  imports: [
    CommonModule,
    LazyLoadImageModule,
    IconsModule,
    ButtonsModule,
    RouterModule,
    ImageCropperModule,
    FiltersModule,
  ],
  exports: [
    SubscribeChannelComponent,
    LazyLoadImageModule,
    IconsModule,
    ShareComponent,
    LinkDirective,
    PopperDirective,
    ChannelListComponent,
    VideoPlayComponent,
    DndDirective,
    UploadImagePopupComponent,
    FileSizePipe,
    SubscribeBtnComponent,
    FiltersModule,
    LanguageSwitchComponent,
    TranslatePipe,
  ],
})
export class ComponentsModule {}
