import { Component, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Columns } from '../../models/column-list.model';

@Component({
  selector: 'app-column-list-section',
  templateUrl: './column-list-section.component.html',
  styleUrls: ['./column-list-section.component.scss']
})
export class ColumnListSectionComponent implements OnInit {
  @Input() data: Columns;
  isMobileResolution: boolean;
  isBrowser = true;
  gap = 3;
  align = 'justify';

  constructor( @Inject(PLATFORM_ID) private platformId: object ) { }

  ngOnInit(): void {
    this.detectScreenSize();
  }

  @HostListener('window:resize', [])
  private onResize(): void  {
    this.detectScreenSize();
  }

  private detectScreenSize(): void {
    if (!isPlatformBrowser(this.platformId)) {
      this.isBrowser = false;
      return;
    }
    this.isMobileResolution = window.innerWidth < 768;
  }
}
