<div class="gallery-quote"  [style]="{'background-color': data.backgroundColor, '--text-color': data.textColor}">
  <div class="container-fluid container-md p-md-0">
    <div class="container p-0">
      <div>
        <h6 class="tag-item d-inline-block" [innerHTML]="data.tag"></h6>
        <div class="share-wrapper d-inline-block">
          <app-share [white]="false" [linkToPage]=" pageLink() " ></app-share>
        </div>
      </div>
      <h2 class="col col-md-7">{{ data.subtitle }}</h2>
    </div>
    <div class="row p-0">
      <app-gallery-slider  dark [maxItems]="8" >
        <ng-template misCarouselItem *ngFor="let number of [].constructor(8); index as i" >
          <div [style.background-color]="data.backgroundColor"  class="quote-block d-flex justify-content-center align-items-center flex-column h-100" *ngIf="i == 0; else elseBlock">
            <h1 class="text-center"><u>{{data.quote.content}}</u></h1>
            <p class="small">&#045; {{data.quote.source}}</p>
          </div>
          <ng-template #elseBlock>
            <img  class="img-item" src="{{  getImgElementUrl(i-1)  }}" alt="{{ getImgElementAlt(i-1) }}" loading="lazy" >
          </ng-template>
        </ng-template>
      </app-gallery-slider>
    </div>
  </div>
</div>
