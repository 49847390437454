import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { CarouselComponent } from '../../shared/sliders/carousel/carousel.component';
import { Content } from '../../models/limitless-interaction.model';

@Component({
  selector: 'app-jobs-section',
  templateUrl: './jobs-section.component.html',
  styleUrls: ['./jobs-section.component.scss']
})
export class JobsSectionComponent implements OnInit {
  @ViewChild('slider') slider: CarouselComponent;
  @HostBinding('class')
  elementClass = 'with-background';
  recData: Content[] = [];
  data: any;

  // tslint:disable-next-line
  _slideConfig: any = {
    infinite: false,
    slidesToShow: 1.2,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  constructor() { }

  ngOnInit(): void { }

  handleNavigate(direction: string): void {
    if (direction === 'NEXT') {
      this.slider.next();
    }
    if (direction === 'PREV') {
      this.slider.prev();
    }
  }
}
