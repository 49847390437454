import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { TranslationService } from '../../../services/translation.service';
import { Language } from '../../../models/Page.model';

declare var bootstrap: any;


@Component({
  selector: 'app-signup-modal',
  templateUrl: './signup-modal.component.html',
  styleUrls: ['./signup-modal.component.scss']
})
export class SignupModalComponent implements AfterViewInit, OnInit {

  @ViewChild('signupModal', {static: false}) signupModal: ElementRef;
  @ViewChild('successModal', {static: false}) successModal: ElementRef;
  @ViewChild('firstName', {read: ElementRef}) firstName: ElementRef<HTMLInputElement>;
  @ViewChild('email1', {read: ElementRef}) email1: ElementRef<HTMLInputElement>;
  @ViewChild('password1', {read: ElementRef}) password1: ElementRef<HTMLInputElement>;
  signupForm: FormGroup;
  modal: any;
  sModal: any;
  @Output() switch = new EventEmitter();
  submitted = false;
  submitLoading = false;
  languages: Language[] = [];
  selectLanguage = 'en';
  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private readonly ts: TranslationService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {
    this.languages = this.ts.data.languages;
  }


  ngOnInit(): void {
    const sLang = this.ts.getSelectedLanguageCookieValue();
    if (sLang) {
      this.selectLanguage = sLang;
    }
    this.signupForm = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]],
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      preflang: [],
      password: ['', [Validators.required, Validators.minLength(6)]],
      password2: ['', [Validators.required, Validators.minLength(6)]],
      agreed: [false, [Validators.requiredTrue]],
      pp: [false, [Validators.requiredTrue]],
    }, {validators: passwordMatchValidator});

    function passwordMatchValidator(g: FormGroup): any {
      if (!g.get('password2')?.value){
        return null;
      }
      // @ts-ignore
      return g.get('password').value === g.get('password2').value
        ? null : {mismatch: true};
    }
  }

  ngAfterViewInit(): void {

    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.sModal = new bootstrap.Modal(this.successModal.nativeElement);
    this.modal = new bootstrap.Modal(this.signupModal.nativeElement);
    this.signupModal.nativeElement.addEventListener('hidden.bs.modal', () => {
      this.signupForm.reset();
      this.signupForm.enable();
      this.submitted = false;
    });
  }

  async handleSignup(): Promise<void> {
    this.signupForm.controls.preflang.setValue(this.selectLanguage);
    this.submitted = true;
    if (this.signupForm.valid) {
      this.submitLoading = true;
      try {
        this.signupForm.disable();
        const res = await this.authService.signup(this.signupForm.value);
        this.modal.hide();
        this.sModal.show();
      } catch (e) {
        this.signupForm.enable();
        this.signupForm.controls.email.setErrors({auth: e.error.message});
      } finally {
        this.submitLoading = false;
      }
    }
  }

  handleSwitch(): void {
    this.switch.emit();
  }

  public open(): any {
    this.signupForm.reset();
    this.modal.show();
    this.email1.nativeElement.value = '';
    this.password1.nativeElement.value = '';
  }

  public close(): any {
    this.signupForm.reset();
    this.modal.hide();
  }

  changePreferredLanguage(e: any): void {
    this.selectLanguage = e.target.value;
  }

}
