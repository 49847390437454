<div class="gallery-recommender ipad-padding  d-flex position-relative" [style]="{'background-color': data.backgroundColor, '--text-color': data.textColor}">
  <div
    class="highlight-section-left-tan d-none d-md-inline-block"
    *ngIf="brandingBorder?.sizes"
    [defaultImage]="brandingBorder.sizes.thumbnail"
    [lazyLoad]="brandingBorder.sizes.large"
  >
  </div>
  <div class="container-fluid not-gr-elem  p-md-0 pe-0">
    <div class="d-flex ps-2 p-md-0 justify-content-end">
      <div class="col-12 d-md-flex f-content">
        <div class="col-md-3 col-12">
          <div class="" *ngIf="data.link.title">
            <app-link-to [title]="data.link.title" [link]="data.link.url" [color]="'black'" ></app-link-to>
          </div>
          <h4 class="mobile2 top-title" [innerHTML]="data.title"></h4>
        </div>
        <div class="col-md-9 col-12" *ngIf="recData.length">
          <app-carousel *ngIf="recData.length"  dark [slideConfig]="_slideConfig">
            <ng-template misCarouselItem *ngFor="let item of recData; let i = index">
              <a [routerLink]=" '../' + item.url" id="{{ generatedId(item.url) }}">
                <div class="image-wrapper position-relative">
                  <app-video-play [isVideo]="item.hasVideo"></app-video-play>
                  <div class="slide gallery-recommender-slider"  [defaultImage]="item.coverThumbnailSmall ? item.coverThumbnailSmall : item.thumbnail"
                       [lazyLoad]="item.coverThumbnailLarge ? item.coverThumbnailLarge : item.mediumImg" >
                  </div>
                </div>
              </a>
              <p class="small slide-counter d-block d-md-none">
                <strong>{{i+1}}</strong>/{{recData.length}}
              </p>
              <div class="inner-container h-100">
                <div class="d-flex align-items-center">
                  <h6 class="medium-p tag-item d-inline-block social-title" style="border-color:{{ data.textColor + '80' }}"  [innerHTML]="item.tag"></h6>
                  <div class="share-wrapper d-inline-block">
                    <app-share [white]="false" [linkToPage]="item.url"  ></app-share>
                  </div>
                </div>
                <div class="d-flex align-items-end inner-content">
                  <div>
                    <a [routerLink]=" '../' + item.url" >
                      <h5 class="item-title" [innerHTML]="item.title"></h5>
                    </a>
                  </div>
                </div>
              </div>
            </ng-template>
          </app-carousel>
        </div>
      </div>
    </div>
  </div>
</div>
