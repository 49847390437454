import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss']
})
export class CollectionComponent implements OnInit {
  @Input() dataBack: any;
  data: any;

  constructor(
    private http: HttpClient,
    private globalService: GlobalService
  ) { }

  async ngOnInit(): Promise<void> {
    // this.dataBack = await this.http.get(environment.pageUrl + `collection-new`).toPromise();
    this.data = this.dataBack.data.components;
    if ( this.globalService.getIsDebuggingEnabled() ) {
      console.group('CollectionComponent');
      console.dir( this.data);
      console.groupEnd();
    }
  }

  onFilterChange(filter: string): void { }
}
