<div class='language-wrap'>
  <div class='selected d-flex align-items-center'>
    <img class='flag' (click)='showOptions = !showOptions'  [src]='selectedLang!.icon' [title]="selectedLang!.title">
    <svg  (click)='showOptions = !showOptions'  [class]='showOptions ? "active" : ""' width="8" height="6" viewBox="0 0 8 6" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 6 0 0h8z" fill="#fff" fill-rule="evenodd"/>
    </svg>

  </div>
  <div class='options' *ngIf='showOptions'>
    <ng-container *ngFor="let lang of languages" >
      <span class="flag-container" (click)="handleLang(lang.code )" [ngClass]=" lang.active == '1'? 'active': '' " >
        <img class='flag'  [title]="lang.title" [src]="lang.icon">
      </span>
    </ng-container>
  </div>
</div>
