import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CarouselComponent } from '../../shared/sliders/carousel/carousel.component';
import { TestimonialSection } from '../../models/testimonial-section.model';

@Component({
  selector: 'app-testimonial-section',
  templateUrl: './testimonial-section.component.html',
  styleUrls: ['./testimonial-section.component.scss']
})
export class TestimonialSectionComponent implements OnInit {
  @ViewChild('carouselComponent') carouselComponent: CarouselComponent;
  @Input() dark: string;
  @Input() data: any;
  testimonialSection: TestimonialSection;
  first = false;
  last = false;

  constructor() { }

  ngOnInit(): void {
    this.testimonialSection = this.data;
  }

}
