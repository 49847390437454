<div class="container d-none d-md-block">
    <p class="headling small">{{'SSRecognitions' | translate}}</p>
    <div class="row the-row mb-4">
        <div class="col-3 col-lg-2 mx-2" *ngFor="let item of data.list">
            <div class="row">
                <div class="col-3 d-flex align-items-center"><h4>{{item.count}}x</h4></div>
                <div class="col-9"> <img [src]="item.image.sizes.medium" alt="client-logo"></div>
            </div>
        </div>
    </div>
</div>

<div class="container d-md-none">
    <div class="accordion _accordion" id="accordionExample">
        <div class="mobile-acc">
            <div class="accordion-header" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"  id="headingOne">
                <div class="d-flex justify-content-between">
                    <p class="headling mobile small" [innerHTML]="'SSRecognitions' | translate"></p>
                    <span  class=" arrow "><svg class=" arrow" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M23.245 4l-11.245 14.374-11.219-14.374-.781.619 12 15.381 12-15.391-.755-.609z"/></svg></span>
                </div>
            </div>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body _accordion-body">
                    <div class="container">
                        <div class="row">
                            <div class="col-6" *ngFor="let item of data.list">
                                <div class="row">
                                    <div class="col-4 d-flex align-items-center mb-4"><h4>{{item.count}}x</h4></div>
                                    <div class="col-8"> <img [src]="item.image.sizes.medium" alt="client-logo"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
