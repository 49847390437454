import {Component, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss']
})
export class SvgComponent implements OnInit {
  @Input() url: any;
  @Input() clazz: any;
  @Input() alt: any;
  svg: any;
  isIMG = true;
  isBrowser: boolean;

  constructor(
    private httpClient: HttpClient, private sanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platformId: object
  ) { }

  ngOnInit(): void {
   this.isIMG = true;
   if (this.url.includes('.svg')) {
     this.isIMG = false;
     if (isPlatformBrowser(this.platformId)) {
       this.isBrowser = true;
       this.getSVG();
     }
   }
  }

  async getSVG(): Promise<void> {
    const headers = new HttpHeaders();
    headers.set('Accept', 'image/svg+xml');
    try {
      const svgString = await this.httpClient.get(this.url, {headers, responseType: 'text'}).toPromise();
      this.svg = this.sanitizer.bypassSecurityTrustHtml(svgString);
    } catch (e) {
    }
  }
}
