import { Component, Input, OnInit } from '@angular/core';
import { GalleryQuote } from '../../models/gallery-quote.model';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-gallery-quote',
  templateUrl: './gallery-quote.component.html',
  styleUrls: ['./gallery-quote.component.scss']
})
export class GalleryQuoteComponent implements OnInit {
  @Input() data: GalleryQuote;

  constructor() { }

  ngOnInit(): void {
  }

  getImgElementUrl(n: number): string {
    if (this.data.images !== undefined) {
      return this.data.images[n].url;
    } else {
      return '';
    }
  }

  getImgElementAlt(n: number): string {
    if ( this.data.images !== undefined ){
      return this.data.images[n].alt;
    } else {
      return '';
    }
  }

  pageLink(): string{
    return environment.angularUrl  + URL;
  }
}
