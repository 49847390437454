import { AfterViewInit, Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { GeneralSettingsService } from '../../services/general-settings.service';
import { Footer } from '../../models/general-settings.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, AfterViewInit{
  isMobileResolution: boolean;
  data$ = this.generalSettings.$data
    .pipe(
      map(item => item.data.footer),
    ) as Observable<Footer>;

  constructor(
    private generalSettings: GeneralSettingsService,
    @Inject(PLATFORM_ID) private platformId: object
  ) { }

  ngOnInit(): void {
    this.detectScreenSize();
  }

  ngAfterViewInit(): void {
    this.detectScreenSize();
  }

  @HostListener('window:resize', [])
  private onResize(): void  {
    this.detectScreenSize();
  }

  private detectScreenSize(): void {
    if (!isPlatformBrowser(this.platformId)){
      return;
    }
    this.isMobileResolution = window.innerWidth < 769;
  }

  currentYear(): string  {
    return new Date().getFullYear() + '';
  }
}
