import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CtaDownlaodService {
  target = new BehaviorSubject<string>('');

  constructor(private http: HttpClient) { }

  passTarget(target: string): any {
    this.target.next(target);
  }

  getTarget(targetId: string, email: string, signIn: string, url: string): Promise<string|any> {
    return this.http.post(environment.wpJson + 'target', {
      email,
      targetId,
      signIn,
      url
    }).toPromise();
  }

  downloadURI(uri: string, name?: string): void {
    const link = document.createElement('a');
    // link.download = name;
    link.href = uri;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // delete link;
  }
}
